import { useQuery } from "@tanstack/react-query";
import { Quote, Sparks } from "iconoir-react";
import { useSelector } from "react-redux";
import FileIcon from "../../asset/svg/FileIcon";
import { fetchAITemplateCategory } from "../../services/template.service";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const RawThoughtScreen = ({
    handleNext,
    rawThought,
    setRawThought,
    setAITemplateCategory,
    aiTemplateCategory,
    handleGenerateNewPost,
}) => {
    const authState = useSelector((state) => state.auth);
    const { isFreeTrial } = authState.subscription;
    const { subscriptionLimit } = authState;
    const { aiCreditLimit, aiCreditUsage } = subscriptionLimit;

    const query = {
        type: 'GEN_AI'
    };

    const { data } = useQuery({
        queryKey: ['ai-template-category', query],
        queryFn: ({ queryKey }) => fetchAITemplateCategory(queryKey[1]),
        retry: false
    });
    const trackAIClicked = () => {
        trackEvent(MIXPANEL_EVENTS.AI_POST, {
            "Source":"Post generator",
            "Raw Thought": rawThought,
            "Tone selected": aiTemplateCategory?.name,
        })
    }
    const isDisable = !rawThought || !aiTemplateCategory;
    return (
        <div className={`bg-star-light3 ${isFreeTrial ? 'h-[92vh]' : 'h-screen'} w-full rounded-[20px] p-5`}>
            <div className="bg-gray-moon flex flex-col rounded-[20px] relative h-full p-8">
            <div className="  scrollbar min-h-[400px] overflow-y-auto">

                
                <div className="flex items-center justify-center gap-2">
                    <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                    <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                    {/* <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" /> */}
                </div>
                <p className="text-subHeadingTwoBold bg-clip-text text-transparent bg-hover-gradient-1 mt-10">
                    Generate a LinkedIn post in less than 60 seconds
                </p>
                <p className="text-bodyOne">
                    Tell us what you want to write about today and we'll optimize it for LinkedIn virality!
                </p>
                <div className="flex gap-2 items-center mt-8">
                    <Quote className="w-5 h-5 text-star-light" />
                    <p className="text-bodyTwo text-space-default">Write your raw thoughts</p>
                </div>
                <div className="mt-3">
                    <div className="relative">
                        <textarea
                            value={rawThought}
                            onChange={(event) => { setRawThought(event.target.value) }}
                            maxLength={1000}
                            className="rounded-lg scrollbar h-[32vh] outline-none resize-none w-full px-2 py-3 text-bodyTwo border border-gray-3 bg-white placeholder:text-gray-4"
                            placeholder="Today I want to post about..."
                        />
                        <div className="absolute bottom-3 right-3">
                            <p className="text-bodyThree text-gray-3">{rawThought.length}/1000</p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-2 mt-6">
                    <FileIcon />
                    <p className="text-bodyOne text-space-default">Create a version of this post that is</p>
                </div>
                <div className="flex flex-wrap items-center gap-2 mt-3">
                    {
                        data?.data?.map((item) => (
                            <button key={item._id} className={`${aiTemplateCategory?._id === item._id ? 'active' : ''} outlined-btn small`} onClick={() => {
                                setAITemplateCategory(item);
                            }}>
                                {item.name}
                            </button>
                        ))
                    }
                </div>
                </div>
                
                <div className="mt-auto w-full">
                    <div className="flex justify-end ">
                        <p className="text-bodyThree text-gray-6">{aiCreditLimit - aiCreditUsage}/{aiCreditLimit} credits remaining</p>
                    </div>
                    <div className="flex justify-end mt-2">
                        {/* <Link to={'/'} className="text-btn"><ArrowLeft />Back</Link> */}
                        <div className="tooltip-wrapper">
                            <button className="filled-btn small v2 w-[260px]" disabled={isDisable} onClick={() => {
                                handleGenerateNewPost({ content: rawThought, rawThought: '', aiTemplateCategoryId: aiTemplateCategory?._id });
                                handleNext();
                                trackAIClicked();
                            }}>
                                <Sparks />
                                Magically Transform
                            </button>
                            {
                                isDisable && (
                                    <div className="tooltip absolute bottom-10 w-max right-0 hidden rounded-br-none rounded-lg bg-white px-4 py-2 shadow-drop-shadow">
                                        <p className="text-bodyThree text-gray-5">
                                            Write your raw thoughts and select a post type, then click here to a generate post
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RawThoughtScreen;