import { useQuery } from "@tanstack/react-query";
import { NavArrowDown } from "iconoir-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarIcon from "../../asset/svg/AvatarIcon";
import CircleStar from "../../asset/svg/CircleStar";
import GradientSearchIcon from "../../asset/svg/GradientSearchIcon";
import UserStar from "../../asset/svg/UserStar";
import { selectAllCreator, setAllCreator } from "../../redux/slice/favCreator.slice";
import { fetchFavCreator } from "../../services/favCreator.service";
import CheckBox from "./CheckBox";
import GradientLock from "../../asset/svg/GradientLock";

const SearchField = ({ value, onChange }) => {
    const ref = useRef();
    const [hasFocus, setFocus] = useState(false);

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);

    return (
        <>
            <div className="h-[40px] relative">
                <div className="absolute z-20 w-full bg-white  rounded-lg"
                    style={{
                        border: hasFocus ? '1px solid transparent' : '1px solid #E0DDED',
                        backgroundImage: hasFocus ? 'linear-gradient(#FFF, #FFF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)' : undefined,
                        backgroundOrigin: 'border-box',
                        backgroundClip: 'padding-box, border-box',
                        outline: 'none'
                    }}>
                    <div className="flex h-full items-center px-2">
                        <div className="cursor-pointer">
                            <GradientSearchIcon width="18" height="18" />
                        </div>

                        <input className="h-full py-3 w-full text-bodyTwo bg-transparent border-none outline-none pl-3"
                            ref={ref}
                            value={value}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            onChange={(event) => { onChange(event.target.value); }}
                            placeholder="Search creator"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const FavCreatorBox = () => {

    const favCreatorState = useSelector((state => state.favCreator));
    const favCreator = favCreatorState.creatorIds;
    const selectedCreatorsIds = favCreatorState.selectedCreatorsIds;

    const dropdownRef = useRef(null);
    const [showModel, setShowModel] = useState(false);
    const [creators, setCreators] = useState(selectedCreatorsIds);
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowModel(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const handleSelectCreator = (profileId) => {
        let tempCreator = [...creators];
        if (tempCreator.includes(profileId)) {
            tempCreator = tempCreator.filter((id) => id !== profileId);
        } else {
            tempCreator.push(profileId)
        }
        setCreators(tempCreator)
    }

    const query = {
        limit: 100,
        favCreator,
        status: 'progress' //only for dependency to call the query
    }
    const { data } = useQuery({
        queryKey: ['fav-creator', query],
        queryFn: async ({ queryKey }) => {
            const response = await fetchFavCreator(queryKey[1]);
            setCreators(response?.data?.map((item) => item.profileId) || [])
            dispatch(setAllCreator(response?.data?.map((item) => item.profileId) || []))
            dispatch(selectAllCreator(response?.data?.map((item) => item.profileId) || []))
            return response;
        },
        staleTime: Infinity,
        retry: false
    });

    const handleSelectAll = () => {
        if (creators.length > 0) {
            setCreators([]);
        } else {
            setCreators(data?.data?.map((item) => item.profileId) || [])
        }
    }

    const applyFilter = () => {
        dispatch(selectAllCreator(creators))
    }
    let options = data?.data || [];

    // Ensure search is a string to prevent runtime errors
    const searchQuery = search?.toString().toLowerCase() || "";

    // Filter options based on the search query
    options = options.filter((item) => {
        const text = item?.user?.fullName || item?.user?.username;
        return text?.toLowerCase().includes(searchQuery);
    });

    let lockOptions = data?.lockCreator?.filter((item) => {
        const text = item?.user?.fullName || item?.user?.username;
        return text?.toLowerCase().includes(searchQuery);
    });


    const totalCreators = data?.total;
    const isAllSelected = totalCreators === creators.length;
    return (
        <>
            <div className="relative" ref={dropdownRef}>
                <div
                    className="rounded-lg cursor-pointer bg-gray-moon flex gap-2 items-center px-2 py-1"
                    onClick={() => { setShowModel(!showModel) }}
                >
                    <div>
                        <CircleStar />
                    </div>
                    <p className="text-bodyThree text-gray-5">
                        Creators
                    </p>
                    {
                        selectedCreatorsIds.length > 0 && <>
                            <p className="text-bodyThree text-gray-5">|</p>
                            <p className="text-bodyThreeBold text-gray-6">{selectedCreatorsIds.length}</p>
                        </>
                    }
                    <NavArrowDown width={15} height={15} color="#8B8699" />
                </div>

                {showModel && (
                    <div className="show absolute right-0 z-20 top-[35px] min-w-[155px] rounded-lg overflow-hidden  bg-white border-[0.5px] border-gray-4 shadow-purple">
                        <div className="p-2 overflow-auto max-h-[400px] scrollbar rounded-lg" >
                            <p className="text-bodyThree text-gray-4 ">Favourite Creators | {creators.length}</p>
                            <div className="my-4">
                                <SearchField value={search} onChange={setSearch} />
                            </div>
                            <div className="flex flex-col gap-2">
                                <div
                                    onClick={handleSelectAll}
                                    className={`px-2 py-1 text-bodyTwo rounded-lg text-nowrap text-gray-5 cursor-pointer hover:bg-star-light4 hover:text-star-light `}
                                >
                                    <div className="flex items-center gap-2">
                                        <div className="flex items-center gap-1">
                                            <UserStar />
                                            <p className="w-[120px] whitespace-nowrap overflow-hidden text-ellipsis" >All Creators </p>
                                        </div>
                                        <div>
                                            <CheckBox checked={isAllSelected} />
                                        </div>
                                    </div>
                                </div>
                                {options.map((option, index) => {
                                    const isActive = creators.includes(option.profileId);

                                    return (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                handleSelectCreator(option.profileId);
                                            }}
                                            className={`px-2 py-1  text-bodyTwo rounded-lg text-nowrap text-gray-5 cursor-pointer hover:bg-star-light4 hover:text-star-light `}
                                        >
                                            <div className="flex items-center gap-2">
                                                <div className="flex items-center gap-1">
                                                    <AvatarIcon width="24" height="24" src={option.user?.profilePhoto} />
                                                    <p className="w-[120px] whitespace-nowrap overflow-hidden text-ellipsis" >{option.user?.fullName || option.user?.username}</p>
                                                </div>
                                                <div>
                                                    <CheckBox checked={isActive} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {lockOptions.map((option, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`px-2 py-1  text-bodyTwo rounded-lg text-nowrap text-gray-5 cursor-pointer hover:bg-star-light4 hover:text-star-light `}
                                        >
                                            <div className="flex items-center gap-2">
                                                <div className="flex items-center gap-1">
                                                    <AvatarIcon width="24" height="24" src={option.user?.profilePhoto} />
                                                    <p className="w-[120px] whitespace-nowrap overflow-hidden text-ellipsis" >{option.user?.fullName || option.user?.username}</p>
                                                </div>
                                                <div>
                                                    <GradientLock />
                                                </div>
                                            </div>
                                        </div>
                                    )

                                })}
                            </div>
                        </div>
                        <div className="sticky border-t  py-1 border-star-light2 bottom-0 flex justify-center  bg-white">
                            <button className="text-btn small border border-transparent" disabled={creators.length <= 0} onClick={() => { setShowModel(false); applyFilter(creators) }}>
                                Apply Filter
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default FavCreatorBox;
