import axios from 'axios';
import { BASE_URL } from './config';
import { store } from '../redux/store';
import { logout, updateCaptchaStatus } from '../redux/slice/auth.slice';
import { trackEvent } from './mixpanel';
import { MIXPANEL_EVENTS } from './mixpanel/eventType';

const api = axios.create({
    baseURL: BASE_URL,
});

api.interceptors.request.use(
    (config) => {
        const token = store.getState()?.auth?.accessToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch(logout());
            }

            if (error.response.status === 429) {
                trackEvent(MIXPANEL_EVENTS.BOT_DETECTED)
                store.dispatch(updateCaptchaStatus(true));
            }
        }
        return Promise.reject(error);
    }
);

export default api;
