const RadioButton = ({color = '#FD6483'}) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Radio button" clip-path="url(#clip0_5_1147)">
                <path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M9 2.0625C5.16852 2.0625 2.0625 5.16852 2.0625 9C2.0625 12.8315 5.16852 15.9375 9 15.9375C12.8315 15.9375 15.9375 12.8315 15.9375 9C15.9375 5.16852 12.8315 2.0625 9 2.0625ZM0.9375 9C0.9375 4.5472 4.5472 0.9375 9 0.9375C13.4528 0.9375 17.0625 4.5472 17.0625 9C17.0625 13.4528 13.4528 17.0625 9 17.0625C4.5472 17.0625 0.9375 13.4528 0.9375 9Z" fill={color} />
                <circle id="Ellipse 99" cx="9" cy="9" r="4.875" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_5_1147">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default RadioButton;
