import { useEffect, useRef, useState } from "react";
import { NavArrowDown } from "iconoir-react";
import GradientSearchIcon from "../../asset/svg/GradientSearchIcon";
import CheckBox from "./CheckBox";
import countryCodes from '../../asset/json/countryCode';
import { useSelector } from "react-redux";

const SearchField = ({ value, onChange }) => {
    const ref = useRef();
    const [hasFocus, setFocus] = useState(false);

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);

    return (
        <div className="h-[40px] relative">
            <div className="absolute z-20 w-full bg-white rounded-lg"
                style={{
                    border: hasFocus ? '1px solid transparent' : '1px solid #E0DDED',
                    backgroundImage: hasFocus ? 'linear-gradient(#FFF, #FFF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)' : undefined,
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'padding-box, border-box',
                    outline: 'none'
                }}>
                <div className="flex h-full items-center px-2">
                    <GradientSearchIcon width="18" height="18" />
                    <input className="h-full py-3 w-full text-bodyTwo bg-transparent border-none outline-none pl-3"
                        ref={ref}
                        value={value}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        onChange={(event) => onChange(event.target.value)}
                        placeholder="Search country"
                    />
                </div>
            </div>
        </div>
    );
};

const CountryCodeSelector = ({ onSelectCountry }) => {
    const dropdownRef = useRef(null);
    const authState = useSelector((state) => state.auth);
    const country = authState.locationDetail.country || ""
    const [showModel, setShowModel] = useState(false);
    const [selectedCode, setSelectedCode] = useState(country === "India" ? "+91" : "+1"); // Default to India (+91)
    const [search, setSearch] = useState("");
    const [hasFocus, setFocus] = useState(false);

    useEffect(() => {
        onSelectCountry(country === "India" ? "+91" : "+1"); // Set default country code on mount
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowModel(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (hasFocus) {
            dropdownRef.current.focus();
        }
    }, [hasFocus]);

    const handleSelectCountry = (code) => {
        setSelectedCode(code);
        onSelectCountry(code); // Pass selected value to parent
        setShowModel(false);
    };

    const filteredOptions = countryCodes.filter((item) =>
        item.Country.toLowerCase().includes(search.toLowerCase()) || item.value.includes(search)
    );

    return (
        <div className="relative" ref={dropdownRef}>
            <div className="rounded-lg cursor-pointer bg-gray-moon h-12 flex gap-2 items-center px-2 py-1"
                style={{
                    // border: '1px solid #E0DDED',
                    border: showModel ? '1px solid transparent' : '1px solid #E0DDED',
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'padding-box, border-box',
                    outline: 'none',
                    backgroundImage: showModel
                        ? 'linear-gradient(#F7F6FF, #F7F6FF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)'
                        : undefined,

                }}
                onClick={() => setShowModel(!showModel)}>
                <p className="text-bodyThree text-gray-5">{selectedCode}</p>
                <NavArrowDown width={15} height={15} color="#8B8699" />
            </div>

            {showModel && (
                <div className="absolute right-0 z-20 top-[35px] min-w-[155px] rounded-lg overflow-hidden bg-white border-[0.5px] border-gray-4 shadow-purple">
                    <div className="p-2 overflow-auto max-h-[400px] scrollbar rounded-lg">
                        <p className="text-bodyThree text-gray-4">Country Code</p>
                        <div className="my-4">
                            <SearchField value={search} onChange={setSearch} />
                        </div>
                        <div className="flex flex-col gap-2">
                            {filteredOptions.map((option, index) => (
                                <div key={index} onClick={() => { handleSelectCountry(option.value); setShowModel(false); }}
                                    className="px-2 py-1 text-bodyTwo rounded-lg text-nowrap text-gray-5 cursor-pointer hover:bg-star-light4 hover:text-star-light">
                                    <div className="flex items-center gap-2">
                                        <p className="w-[120px] whitespace-nowrap overflow-hidden text-ellipsis">{option.Country} ({option.value})</p>
                                        <CheckBox checked={selectedCode === option.value} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CountryCodeSelector;