import moment from "moment";
import api from "../utils/api";
import { setUserProperties, trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";

export const generatePost = async (body) => {
    try {
        const response = await api.post('/genai/generate-post', body);
        if (response.data) {
            const subscriptionLimit = response.data?.data?.subscriptionLimit;
            setUserProperties({
                "AI credits remaining": subscriptionLimit.aiCreditLimit - subscriptionLimit.aiCreditUsage,
                "Search credits remaining": subscriptionLimit.searchLimit === null ? "Unlimited" : subscriptionLimit.searchLimit - subscriptionLimit.searchUsage
            })
        }
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const generateTemplate = async ({ content, editorText, tag, templateType, templateExample }) => {
    try {
        const response = await api.post('/genai/generate-template', { content, editorText, tag, templateType, templateExample });
        if (response.data) {
            const subscriptionLimit = response.data?.data?.subscriptionLimit;
            const subscription = response.data?.data?.subscription;

            if (subscription && subscriptionLimit) {
                setUserProperties({
                    "AI credits remaining": subscriptionLimit.aiCreditLimit - subscriptionLimit.aiCreditUsage,
                    "Search credits remaining": subscriptionLimit.searchLimit === null ? "Unlimited" : subscriptionLimit.searchLimit - subscriptionLimit.searchUsage
                })
                if (subscriptionLimit.aiCreditUsage >= subscriptionLimit.aiCreditLimit) {
                    trackEvent(MIXPANEL_EVENTS.AI_EXPIRED, {
                        "Current Plan": subscription.planName,
                        "Date of next renewal": moment(subscription.startDate).add(1, 'months').toDate(),
                        "Date of expiry": subscription.endDate
                    })
                }
            }
        }
        return response.data;
    } catch (error) {
        console.log(error)
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};