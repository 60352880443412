const FileIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.99992 3.12523C4.42462 3.12523 3.95825 3.5916 3.95825 4.1669L3.95825 15.8336C3.95825 16.4089 4.42462 16.8752 4.99992 16.8752L14.9999 16.8752C15.5752 16.8752 16.0416 16.4089 16.0416 15.8336L16.0416 4.1669C16.0416 3.5916 15.5752 3.12523 14.9999 3.12523L4.99992 3.12523ZM2.70825 4.1669C2.70825 2.90124 3.73427 1.87523 4.99992 1.87523L14.9999 1.87523C16.2656 1.87523 17.2916 2.90125 17.2916 4.1669L17.2916 15.8336C17.2916 17.0992 16.2656 18.1252 14.9999 18.1252L4.99992 18.1252C3.73427 18.1252 2.70825 17.0992 2.70825 15.8336L2.70825 4.1669Z" fill="#A489F3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.04175 5.83355C6.04175 5.48837 6.32157 5.20855 6.66675 5.20855L13.3334 5.20855C13.6786 5.20855 13.9584 5.48837 13.9584 5.83355C13.9584 6.17872 13.6786 6.45855 13.3334 6.45855L6.66675 6.45855C6.32157 6.45855 6.04175 6.17872 6.04175 5.83355Z" fill="#A489F3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.04175 9.16689C6.04175 8.82171 6.32157 8.54189 6.66675 8.54189L13.3334 8.54189C13.6786 8.54189 13.9584 8.82171 13.9584 9.16689C13.9584 9.51207 13.6786 9.79189 13.3334 9.79189L6.66675 9.79189C6.32157 9.79189 6.04175 9.51207 6.04175 9.16689Z" fill="#A489F3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.0415 12.5002C6.0415 12.1551 6.32133 11.8752 6.6665 11.8752L10.8332 11.8752C11.1783 11.8752 11.4582 12.1551 11.4582 12.5002C11.4582 12.8454 11.1783 13.1252 10.8332 13.1252L6.6665 13.1252C6.32133 13.1252 6.0415 12.8454 6.0415 12.5002Z" fill="#A489F3" />
        </svg>
    )
}
export default FileIcon;