import { Xmark } from "iconoir-react";
import DialogBox from "../../../component/DialogBox";
import moment from "moment/moment";
import { useSelector } from "react-redux";

const HEROSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="138" height="115" viewBox="0 0 138 115" fill="none">
            <path d="M56.6141 24.0484C57.9142 20.7495 62.298 20.0951 64.5049 22.8705L99.5116 66.8961C101.718 69.6715 100.093 73.7951 96.5863 74.3186L40.9557 82.6226C37.4487 83.1461 34.6901 79.6768 35.9902 76.3779L56.6141 24.0484Z" stroke="#FF9EB7" strokeWidth="4.18747" />
            <path fillRule="evenodd" clipRule="evenodd" d="M41.01 81.316C38.3415 81.7143 36.2425 79.0745 37.2318 76.5644L57.7115 24.6007C58.7007 22.0906 62.0363 21.5927 63.7155 23.7045L98.4776 67.4222C100.157 69.534 98.9202 72.6717 96.2517 73.07L41.01 81.316ZM61.7286 45.6059C61.4614 44.2165 62.4051 42.8843 63.8045 42.6754C65.2038 42.4665 66.4954 43.4651 66.6457 44.8719L68.1749 59.1923C68.2822 60.1974 67.5779 61.1083 66.5782 61.2576C65.5785 61.4068 64.639 60.7412 64.4481 59.7486L61.7286 45.6059ZM67.5304 67.6544C68.6592 67.4859 69.4377 66.4342 69.2692 65.3054C69.1007 64.1766 68.049 63.3981 66.9202 63.5666C65.7914 63.7351 65.0129 64.7868 65.1814 65.9156C65.3499 67.0444 66.4016 67.8229 67.5304 67.6544Z" fill="#FD6483" />
            <path opacity="0.56" d="M24.6072 26.592C25.7178 26.5968 26.781 27.0426 27.5629 27.8313L31.699 32.0033C32.4809 32.792 32.9175 33.859 32.9127 34.9695L32.8873 40.8443C32.8825 41.9549 32.4367 43.018 31.6481 43.7999L27.476 47.9361C26.6874 48.718 25.6204 49.1546 24.5098 49.1498L18.635 49.1244C17.5245 49.1196 16.4613 48.6738 15.6794 47.8851L11.5432 43.7131C10.7613 42.9244 10.3248 41.8574 10.3296 40.7468L10.3549 34.8721C10.3597 33.7615 10.8055 32.6983 11.5942 31.9164L15.7662 27.7803C16.5549 26.9984 17.6219 26.5618 18.7325 26.5666L24.6072 26.592Z" fill="#FFCEDB" />
            <ellipse opacity="0.2" cx="96.1496" cy="32.253" rx="3.59943" ry="3.66511" transform="rotate(3.34996 96.1496 32.253)" fill="#FD6483" />
            <path opacity="0.5" d="M117.867 68.4029C119.531 67.416 121.639 68.5998 121.662 70.5338L121.819 83.787C121.842 85.721 119.763 86.9546 118.077 86.0075L106.52 79.5173C104.834 78.5702 104.805 76.1527 106.468 75.1658L117.867 68.4029Z" fill="#FF9EB7" />
        </svg>
    )
}

const LINESVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="680" height="138" viewBox="0 0 680 138" fill="none">
            <path d="M256.368 -318.187L535.068 36.7076C569.872 81.0274 630.39 95.9445 681.805 72.8771V72.8771C759.825 37.8731 762.295 -71.9941 685.927 -110.469L482.464 -212.975M17.9576 -12.4564L-79.4323 132.137" stroke="url(#paint0_linear_1219_8007)" strokeWidth="19.7993" />
            <defs>
                <linearGradient id="paint0_linear_1219_8007" x1="406.392" y1="-346.85" x2="794.001" y2="368.127" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.261823" stopColor="#FFDFE6" />
                    <stop offset="0.555197" stopColor="#FDA1BD" />
                    <stop offset="0.635" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}
const DowngradeConfirmDialog = ({ planDetail, onSuccess, onClose, isPending }) => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;

    const planName = planDetail?.name;
    const planAmount = planDetail?.amountText;

    const expireDate = moment(subscription?.endDate).format("DD MMM’YY")
    const isOutsideIndia = authState?.locationDetail?.countryCode !== 'IN';

    return (
        <DialogBox>
            <div className="bg-white rounded-3xl shadow-drop-shadow w-[680px] overflow-hidden p-8 relative">
                <div className="absolute top-0 left-0">
                    <LINESVG />
                </div>
                <div className="flex flex-col items-center justify-center relative z-10">
                    <div className="flex w-full justify-end">
                        <button onClick={onClose} className="icon-outline-btn small border-none">
                            <Xmark />
                        </button>
                    </div>
                    <HEROSVG />
                    <p className="text-subHeadingTwoBold text-venus-default text-center">Alert!</p>
                    <p className="mt-9 text-center text-bodyOne text-gray-5">
                        Changing plan to the <span className="text-subHeadingThreeBold text-star-default">{planName}</span> plan will mean you <b>
                            lose access to some premium SuperPen features</b>
                    </p>
                    <p className="text-center text-bodyOne mt-5 text-gray-5">
                        If you press continue, you will be redirected to authenticate your payment method for which we will make a charge (and immediately refund) of <span className="text-subHeadingThreeBold text-star-default">{isOutsideIndia ? "$0.50" : "Rs 5."}</span> 
                    </p>
                    <div className="rounded-lg mt-5 bg-venus-light2 px-6 py-2">

                        <p className=" text-center text-bodyOne text-venus-default">
                            On <b>{expireDate}</b>, your current plan will be cancelled and the first charge of Rs {planAmount} will be made for your new plan
                        </p>
                    </div>
                    <div className="mt-10 flex w-full justify-between items-center">
                        <button className="filled-btn white w-[238px] loader" disabled={isPending} onClick={() => {
                            onSuccess(planDetail?._id)
                        }}>Continue</button>
                        <button className="filled-btn w-[238px]" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </DialogBox>
    );
};
export default DowngradeConfirmDialog;