import { Crown, Infinite, InfoCircle, Search } from "iconoir-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AiIcon from "../asset/svg/AiIcon";
import FreePlanLimitDialog from "./FreePlanLimitDialog";
import { trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";

const CreditWidget = () => {
    const authState = useSelector((state) => state.auth);
    const { subscriptionLimit, subscription } = authState;
    const isFreeTrial = subscription.isFreeTrial;
    const { aiCreditLimit, aiCreditUsage, searchLimit, searchUsage } = subscriptionLimit;
    const aiPercent = 100 - (aiCreditUsage / (aiCreditLimit)) * 100
    const searchPercent = 100 - (searchUsage / (searchLimit)) * 100

    const planName = isFreeTrial ? "Free" : subscription.planName?.split(' ')?.[1];
    const [openDialog, setOpenDialog] = useState(false);
    const showUpgrade = planName.toLowerCase() !== 'nova';
    return (
        <>
            {
                openDialog && (
                    <FreePlanLimitDialog title={'This premium feature is available in a paid plan'} onClose={() => { setOpenDialog(false) }} />
                )
            }
            <div className="w-full px-2 py-2 bg-white rounded-xl">
                <div className="flex justify-between items-center">
                    <p className="text-bodyThree text-gray-5">Your plan</p>
                    <div className="text-center">
                        {!subscription.isFreeTrial && (
                            <p className="text-subText text-gray-4 -m-[2px]">Super</p>
                        )}
                        <p className="text-bodyThreeBold font-semibold text-gray-5">{planName}</p>
                    </div>
                </div>
                <div className="border-b border-star-light3 w-full my-2" />
                <div className="flex items-center gap-2">
                    <p className="text-subText text-gray-6">Account Credits</p>
                    <div className="tooltip-wrapper">
                        <InfoCircle className="w-[14px] h-[14px] text-gray-4" />
                        <div className="tooltip hidden absolute bottom-3 left-1 w-max rounded-lg rounded-bl-none p-4 bg-white shadow-drop-shadow">
                            <p className="text-bodyThree text-gray-5">
                                1.⁠ ⁠Each search and applied filter counts as one search credit
                                <br />
                                2.⁠ ⁠Each AI generation, including regeneration of the same post, counts as one AI credit
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex gap-1 items-center mt-[6px]">
                    <div className="rounded-full flex items-center justify-center w-[18px] min-w-[18px] min-h-[18px] bg-star-light3">
                        <Search className="text-star-light" width={11} height={11} />
                    </div>

                    {searchLimit === null ? (
                        <div className="flex ml-auto items-center gap-1">
                            <Infinite className="w-[11px] text-star-default" />
                            <p className="text-subText text-gray-6">Unlimited</p>
                        </div>
                    ) : (
                        <>
                            <div className="h-[6px] w-full border-[0.5px] rounded-lg">
                                <div
                                    className="bg-star-light2 h-full rounded-lg" style={{
                                        width: `${searchPercent}%`
                                    }} />
                            </div>
                            <div className="text-subText text-gray-6">
                                {searchLimit - searchUsage}/{searchLimit}
                            </div>
                        </>
                    )}
                </div>
                <div className="flex gap-1 items-center mt-[6px]">
                    <div className="rounded-full flex items-center justify-center w-[18px] min-w-[18px] min-h-[18px] bg-star-light3">
                        <AiIcon className="text-star-light" width={11} height={11} />
                    </div>
                    <div className="h-[6px] w-full border-[0.5px] rounded-lg">
                        <div
                            className="w-[10px] bg-star-light2 h-full rounded-lg"
                            style={{
                                width: `${aiPercent}%`
                            }}
                        />
                    </div>
                    <div className="text-subText text-gray-6 ">
                        {aiCreditLimit - aiCreditUsage}/{aiCreditLimit}
                    </div>
                </div>
                {
                    showUpgrade && (
                        <div className="mt-2">
                            <Link to={'/plan'} className="filled-btn small w-full"  onClick={() => {
                                trackEvent(MIXPANEL_EVENTS.UPGRADE_CLICKED, {
                                    'Upgrade clicked from': 'Credit Widget'
                                })
                            }}>
                                <Crown />
                                Upgrade
                            </Link>
                        </div>
                    )
                }
            </div>
        </>
    )
}
export default CreditWidget;