import { useQuery } from "@tanstack/react-query";
import { fetchPlans } from "../../../services/payment.service";

import BottomPayment from "./BottomPayment";
import PricingHeader from "./PricingHeader";
import FeatureList from "./FeatureList";

const PlanDetail = ({ offerDetail, activeTenure, setActiveTenure }) => {
    const { data, isLoading } = useQuery({
        queryKey: ['plans'],
        queryFn: fetchPlans,
        staleTime: Infinity,
    });

    const formatPlans = (plans, billingPeriod) => {
        return (plans?.filter(plan => plan.billingPeriod === billingPeriod)
            .sort((a, b) => a.amount - b.amount) || [])
            .map(plan => {
                const amount=plan.amount;
                const actualAmount = billingPeriod === 'yearly' ? (amount / 12)?.toLocaleString() : amount?.toLocaleString();

                return ({
                    ...plan,
                    amountText: `${plan.currency === 'INR' ? '₹' : '$'}${actualAmount}`,
                })
            });
    };

    let monthlyPlan = formatPlans(data?.data?.plans, 'monthly');
    let yearlyPlan = formatPlans(data?.data?.plans, 'yearly');

    if (offerDetail?.plans) {
        const planOffers = offerDetail.plans;

        const applyDiscounts = (plans) =>
            plans.map(plan => {
                const couponPlan = planOffers.find(offer => offer.planId === plan._id);
                return couponPlan
                    ? {
                        ...plan,
                        amountAfterDiscount: `${plan.currency === 'INR' ? '₹' : '$'}${couponPlan.amountAfterDiscount}`,
                        primaryText: couponPlan.primaryText,
                        secondaryText: couponPlan.secondaryText,
                        hasOffer: true,
                    }
                    : plan;
            });

        monthlyPlan = applyDiscounts(monthlyPlan);
        yearlyPlan = applyDiscounts(yearlyPlan);
    }



    const currentPlan = activeTenure === 0 ? yearlyPlan : monthlyPlan;
    return (
        <div className="p-5 pt-0 rounded-2xl bg-star-light4 w-full relative">
            <PricingHeader planList={currentPlan} tenure={activeTenure} setActiveTenure={setActiveTenure} />
            <FeatureList />
            <BottomPayment planList={currentPlan} offerDetail={offerDetail} tenure={activeTenure} />
        </div>
    )
}
export default PlanDetail;