import { Xmark } from "iconoir-react";
import HEROSVG from '../../../asset/svg/upgrade-success.svg';
import DialogBox from "../../../component/DialogBox";

const LINESVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="680" height="222" viewBox="0 0 680 222" fill="none">
            <path d="M830.036 -91.6372L730.475 89.2437C697.557 149.048 747.484 220.645 814.98 210.427V210.427C878.482 200.814 894.685 116.753 839.307 84.2251L622.88 -42.8984C620.579 -44.2495 618.01 -45.0789 615.354 -45.3279L142.317 -89.6719C135.503 -90.3106 130.811 -82.9887 134.238 -77.0649V-77.0649C205.309 45.7794 104.858 196.843 -35.915 178.82L-51.4893 176.826" stroke="url(#paint0_linear_1224_2370)" strokeWidth="19.7993" />
            <defs>
                <linearGradient id="paint0_linear_1224_2370" x1="509.096" y1="-127.889" x2="-216.863" y2="685.334" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5F45FD" />
                    <stop offset="0.33" stop-color="#FDA1F5" />
                    <stop offset="0.635" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}
const UpgradeSuccessDialog = ({ planDetail, onClose }) => {

    const planName = planDetail?.name;
    const planText = `${planDetail?.name} ${planDetail?.billingPeriod === 'yearly' ? 'yearly plan' : 'monthly plan'}`;

    return (
        <DialogBox>
            <div className="bg-white rounded-3xl shadow-drop-shadow w-[680px] p-8 relative">
                <div className="absolute top-0 left-0">
                    <LINESVG />
                </div>
                <div className="flex flex-col items-center justify-center relative z-10">
                    <div className="flex w-full justify-end">
                        <button onClick={onClose} className="icon-outline-btn small border-none">
                            <Xmark />
                        </button>
                    </div>
                    <img src={HEROSVG} alt="success" />
                    <p className="text-bodyOne text-gray-5 mt-2">Your <b className="text-transparent bg-clip-text bg-heading-gradient-3">{planText}</b> has started successfully!  </p>
                    <div className="flex items-center justify-center mt-7">
                        <a href={'/'} className="filled-btn">
                            Get Started now
                        </a>
                    </div>
                </div>
            </div>
        </DialogBox>
    );
};
export default UpgradeSuccessDialog;