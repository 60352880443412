import { useMutation } from '@tanstack/react-query';
import { Refresh } from 'iconoir-react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PaymentFailedIcon from '../../asset/svg/paymentFailed.svg';
import PaymentSucess from '../../asset/svg/PaymentSucess.svg';
import { logout, updateSubscriptionStatus } from '../../redux/slice/auth.slice';
import { paymentRetry, paymentRetryVerified,manageBillingPlan } from '../../services/payment.service';
import { RAZORPAY_KEY } from '../../utils/config';

const LeftSvg = () => {
    return (
        <svg width="138" height="335" viewBox="0 0 138 335" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.65503 3.78198L110.922 115.764C137.954 144.25 138.436 188.765 112.026 217.829L9.49057 330.667" stroke="url(#paint0_linear_15_61)" stroke-width="11" />
            <defs>
                <linearGradient id="paint0_linear_15_61" x1="31.2993" y1="286.735" x2="199.146" y2="134.235" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8773FF" />
                    <stop offset="0.505" stop-color="#FFC0FA" />
                    <stop offset="1" stop-color="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>

    )
}
const RightSvg = () => {
    return (
        <svg width="186" height="60" viewBox="0 0 186 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.29876 46.674C-0.426666 49.174 0.201193 52.5993 2.70112 54.3247C5.20107 56.0502 8.62641 55.4223 10.3518 52.9224L1.29876 46.674ZM109.507 15.0567L112.097 10.2046L109.507 15.0567ZM185.756 49.5227L112.097 10.2046L106.918 19.9087L180.576 59.2268L185.756 49.5227ZM9.23181 35.18L1.29876 46.674L10.3518 52.9224L18.2849 41.4283L9.23181 35.18ZM112.097 10.2046C76.445 -8.82623 32.1881 1.91919 9.23181 35.18L18.2849 41.4283C38.0648 12.7697 76.1981 3.51103 106.918 19.9087L112.097 10.2046Z" fill="url(#paint0_linear_15_60)" />
            <defs>
                <linearGradient id="paint0_linear_15_60" x1="-1.97575" y1="68.576" x2="214.743" y2="-2.45139" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8773FF" />
                    <stop offset="0.505" stop-color="#FFC0FA" />
                    <stop offset="1" stop-color="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>

    )
}
const PaymentFailed = () => {
    const { subscription, email,locationDetail } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate=useNavigate();

    console.log("------location details",locationDetail)

    const { mutate, isPending } = useMutation({
        mutationFn: paymentRetry,
        onSuccess: (data) => {
            const subscription = data?.data?.subscription;

            if (!subscription) {
                console.error("Subscription data is missing:", data);
                toast.error("Failed to retrieve subscription details. Please try again later.");
                return;
            }

            const subscriptionId = subscription?.subscriptionId;
            const status = subscription?.status;

            if (!subscriptionId) {
                console.error("Subscription ID is missing:", subscription);
                toast.error("Invalid subscription details. Please contact support.");
                return;
            }

            if (status === "pending") {
                const options = {
                    key: RAZORPAY_KEY,
                    subscription_id: subscriptionId,
                    name: "SuperPen",
                    description: "Subscription Payment",
                    "subscription_card_change": 1,
                    handler: async (response) => {
                        try {
                            // console.log("Payment response:", response);
                            const result = await paymentRetryVerified(response);
                            // console.log("Payment verification result:", result);

                            if (result?.data) {
                                console.log(result?.data?.subscription?.status)
                                dispatch(updateSubscriptionStatus(result?.data?.subscription?.status));

                            } else {
                                toast.error("Payment verification failed. Please contact support.");
                            }
                        } catch (error) {
                            console.error("Error verifying payment:", error);
                            toast.error("Payment was successful, but there was an error processing your request on the server. Please try again later.");
                        }
                    },
                    modal: {
                        ondismiss: () => {
                            console.log("Payment Modal Closed");
                        },
                    },
                    theme: {
                        color: "#7145FD",
                    },
                };

                const rzp = new window.Razorpay(options);
                rzp.open();
            } else {
                console.log(`Subscription is in '${status}' state. Updating status.`);
                dispatch(updateSubscriptionStatus(status));
                navigate('/')
            }
        },
        onError: (data) => {
            toast.error(data.message || 'Server Error');
        }
    });

        const { mutate: manageBilling, isPending: isManageBillingPending } = useMutation({
            mutationFn: manageBillingPlan, // This should call your backend endpoint to create a billing portal session
            onSuccess: (data) => {
              if (!data.data?.url) {
                toast.success(data.message);
                return;
              }
                window.location.href = data.data.url;
            },
            onError: (data) => {
              toast.error(data.message || 'Server Error');
            }
          });

    const handlePayment = () => {
        if(locationDetail?.countryCode === "IN"){
            if (subscription) {
                mutate({
                    planId: subscription.planId,
                });
            }
        }else{
            manageBilling("stripe") 
        }
    };

    if (subscription && subscription.endDate) {
        const today = moment(); // Get today's date and time
        const endDate = moment(subscription.endDate); // Convert endDate to a moment object

        // Check if current time is before the subscription end date
        if (today.isBefore(endDate)) {
            return <Navigate to='/' />;
        }
    }


    const awaitingCharge = subscription.status === 'awaiting_charge';
    return (
        <>
            <div className="absolute top-2 z-[55] font-normal hover:text-star-default text-white text-btn right-8 cursor-pointer" onClick={() => {
                dispatch(logout())
            }}>
                Log out
            </div>
            {
                awaitingCharge ? (
                    <div className='h-screen top-0 z-50 flex justify-center items-center w-screen fixed'>
                        <div className="h-screen top-0 z-10 w-full absolute blur-xl bg-[#6A65A785]" />
                        <div className="bg-white rounded-3xl p-8 z-30 relative flex items-center text-center flex-col w-[625px] overflow-hidden ">
                            <div className='absolute -left-[60px] -bottom-[100px] z-10 '>
                                <LeftSvg />
                            </div>
                            <div className='absolute -right-1  top-[50px] z-10'>
                                <RightSvg />
                            </div>
                            <div className='w-[265px]'>
                                <img src={PaymentSucess} alt='payment-success' className='w-full h-full' />
                            </div>
                            <div className='relative z-20'>
                                <p className='bg-clip-text text-transparent bg-heading-gradient-3 text-bodyOneBold'>Payment method updated successfully</p>
                                <p className='text-gray-6 text-bodyTwo'>We will attempt to charge your account in the next 48 hours and update you on {email} </p>
                                <p className='text-gray-6 text-bodyTwo mt-2'>Feel free to reach out to us at <b>team@superpen.io</b>  if you have any questions!</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='h-screen top-0 z-50 flex justify-center items-center w-screen fixed'>
                        <div className="h-screen top-0 z-10 w-full absolute blur-xl bg-[#6A65A785]" />
                        <div className="bg-white rounded-3xl p-8 z-30 relative flex items-center text-center flex-col w-[625px] overflow-hidden ">
                            <div className='absolute -left-[60px] -bottom-[100px] z-10 '>
                                <LeftSvg />
                            </div>
                            <div className='absolute -right-1  top-[50px] z-10'>
                                <RightSvg />
                            </div>

                            <div className='w-[265px]'>
                                <img src={PaymentFailedIcon} alt='payment-success' className='w-full h-full' />
                            </div>
                            <p className='text-venus-default text-bodyOneBold'>Uh-oh your payment was unsuccessful</p>
                            <p className='text-gray-6 text-bodyTwo'>{locationDetail?.countryCode === "IN" ? "You will be charged Rs 5 to verify your payment method, which will be refunded immediately":"Please update your payment method to continue enjoying SuperPen"}</p>
                            <button className='filled-btn mt-8 red gap-2' disabled={isPending} onClick={handlePayment}>
                                <Refresh width={22} height={22} />  Update payment method
                            </button>
                        </div>
                    </div>
                )
            }

        </>
    )
}
export default PaymentFailed;