import { useQuery } from "@tanstack/react-query";
import { Download, Xmark } from "iconoir-react";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import CustomTable from "../../../component/CustomTable";
import { fetchInvoices } from "../../../services/payment.service";
import { trackEvent } from "../../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanel/eventType";
import CancelConfirmDialog from "../dialog/CancelConfirmDialog";

function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const columns = [
    {
        name: 'Invoice Date',
        selector: row => `${moment(row.createdAt).format("DD MMM'YY")}`,
    },
    {
        name: 'Tenure',
        selector: row => capitalizeFirstLetter(row.subscriptionId.billingPeriod),
    },
    {
        name: 'Amount',
        selector: row => `${row.currency === 'INR' ? '₹':'$'} ${row.amount / 100}`,
    },
    {
        name: 'Action',
        selector: (row) => {
            return (
                <div>
                    <a href={row.invoiceUrl || row.gatewayInvoiceUrl} target="_blank" className="tertiary-btn small" onClick={() => {
                        trackEvent(MIXPANEL_EVENTS.INVOICE_VIEWED)
                    }}>
                        <Download />
                        View Invoice
                    </a>
                </div>
            )
        },
    },
];

const InvoiceList = () => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;
    const isFreeTrial = subscription.isFreeTrial;
    const [openCancelSubscription, setOpenCancelSubscription] = useState(false);

    const query = {
        id: subscription.subscriptionId
    }

    const { data: invoiceData } = useQuery({
        queryKey: ['invoices', query],
        queryFn: ({ queryKey }) => fetchInvoices(queryKey[1]),
        staleTime: Infinity,
        retry: false,
        enabled: !isFreeTrial
    });


    return (
        <>
            {
                openCancelSubscription && (
                    <CancelConfirmDialog onClose={() => {
                        setOpenCancelSubscription(false);
                    }} />
                )
            }
            <div>
                <div className="flex justify-between mt-7">
                    <div>
                        <p className="text-bodyOneBold text-gray-6">Subscription History</p>
                        <p className="text-bodyTwo text-gray-5">Download your previous subscription invoices</p>
                    </div>
                </div>
                <div className="mt-5">
                    <CustomTable
                        data={invoiceData?.data || []}
                        columns={columns}
                        totalRows={10}
                        handlePageChange={() => { }}
                        handlePerRowsChange={() => { }}
                    />
                </div>
                {
                    !isFreeTrial && (
                        <div className="flex justify-between items-center">
                            <div>
                                <p className="text-bodyOneBold text-venus-default">Danger Zone</p>
                                <p className="text-bodyTwo text-gray-5 mt-2">Request to cancel your plan subscription</p>
                            </div>
                            <div className="my-10">
                                <button className="text-btn small white" disabled={subscription.status === 'cancelled'} onClick={() => {
                                    trackEvent(MIXPANEL_EVENTS.CANCEL_REQUEST);
                                    setOpenCancelSubscription(true);
                                }}>
                                    <Xmark />
                                    Request Cancellation
                                </button>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}
export default InvoiceList;