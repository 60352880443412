const PostGeneratorIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M11.122 17.2491L5.13884 17.2491C4.21836 17.2491 3.47217 16.503 3.47217 15.5825L3.47217 4.95085C3.47217 3.73583 4.45714 2.75086 5.67217 2.75085L14.2855 2.75085C15.206 2.75085 15.9521 3.49705 15.9521 4.41752L15.9521 10.4911" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M11.1064 14.461C12.8875 14.461 13.6639 13.7117 13.6639 11.9035C13.6639 13.7117 14.435 14.461 16.2214 14.461C14.435 14.461 13.6639 15.2321 13.6639 17.0185C13.6639 15.2321 12.8875 14.461 11.1064 14.461Z" fill="currentColor" stroke="currentColor" strokeLinejoin="round" />
            <path d="M6.83789 7.33667H12.6212" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M6.83789 9.97925H10.4524" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" />
        </svg>
    )
}
export default PostGeneratorIcon;