import { Link, useLocation } from 'react-router-dom';
import TrialExpireImg from '../asset/svg/FreeTrial.svg';
import DialogBox from './DialogBox';
import { useSelector } from 'react-redux';
import { Xmark } from 'iconoir-react';
import { useEffect } from 'react';
import { trackEvent } from '../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../utils/mixpanel/eventType';

const FreePlanLimitDialog = ({ onClose, title, subTitle }) => {
    const auth = useSelector((state) => state.auth);
    const location = useLocation();
    const pathname = location.pathname === '/' ? 'Viral content' : location.pathname;
    const name = auth.fullName;
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);
    return (
        <DialogBox>
            <div className="relative p-8 w-[680px] bg-white rounded-[25px] overflow-hidden">
                {
                    onClose && (
                        <div className="flex justify-end w-full z-20">
                            <button className='icon-outline-btn small border-none' onClick={onClose}>
                                <Xmark />
                            </button>
                        </div>
                    )
                }

                <div className="top-0 left-0 absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="276" viewBox="0 0 96 276" fill="none">
                        <path d="M-36.6045 -55L69.6626 56.9821C96.6949 85.4681 97.1761 129.984 70.766 159.047L-31.7689 271.885" stroke="url(#paint0_linear_237_9268)" strokeWidth="11" />
                        <defs>
                            <linearGradient id="paint0_linear_237_9268" x1="-9.96023" y1="227.953" x2="157.887" y2="75.4528" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#8773FF" />
                                <stop offset="0.505" stopColor="#FFC0FA" />
                                <stop offset="1" stopColor="#FBDEBC" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="text-center flex flex-col items-center justify-center">
                    <div>
                        <img src={TrialExpireImg} alt="expire" />
                    </div>
                    <p className="text-bodyOneBold">{title}</p>
                    {subTitle && (
                        <p className="mt-2 text-bodyOne text-gray-5"> {subTitle} </p>
                    )}
                    <Link to={'/plan'} className="filled-btn mt-6" onClick={() => {
                        trackEvent(MIXPANEL_EVENTS.UPGRADE_CLICKED, {
                            'Upgrade clicked from': pathname
                        })
                    }}>Subscribe now</Link>
                </div>
            </div>
        </DialogBox>
    )
}
export default FreePlanLimitDialog;