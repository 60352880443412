import { useEffect } from "react";
import MainLayout from "../../layout/main";
import { trackPageView } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import InvoiceList from "./invoiceList";
import PlanList from "./planList/index";

const Plan = () => {
    useEffect(() => {
        trackPageView(MIXPANEL_EVENTS.SETTINGS_VIEWED, {
            "Page viewed": "Plan & Billing, Preferences"
        })
    }, [])

    return (
        <>
            <MainLayout>
                <div className="rounded-2xl px-5 py-8 bg-star-light3">
                    <PlanList />
                    <InvoiceList />
                </div>
            </MainLayout>
        </>
    )
}
export default Plan;
