import { Link } from "react-router-dom";
import PlanList from "../plan/planList";
import { Xmark } from "iconoir-react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slice/auth.slice";
import { useEffect } from "react";

const PaymentPending = () => {
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout());
    }
    useEffect(() => {
        dispatch(logout());
    }, [])
    return (
        <>
            <div className="bg-star-light4 p-14">
                <Link to={'/'} className="absolute right-5 small top-5 icon-outline-btn border-none">
                    <Xmark />
                </Link>
                <button onClick={handleLogout} className="outlined-btn absolute top-2 left-14 small">
                    Logout
                </button>
                <PlanList hideHeading={true} />
            </div>
        </>
    )
};

export default PaymentPending;
