import api from "../utils/api";

export const disconnectLinkedin = async () => {
    try {
        const response = await api.post('/linkedin/revoke');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
