import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SegmentedBtn from "../../../component/SegmentedBtn";
import CouponDialog from "../../../component/CouponDialogBox";
import CouponSection from "./CouponSection";
import PlanDetail from "./PlanDetail";

const PlanList = ({ hideHeading = false }) => {
    const [activeTenure, setActiveTenure] = useState(1);
    const [openCouponDialog, setOpenCouponDialog] = useState(false);
    const [validCoupon, setValidCoupon] = useState(false);
    const [offerDetail, setOfferDetail] = useState(null);

    const authState = useSelector((state) => state.auth);
    const { subscription, locationDetail } = authState;
    const isFreeTrial = subscription?.isFreeTrial;
    const currency = locationDetail?.currency;
    
    const countryCode = currency === 'INR' ? false : true

    const tabs = [
        <>Yearly <span className="text-bodyThree" style={{ color: activeTenure === 0 ? "#7DFF8A" : undefined }}>(Save 30%)</span></>,
        'Monthly'
    ];

    const handleRemoveCoupon = () => {
        setValidCoupon(false);
        setOfferDetail({});
    };

    useEffect(() => {
        if (offerDetail || subscription?.billingPeriod === 'monthly') {
            setActiveTenure(1);
        }
    }, [offerDetail, subscription?.billingPeriod]);

    return (
        <>
            {openCouponDialog && (
                <CouponDialog
                    onClose={() => setOpenCouponDialog(false)}
                    setValidCoupon={setValidCoupon}
                    setPlans={() => { }}
                    setCouponName={() => { }}
                    currency={currency}
                    setOfferId={setOfferDetail}
                />
            )}
            <div>
                {
                    !hideHeading && (
                        <>
                            <p className="text-subHeadingTwoBold text-space-default">Your Plan & Billing</p>
                            <p className="text-bodyTwo bg-clip-text text-transparent bg-heading-gradient-3">Manage your billing information, subscription, and invoices</p>
                        </>
                    )
                }
                <div className="flex justify-between mt-7">
                    <div>
                        <SegmentedBtn list={tabs} onChange={setActiveTenure} activeIndex={activeTenure} />
                    </div>
                    <CouponSection
                        isFreeTrial={isFreeTrial}
                        validCoupon={validCoupon}
                        offerDetail={offerDetail}
                        setOpenCouponDialog={setOpenCouponDialog}
                        handleRemoveCoupon={handleRemoveCoupon}
                        countryCode={countryCode}
                    />
                </div>
            </div>
            <div className="mt-6">
                <PlanDetail
                    offerDetail={offerDetail}
                    activeTenure={activeTenure}
                    setActiveTenure={setActiveTenure}
                />
            </div>
        </>
    );
};

export default PlanList;
