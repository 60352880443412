import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { paymentVerified, subscribePlan, manageBillingPlan } from "../../../services/payment.service";
import { toast } from "react-toastify";
import { RAZORPAY_KEY, STRIPE_KEY } from "../../../utils/config";
import { updateCancelSubscription, updateSubscription } from "../../../redux/slice/auth.slice";
import { useState, useMemo, useRef, useEffect } from "react";
import PaymentSuccessModel from "../../payment/PaymentSucessModel";
import UpgradeConfirmDialog from "../dialog/UpgradeConfirmDialog";
import DowngradeConfirmDialog from "../dialog/DowngradeConfirmDialog";
import DowngradeSuccessDialog from "../dialog/DowngradeSuccessDialog";
import UpgradeSuccessDialog from "../dialog/UpgradeSuccessDialog";
import { FastArrowDown } from "iconoir-react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { trackEvent } from "../../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanel/eventType";

const BottomPayment = ({ planList, offerDetail, tenure }) => {
    const authState = useSelector((state) => state.auth);
    const isCancelled = authState.subscription?.status === 'cancelled';
    const isFreeTrial = authState.subscription?.isFreeTrial;
    const expireDate = authState.subscription?.endDate;
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [isSticky, setIsSticky] = useState(false);
    const stickyRef = useRef(null);
    const [openSuccess, setOpenSuccess] = useState(false);

    const paymentMode = authState?.locationDetail?.countryCode === 'IN' ? "Razorpay" : "Stripe";
    const [openUpgradeConfirm, setUpgradeConfirm] = useState(false);
    const [openSuccessUpgrade, setSuccessUpgrade] = useState(false);

    const [openDowngradeConfirm, setDowngradeConfirm] = useState(false);
    const [openSuccessDowngrade, setSuccessDowngrade] = useState(false);

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [response, setResponse] = useState(null);
    const [amount, setAmount] = useState(null);
    const [openCancelSubscription, setOpenCancelSubscription] = useState(false);
    const [activeLoaderId, setActiveLoaderId] = useState(null);
    const bottomList = useMemo(() => {
        const cometData = planList.find((planItem) => planItem.name.toLowerCase() === 'super comet');
        const starData = planList.find((planItem) => planItem.name.toLowerCase() === 'super star');
        const novaData = planList.find((planItem) => planItem.name.toLowerCase() === 'super nova');

        return [
            { ...cometData, theme: 'yellow' },
            { ...starData, theme: 'star' },
            { ...novaData, theme: 'blue' },
        ];
    }, [planList]);


    const [searchParams] = useSearchParams(); // Get URL params

    useEffect(() => {
        const status = searchParams.get("status");
        const amountValue = searchParams.get("amount");

        if (status === "success") {
            setAmount(amountValue);
            setOpenSuccess(true);
        } else if (status === "cancel") {
            setAmount(amountValue);
            setOpenCancelSubscription(true);
        }
    }, [searchParams]);

    const handlePaymentSuccess = async (response) => {
        try {
            setIsLoading(true);
            const result = await paymentVerified(response);
            setResponse(result.data);
            if (openDowngradeConfirm) {
                setDowngradeConfirm(false);
                setSuccessDowngrade(true);
                dispatch(updateCancelSubscription());
            } else if (openUpgradeConfirm) {
                setUpgradeConfirm(false);
                setSuccessUpgrade(true);
                dispatch(updateSubscription(result.data));
            } else {
                setOpenSuccess(true);
                dispatch(updateSubscription(result.data));
            }
        } catch (error) {
            console.error(error);
            toast.error('Payment was successful, but there was an error processing your request on the server. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const { mutate, isPending } = useMutation({
        mutationFn: subscribePlan,
        onSuccess: (data) => {
            if (!data.data?.subscriptionId && !data.data?.sessionId) {
                toast.success(data.message);  // If neither subscriptionId nor sessionId exists, show the message
                return;
            }
            if (data.data?.paymentGateway === 'razorpay') {
                // Razorpay Payment Flow
                const options = {
                    key: RAZORPAY_KEY,  // Replace with Razorpay Key ID
                    subscription_id: data.data.subscriptionId,
                    name: 'SuperPen',
                    description: 'Subscription Payment',
                    handler: handlePaymentSuccess,
                    modal: {
                        ondismiss: () => {
                            console.log('Payment Model Closed');
                        },
                    },
                    theme: {
                        color: '#7145FD',
                    },
                };
                const rzp = new window.Razorpay(options);
                rzp.open();
            } else if (data.data?.paymentGateway === 'stripe') {
                // Stripe Payment Flow
                const stripe = window.Stripe(STRIPE_KEY); // Replace with your Stripe public key
                stripe.redirectToCheckout({ sessionId: data.data.sessionId })
                    .then(function (result) {
                        if (result.error) {
                            toast.error(result.error.message); // Display any error if occurs during Stripe checkout redirection
                        }
                    })
                    .catch((error) => {
                        toast.error('Error redirecting to Stripe checkout.');
                        console.error(error);
                    });
            } else {
                toast.error('Unknown payment gateway'); // Show an error if the gateway is not recognized
            }
            setActiveLoaderId(null)

        },
        onError: (data) => {
            toast.error(data.message || 'Server Error');
            setActiveLoaderId(null)
        }
    });

    const handlePayment = (planId) => {
        if (planId) {
            setActiveLoaderId(planId);
            mutate({
                planId,
                name: offerDetail?.name,
                paymentMethod: offerDetail?.paymentMethod
            });
        }

    };

    const currentAmount = authState?.subscription?.amountPaid;
    const currentTenure = authState?.subscription?.billingPeriod;

    const checkIsUpgrade = (amount) => {
        if (amount > currentAmount) {
            return true;
        }
        return false;
    }

    const checkIsDowngrade = (amount, tenure) => {
        if (currentTenure === 'monthly') {
            if (tenure === 'monthly' && amount < currentAmount) {
                return true;
            }
        } else if (currentTenure === 'yearly') {
            if (tenure === 'yearly' && amount < currentAmount) {
                return true;
            }
        }
        return false;
    }

    const hideBottom = (currentTenure === 'yearly' && tenure === 1) || isCancelled;

    useEffect(() => {
        const handleScroll = () => {
            if (stickyRef.current) {
                const rect = stickyRef.current.getBoundingClientRect();
                //@Note: If both are at bottom than sticky
                setIsSticky(!(rect.bottom < window.innerHeight - 10));
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const { mutate: manageBilling, isPending: isManageBillingPending } = useMutation({
        mutationFn: manageBillingPlan, // This should call your backend endpoint to create a billing portal session
        onSuccess: (data) => {
            if (!data.data?.url) {
                toast.success(data.message);
                return;
            }
            window.location.href = data.data.url;
        },
        onError: (data) => {
            toast.error(data.message || 'Server Error');
        }
    });

    const handleStripeUpgradeDowngrade = (item) => {
        manageBilling(item)
    }

    const bottomText = isCancelled ? 'Your plan will be activated till' : 'Your plan will be renewed on';

    // Tracking
    const trackPaymentInit = (props) => {
        trackEvent(MIXPANEL_EVENTS.PAYMENT_INITIATED,
            {
                ...props,
                tenure: tenure === 0 ? "yearly" : "monthly"
            })
    }
    return (
        <>
            {openSuccess && (
                <PaymentSuccessModel amount={`${response?.subscription?.planName ? response?.subscription?.planName : amount} plan`} />
            )}
            {openUpgradeConfirm && (
                <UpgradeConfirmDialog planDetail={selectedPlan} onSuccess={handlePayment} onClose={() => { setUpgradeConfirm(false); }} isPending={isPending || isLoading} />
            )}
            {openSuccessDowngrade && (
                <DowngradeSuccessDialog planDetail={selectedPlan} onClose={() => { setSuccessDowngrade(false); }} />
            )}
            {openSuccessUpgrade && (
                <UpgradeSuccessDialog planDetail={selectedPlan} onClose={() => { setSuccessUpgrade(false); }} />
            )}
            {openDowngradeConfirm && (
                <DowngradeConfirmDialog planDetail={selectedPlan} onSuccess={handlePayment} onClose={() => { setDowngradeConfirm(false); }} isPending={isPending || isLoading} />
            )}

            <div
                ref={stickyRef}
                className="grid grid-cols-4 w-full bg-white sticky bottom-0 rounded-b-2xl"
            >
                <div style={{ height: hideBottom ? '12px' : '88px' }} >
                    {
                        isSticky && (
                            <div className="flex items-center justify-center flex-col pt-3 gap-2.5">
                                <p className="text-star-light2 text-center">Scroll to <br /> see more</p>
                                <FastArrowDown className="text-star-light" width={20} height={20} />
                            </div>
                        )
                    }

                </div>
                {
                    isFreeTrial && (
                        bottomList.map((item, index) => {
                            const isActive = item._id === authState?.subscription?.planId;
                            return (
                                <div
                                    key={index}
                                    className={`flex flex-col  items-center justify-center text-center px-2.5 ${getBorderStyle(item.theme)} py-4`}
                                    style={{
                                        boxShadow: isSticky ? "4px 0px 13.4px 0px rgba(113, 69, 253, 0.23)" : undefined
                                    }}

                                >
                                    <button
                                        className={`filled-btn w-full ${((isPending || isManageBillingPending) && (activeLoaderId === item._id)) ? "loader" : ''} ${item.theme}`}
                                        disabled={isPending}
                                        onClick={() => {
                                            handlePayment(item._id);
                                            trackPaymentInit({ planId: item?._id, isFreeTrial: true, planName: item?.name });
                                        }}
                                    >Start Now</button>
                                    {
                                        isActive ? (
                                            <div className="flex items-center justify-center text-center">
                                                <p className="text-bodyThree mt-2.5 text-gray-5">{bottomText}</p>
                                                <p className="text-bodyTwoBold mt-1 text-star-default">{moment(expireDate).format('DD MMMM YYYY')}</p>
                                            </div>
                                        ) : (
                                            <p className="text-bodyThree mt-2.5 text-gray-5">Cancel anytime  | Don't get charged again </p>
                                        )
                                    }
                                </div>
                            )
                        })
                    )
                }
                {
                    !isFreeTrial && (
                        bottomList.map((item, index) => {
                            const isUpgrade = checkIsUpgrade(item.amount);
                            const isDowngrade = checkIsDowngrade(item.amount, item.billingPeriod);
                            const isActive = item._id === authState?.subscription?.planId;
                            let buttonText = '';
                            if (isCancelled) {
                                return (
                                    <div
                                        key={index}
                                        className={`flex flex-col items-center justify-center text-center ${getBorderStyle(item.theme)}`}
                                    />
                                )
                            }
                            if (isActive) {
                                buttonText = 'Current Plan';
                            } else if (isUpgrade) {
                                buttonText = 'Upgrade'
                            } else if (isDowngrade) {
                                buttonText = 'Change plan'
                            } else {
                                return (
                                    <div
                                        key={index}
                                        className={`flex flex-col items-center justify-center text-center ${getBorderStyle(item.theme)}`}
                                    />
                                )
                            }
                            return (
                                <div
                                    key={index}
                                    className={`flex flex-col items-center justify-center text-center px-2.5 ${getBorderStyle(item.theme)} py-4`}
                                    style={{
                                        boxShadow: isSticky ? "4px 0px 13.4px 0px rgba(113, 69, 253, 0.23)" : undefined
                                    }}
                                >
                                    <button
                                        className={`filled-btn w-full ${((isPending || isManageBillingPending) && (activeLoaderId === item._id)) ? "loader" : ''} ${item.theme} ${isDowngrade ? 'white' : ''}`}
                                        disabled={isActive || isPending || isManageBillingPending}
                                        onClick={() => {
                                            setActiveLoaderId(item._id);
                                            trackPaymentInit({ "Plan chosen": item?.name, "PG": paymentMode });
                                            if (isUpgrade) {
                                                if (item.provider === "stripe") {
                                                    handleStripeUpgradeDowngrade(item?.provider)
                                                    return
                                                } else {
                                                    setUpgradeConfirm(true);
                                                    setSelectedPlan(item);
                                                }
                                            } else if (isDowngrade) {
                                                trackEvent(MIXPANEL_EVENTS.DOWNGRADE_CLICKED, {
                                                    "Downgrade to": item?.name
                                                })
                                                if (item.provider === "stripe") {
                                                    handleStripeUpgradeDowngrade(item?.provider)
                                                    return
                                                } else {
                                                    setDowngradeConfirm(true);
                                                    setSelectedPlan(item);
                                                }
                                            } else {
                                                handlePayment(item._id);
                                            }
                                            setActiveLoaderId(null);
                                        }}
                                    >{buttonText}
                                    </button>
                                    {
                                        isActive ? (
                                            <div className="flex flex-col mt-2.5 items-center justify-center text-center">
                                                <p className="text-bodyThree  text-gray-5">{bottomText} </p>
                                                <p className="text-bodyTwoBold  text-star-default">{moment(expireDate).format('Do MMMM YYYY')}</p>
                                            </div>
                                        ) : (
                                            <p className="text-bodyThree mt-2.5 text-gray-5">Cancel anytime  | Don't get charged again <br /> <span className="invisible">not visible</span></p>
                                        )
                                    }
                                </div>
                            )
                        })
                    )
                }
            </div>
        </>
    );
};

const getBorderStyle = (theme) => {
    switch (theme) {
        case 'yellow':
            return 'border-yellow-dark border-b border-l rounded-bl-2xl';
        case 'blue':
            return 'border-sky-dark border-r border-b border-l rounded-br-2xl';
        case 'star':
            return 'border-star-default border-b border-l bg-star-light3';
        default:
            return '';
    }
};
export default BottomPayment;