import { ArrowUpRight, Bookmark, ChatLines } from "iconoir-react";
import moment from "moment/moment";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import AvatarIcon from "../asset/svg/AvatarIcon";
import HeartAndThumbIcon from "../asset/svg/HeartAndThumbIcon";
import RepeatIcon from "../asset/svg/RepeatIcon";
import FreePlanLimitDialog from '../component/FreePlanLimitDialog';
import { formatToK } from "../utils/formatter";
import { trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";



const ImageComponent = ({ imageURLs }) => {
    const imageCount = imageURLs.length;

    if (imageCount === 0) {
        return null;
    }

    if (imageCount === 1) {
        return (
            <div className="relative overflow-hidden mx-auto">
                <img src={imageURLs[0]} alt="image1" className="w-full h-full object-cover max-h-[360px]" />
                <div className="absolute inset-0 flex items-center justify-center gap-2 p-2 rounded-lg">
                    <div className="bg-opacity-50 w-1/2.3 h-113 flex items-center justify-center gap-2 p-2 rounded-lg" style={{ background: "#FFF" }}>
                        <div className="w-6 h-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 23 14" fill="none" className="m-1">
                                <path d="M21.0004 0.75V6.75C21.0004 6.94891 20.9214 7.13968 20.7807 7.28033C20.6401 7.42098 20.4493 7.5 20.2504 7.5C20.0515 7.5 19.8607 7.42098 19.7201 7.28033C19.5794 7.13968 19.5004 6.94891 19.5004 6.75V2.56031L11.781 10.2806C11.7114 10.3504 11.6287 10.4057 11.5376 10.4434C11.4466 10.4812 11.349 10.5006 11.2504 10.5006C11.1519 10.5006 11.0543 10.4812 10.9632 10.4434C10.8722 10.4057 10.7894 10.3504 10.7198 10.2806L7.50042 7.06031L1.28104 13.2806C1.14031 13.4214 0.94944 13.5004 0.750417 13.5004C0.551394 13.5004 0.360523 13.4214 0.219792 13.2806C0.0790616 13.1399 0 12.949 0 12.75C0 12.551 0.0790616 12.3601 0.219792 12.2194L6.96979 5.46937C7.03945 5.39964 7.12216 5.34432 7.21321 5.30658C7.30426 5.26884 7.40186 5.24941 7.50042 5.24941C7.59898 5.24941 7.69657 5.26884 7.78762 5.30658C7.87867 5.34432 7.96139 5.39964 8.03104 5.46937L11.2504 8.68969L18.4401 1.5H14.2504C14.0515 1.5 13.8607 1.42098 13.7201 1.28033C13.5794 1.13968 13.5004 0.948912 13.5004 0.75C13.5004 0.551088 13.5794 0.360322 13.7201 0.21967C13.8607 0.0790178 14.0515 0 14.2504 0H20.2504C20.4493 0 20.6401 0.0790178 20.7807 0.21967C20.9214 0.360322 21.0004 0.551088 21.0004 0.75Z" fill="#7145FD" />
                            </svg>
                        </div>
                        <div className="text-bodyThreeBold bg-heading-gradient-3 bg-clip-text text-transparent ">
                            Top Performing Post
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (imageCount === 2) {
        return (
            <div className="grid mt-2 grid-cols-2 gap-1">
                <img src={imageURLs[0]} alt="image1" className="w-full h-full rounded-lg overflow-hidden object-cover max-h-[400px]" />
                <img src={imageURLs[1]} alt="image2" className="w-full h-full rounded-lg overflow-hidden object-cover max-h-[400px]" />
            </div>
        );
    }

    if (imageCount >= 3) {
        return (
            <div className="grid mt-2 grid-cols-2 gap-1 overflow-hidden">
                <img src={imageURLs[0]} alt="image1" className="w-full h-full rounded-lg object-cover max-h-[260px]" />

                <div className="grid grid-rows-2 gap-1 h-full">
                    <div>
                        <img src={imageURLs[1]} alt="image2" className="w-full h-[120px] rounded-lg object-cover" />
                    </div>
                    <div className="relative">
                        <img src={imageURLs[2]} alt="image3" className="w-full h-[120px] rounded-lg object-cover" />
                        {
                            imageCount > 3 && (
                                <div className="absolute z-10 inset-0 flex justify-center items-center bg-gradient-to-b from-transparent to-black rounded-lg">
                                    <p className="text-subHeadingOne text-white">
                                        +{imageCount - 3}
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        );
    }

};

const BlurCard = (props) => {
    const [open, setOpen] = useState(false);
    const [bookmarkModal, setbookmarkModal] = useState(false)
    const [openLimitDialog, setOpenLimitDialog] = useState(false);

    const navigate = useNavigate();

    const handleBookmark = () => {

        setOpenLimitDialog(true)
    }

    const {
        sharesCount,
        commentsCount,
        totalLikeCount,
        imageURL,
        publishedDate,
        userId,
        url,
        postType,
    } = props;

    return (
        <>
            <div className="min-w-[360px]">
                {
                    openLimitDialog && (
                        <FreePlanLimitDialog title={"This is one of the top posts in our collection"} subTitle={"To get access to this and 8M+ more, upgrade to a paid plan"} onClose={() => { setOpenLimitDialog(false) }} />
                    )
                }
                <div className="p-4 py-2 flex justify-between items-center bg-white rounded-t-xl ">
                    <div className="flex gap-2 items-center">
                        <div>
                            <AvatarIcon width="36" height="36" src={userId?.profilePhoto} />
                        </div>
                        <div className="flex flex-col">
                            <div className="flex items-center hover:text-star-default hover:underline">
                                <button className="text-bodyTwo " target="_blank" onClick={() => {
                                    setOpenLimitDialog(true)
                                }}>{userId?.fullName || userId?.username}
                                </button>
                                <ArrowUpRight className="ml-1" width={15} height={15} />
                            </div>
                            <p className="text-subText text-gray-5">3 days ago</p>
                        </div>
                    </div>
                    {/* <a href={url} className="text-btn small w-[38px] h-[38px]" style={{ padding: '6px' }} target="_blank" rel="noreferrer" onClick={() => {
                        trackEvent(MIXPANEL_EVENTS.POST_CLICK, trackingData);
                        incrementUserProperty('post_clicked');
                    }}>
                        <Link width={24} height={24} color="#A489F3" />
                    </a> */}
                    {
                        <div className="cursor-pointer icon-outline-btn border-none p-2" onClick={() => {
                            handleBookmark()
                        }} >
                            <Bookmark color="#7145FD" className="" />
                        </div>
                    }
                </div>
                <div className="bg-gray-moon hover:bg-white cursor-pointer" onClick={() => {
                    handleBookmark()
                }}>
                    {
                        imageURL?.length > 0 && (
                            <ImageComponent imageURLs={imageURL} />
                        )
                    }
                </div>
                <div className="p-4 py-2 justify-between items-center bg-white rounded-b-xl">
                    <div className="flex justify-between items-center m-3">
                        <div className="flex items-center gap-1">
                            <div >
                                <HeartAndThumbIcon />
                            </div>
                            <p className="text-bodyThree">{formatToK(totalLikeCount)}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <div className="flex gap-[6px] items-center">
                                <ChatLines width={20} height={20} color="#A489F3" />
                                <p className="text-bodyThree">{formatToK(commentsCount)}</p>
                            </div>
                            <div className="flex gap-[6px] items-center">
                                <RepeatIcon width={20} height={20} color="#A489F3" />
                                <p className="text-bodyThree">{formatToK(sharesCount)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <button className="filled-btn small w-full text-base" onClick={() => {
                            navigate('/plan');
                            trackEvent(MIXPANEL_EVENTS.UPGRADE_CLICKED, {
                                'Upgrade clicked from': 'Blur card'
                            })
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                <path d="M17.0155 13.7833L18.333 5L13.083 8L10.833 5L8.58301 8L3.33301 5L4.65051 13.7833C4.75541 14.4827 5.35614 15 6.06328 15H10.833H15.6027C16.3099 15 16.9106 14.4827 17.0155 13.7833Z" fill="#7145FD" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.97998 4.48426C2.78236 4.61954 2.67941 4.85587 2.71494 5.09271L4.03244 13.8761C4.18323 14.8813 5.04678 15.625 6.06329 15.625H15.6028C16.6193 15.625 17.4828 14.8813 17.6336 13.8761L18.9511 5.09271C18.9866 4.85587 18.8837 4.61954 18.6861 4.48426C18.4884 4.34898 18.2309 4.33853 18.0229 4.45735L13.252 7.18361L11.333 4.625C11.215 4.46762 11.0297 4.375 10.833 4.375C10.6363 4.375 10.4511 4.46762 10.333 4.625L8.41407 7.18361L3.64311 4.45735C3.43517 4.33853 3.17761 4.34898 2.97998 4.48426ZM4.14237 6.18233L8.27294 8.54265C8.54661 8.69904 8.8939 8.62716 9.08302 8.375L10.833 6.04167L12.583 8.375C12.7721 8.62716 13.1194 8.69904 13.3931 8.54265L17.5237 6.18233L16.3974 13.6906C16.3384 14.084 16.0005 14.375 15.6028 14.375H6.06329C5.66553 14.375 5.32762 14.084 5.26861 13.6906L4.14237 6.18233Z" fill="#F7F6FF" />
                            </svg>
                            Upgrade now
                        </button>
                    </div>
                </div>

            </div>
        </>
    )
}
export default BlurCard;