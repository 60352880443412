import { Xmark } from "iconoir-react";
import DialogBox from "../../../component/DialogBox";
import { useState } from "react";
import InputField from "../../../component/custom-form/InputField";
import MultiSelectBox from "../../../component/custom-form/MultiSelectBox";
import { useMutation } from "@tanstack/react-query";
import { unsubscribePlan } from "../../../services/payment.service";
import { trackEvent } from "../../../utils/mixpanel";
import { updateCancelSubscription } from "../../../redux/slice/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanel/eventType";
import CancelSubscriptionSVG from '../../../asset/svg/CancelSubscribtion.svg';
import moment from "moment/moment";
import { EXTERNAL_LINKS } from "../../../constent/links";

const HEROSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="138" height="115" viewBox="0 0 138 115" fill="none">
            <path d="M56.6141 24.0484C57.9142 20.7495 62.298 20.0951 64.5049 22.8705L99.5116 66.8961C101.718 69.6715 100.093 73.7951 96.5863 74.3186L40.9557 82.6226C37.4487 83.1461 34.6901 79.6768 35.9902 76.3779L56.6141 24.0484Z" stroke="#FF9EB7" strokeWidth="4.18747" />
            <path fillRule="evenodd" clipRule="evenodd" d="M41.01 81.316C38.3415 81.7143 36.2425 79.0745 37.2318 76.5644L57.7115 24.6007C58.7007 22.0906 62.0363 21.5927 63.7155 23.7045L98.4776 67.4222C100.157 69.534 98.9202 72.6717 96.2517 73.07L41.01 81.316ZM61.7286 45.6059C61.4614 44.2165 62.4051 42.8843 63.8045 42.6754C65.2038 42.4665 66.4954 43.4651 66.6457 44.8719L68.1749 59.1923C68.2822 60.1974 67.5779 61.1083 66.5782 61.2576C65.5785 61.4068 64.639 60.7412 64.4481 59.7486L61.7286 45.6059ZM67.5304 67.6544C68.6592 67.4859 69.4377 66.4342 69.2692 65.3054C69.1007 64.1766 68.049 63.3981 66.9202 63.5666C65.7914 63.7351 65.0129 64.7868 65.1814 65.9156C65.3499 67.0444 66.4016 67.8229 67.5304 67.6544Z" fill="#FD6483" />
            <path opacity="0.56" d="M24.6072 26.592C25.7178 26.5968 26.781 27.0426 27.5629 27.8313L31.699 32.0033C32.4809 32.792 32.9175 33.859 32.9127 34.9695L32.8873 40.8443C32.8825 41.9549 32.4367 43.018 31.6481 43.7999L27.476 47.9361C26.6874 48.718 25.6204 49.1546 24.5098 49.1498L18.635 49.1244C17.5245 49.1196 16.4613 48.6738 15.6794 47.8851L11.5432 43.7131C10.7613 42.9244 10.3248 41.8574 10.3296 40.7468L10.3549 34.8721C10.3597 33.7615 10.8055 32.6983 11.5942 31.9164L15.7662 27.7803C16.5549 26.9984 17.6219 26.5618 18.7325 26.5666L24.6072 26.592Z" fill="#FFCEDB" />
            <ellipse opacity="0.2" cx="96.1496" cy="32.253" rx="3.59943" ry="3.66511" transform="rotate(3.34996 96.1496 32.253)" fill="#FD6483" />
            <path opacity="0.5" d="M117.867 68.4029C119.531 67.416 121.639 68.5998 121.662 70.5338L121.819 83.787C121.842 85.721 119.763 86.9546 118.077 86.0075L106.52 79.5173C104.834 78.5702 104.805 76.1527 106.468 75.1658L117.867 68.4029Z" fill="#FF9EB7" />
        </svg>
    )
}

const LINESVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="680" height="138" viewBox="0 0 680 138" fill="none">
            <path d="M256.368 -318.187L535.068 36.7076C569.872 81.0274 630.39 95.9445 681.805 72.8771V72.8771C759.825 37.8731 762.295 -71.9941 685.927 -110.469L482.464 -212.975M17.9576 -12.4564L-79.4323 132.137" stroke="url(#paint0_linear_1219_8007)" strokeWidth="19.7993" />
            <defs>
                <linearGradient id="paint0_linear_1219_8007" x1="406.392" y1="-346.85" x2="794.001" y2="368.127" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.261823" stopColor="#FFDFE6" />
                    <stop offset="0.555197" stopColor="#FDA1BD" />
                    <stop offset="0.635" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}
const LINESVG2 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="625" height="149" viewBox="0 0 625 149" fill="none">
            <path d="M-209.654 -164.309L-110.092 16.5719C-77.1742 76.3766 -127.101 147.973 -194.597 137.755V137.755C-258.099 128.142 -274.302 44.0812 -218.924 11.5532L-2.49682 -115.57C-0.196528 -116.921 2.37248 -117.751 5.02858 -118L477.587 -162.299C484.602 -162.956 489.432 -155.419 485.904 -149.32V-149.32C413.73 -24.5705 513.43 129.411 656.791 114.605L675.413 112.682" stroke="url(#paint0_linear_1230_2513)" strokeWidth="19.7993" />
            <defs>
                <linearGradient id="paint0_linear_1230_2513" x1="111.287" y1="-200.561" x2="837.245" y2="612.662" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.33" stopColor="#FDA1F5" />
                    <stop offset="0.635" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const LINESVG3 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="680" height="493" viewBox="0 0 680 493" fill="none">
            <path d="M704.123 422.801L837.952 404.656C875.835 399.52 909.882 378.877 931.953 347.663L955.781 313.966C992.473 262.075 979.67 190.203 927.316 154.175L686.361 -11.6436C684.213 -13.1219 681.772 -14.1217 679.204 -14.5755L356.087 -71.6779C353.83 -72.0767 351.518 -72.0461 349.273 -71.5874L276.859 -56.7985C113.129 -23.3603 -7.97715 115.507 -18.8364 282.263L-32.4898 491.927" stroke="url(#paint0_linear_1220_6330)" strokeWidth="19.7993" />
            <defs>
                <linearGradient id="paint0_linear_1220_6330" x1="526.406" y1="-89.8745" x2="-199.552" y2="723.348" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.33" stopColor="#FDA1F5" />
                    <stop offset="0.635" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}
const CancelConfirmDialog = ({ onClose }) => {
    const [step, setStep] = useState(0);
    const handleNext = () => {
        setStep((prev) => prev + 1);
    }
    const componentList = [
        <CancelStepOne onClose={onClose} onNextStep={handleNext} />,
        <CancelStepTwo onClose={onClose} onNextStep={handleNext} />,
        <CancelStepThreeSuccess onClose={onClose} />
    ];
    return (
        <DialogBox>
            {componentList[step]}
        </DialogBox>
    );
};

const CancelStepOne = ({ onClose, onNextStep }) => {
    const handleClose=()=>{
        window?.scrollTo({ top: 0, behavior: "smooth" });
        onClose();
    }
    return (
        <div className="bg-white rounded-3xl overflow-hidden shadow-drop-shadow w-[680px] p-8 relative">
            <div className="absolute top-0 left-0">
                <LINESVG />
            </div>
            <div className="flex flex-col items-center justify-center relative z-10">
                <div className="flex w-full justify-end">
                    <button onClick={onClose} className="icon-outline-btn small border-none">
                        <Xmark />
                    </button>
                </div>
                <HEROSVG />
                <p className="text-subHeadingTwoBold text-venus-default text-center">Alert!</p>
                <p className="text-bodyOne text-center text-gray-5">Users who cancel their SuperPen subscription typically <b>run out of <br />  inspiration</b> and stop posting on LinkedIn within a month.</p>
                <div className="rounded-lg bg-gray-moon p-2.5 mt-16">
                    <p className="text-subHeadingThree text-gray-5">Would a you like to <b>downgrade</b> instead?</p>
                </div>
                <div className="mt-[90px] flex w-full justify-between items-center">
                    <button className="filled-btn w-[238px] light-red" onClick={onNextStep}>Continue with cancellation</button>
                    <button className="filled-btn w-[238px]" onClick={handleClose}>Downgrade instead</button>
                </div>
            </div>
        </div>
    )
}
const CancelStepTwo = ({ onClose, onNextStep }) => {

    const dispatch = useDispatch();

    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedError, setSelectedError] = useState("");

    const [reason, setReason] = useState("");
    const [reasonError, setReasonError] = useState("");
    const options = [
        'Repetitive posts in the content library',
        'Don’t want to post on LinkedIn anymore',
        'Too costly',
        'Found a better tool',
        'None of these'
    ]
    const handleChange = (event) => {
        setReason(event.target.value);
        if (event.target.value.trim()) {
            setReasonError("");
        }
    }
    const handleOptionChange = (selected) => {
        setSelectedOption(selected);
        if (selected.length > 0) {
            setSelectedError("");
        }
    };

    const { mutate, isPending } = useMutation({
        mutationFn: unsubscribePlan,
        onSuccess: () => {
            toast.success('Subscription cancelled successfully.');
            trackEvent(MIXPANEL_EVENTS.CANCEL_REQUEST, {
                feedback: reason,
                cancelReason: selectedOption
            });
            dispatch(updateCancelSubscription());
            onNextStep();
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong !!!')
        }
    });
    const handleSubmit = () => {
        let valid = true;
        if (!reason.trim()) {
            setReasonError("Please enter feedback.");
            valid = false;
        }
        if (selectedOption.length === 0) {
            setSelectedError("Please select atleast one option.");
            valid = false;
        }
        if (valid) {
            mutate({
                feedback: reason, cancelReason: selectedOption
            })
        }
    };
    return (
        <div className="bg-white rounded-3xl shadow-drop-shadow w-[680px] p-8 relative">
            <div className="absolute top-0 right-0">
                <LINESVG2 />
            </div>
            <div className="flex flex-col relative z-10">
                <div className="flex w-full justify-end">
                    <button onClick={onClose} className="icon-outline-btn small border-none">
                        <Xmark />
                    </button>
                </div>
                <p className="text-subHeadingThreeBold w-full bg-clip-text text-transparent bg-heading-gradient-3">We’re sorry to see you go...</p>
                <div >
                    <div className="mt-8">
                        <p className="text-bodyTwo mb-2 text-space-default">Select the reason <span className="text-venus-default">*</span></p>
                        <MultiSelectBox options={options} onChange={handleOptionChange} theme={'red'} placeholder='Select your reason(s) for cancellation' />
                        {!!selectedError && (
                            <p className="text-bodyThree text-venus-default">{selectedError}</p>
                        )}
                    </div>
                    <div className="mt-4">
                        <p className="text-bodyTwo mb-2 text-space-default">Share your feedback or comments <span className="text-venus-default">*</span></p>
                        <InputField required={true} value={reason} onChange={handleChange} maxLength={150} placeholder='Write your feedback  here' />
                        {!!reasonError && (
                            <p className="text-bodyThree text-venus-default">{reasonError}</p>
                        )}
                    </div>
                </div>
                <div className="mt-4 flex w-full justify-between items-center">
                    <button className="filled-btn w-[238px] light-red" onClick={handleSubmit} disabled={isPending}>Cancel subscription</button>
                    <button className="filled-btn w-[238px]" onClick={onClose}>Stay subscribed</button>
                </div>
            </div>
        </div>
    )
}

const CancelStepThreeSuccess = ({ onClose }) => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;
    const currentPlanName = subscription?.planName;
    const expireDate = moment(subscription?.endDate).format("DD MMM’YY")

    return (
        <div className="bg-white rounded-3xl shadow-drop-shadow w-[680px] p-8 relative">
            <div className="absolute top-0 left-0">
                <LINESVG3 />
            </div>
            <div className="flex flex-col items-center justify-center relative z-10">
                <div className="flex w-full justify-end">
                    <button onClick={onClose} className="icon-outline-btn small border-none">
                        <Xmark />
                    </button>
                </div>
                <p className="text-center mt-1 mb-16 text-transparent bg-clip-text text-subHeadingThreeBold bg-heading-gradient-3">We’re sad to see you go :( </p>
                <img src={CancelSubscriptionSVG} alt="sucess" srcset="" />
                <p className="text-bodyOne mt-6 text-center text-gray-5">
                    You will continue to enjoy access to
                    <span className="font-bold text-gray-6"> {currentPlanName} </span>
                    premium features till <br />
                    <span className="font-bold text-gray-6"> {expireDate} </span>
                    post which you will be shifted to our free plan
                </p>
                <div className="flex gap-2 text-bodyTwo mt-6 text-gray-4">
                    <p>Feel free to connect with us: </p>
                    <a href={EXTERNAL_LINKS.whatsapp} className="font-bold text-gray-6 underline" rel="noreferrer" target="_blank">WhatsApp</a>
                    &
                    <a href={`mailto:${EXTERNAL_LINKS.mailTo}`} className="font-bold text-gray-6 underline" rel="noreferrer" target="_blank">email</a>
                </div>
            </div>
        </div>
    )
}
export default CancelConfirmDialog;