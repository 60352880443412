import { ArrowLeft, ArrowRight } from "iconoir-react";
import OnBoardingStep4 from "../../asset/image/onboardingStep4.png";
import PhoneNumberInput from "../../component/custom-form/PhoneField";
import ToggleBtn from "../../component/custom-form/ToggleBtn";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import { setUserProperties, trackEvent } from "../../utils/mixpanel";

const NotificationForm = ({ value, onChange, handleNext, handleBack, phoneNumber, notifications, countryCode }) => {
    const maxLength = 15;
    const [errorMessage, setErrorMessage] = useState("");
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const isAuthenticated = authState.isLoggedIn;
    const isLinkedinVerified = authState.isLinkedinVerified;


    // State for notification toggles
    const [toggleStates, setToggleStates] = useState(value || {
        trendingTopics: false,
        latestIdeas: false,
        updates: false,
    });
    const handlePhoneChange = (event) => {
        const { phoneNumber, countryCode } = event;

        clearTimeout(window.phoneValidationTimeout); // Clear any previous timeout

        window.phoneValidationTimeout = setTimeout(() => {
            let isValid = true;

            if (countryCode === "+91" && phoneNumber) {
                const indianPhoneRegex = /^[6789]\d{9}$/; // Must start with 6,7,8,9 and be 10 digits
                if (!indianPhoneRegex.test(phoneNumber)) {
                    setErrorMessage("Invalid phone number");
                    isValid = false;
                }
            } else {
                if (countryCode !== "+91" && phoneNumber.length < 6 && phoneNumber) {
                    setErrorMessage("Invalid phone number");
                    isValid = false;
                }
            }

            if (isValid) {
                setErrorMessage(""); // Clear error if valid
                onChange('phoneNumber', phoneNumber);
                onChange('countryCode', countryCode);
            }
        }, 500); // 500ms delay
    };


    const handleToggle = (key) => {
        setToggleStates((prevState) => {
            const newState = {
                trendingTopics: prevState.trendingTopics ?? false,
                latestIdeas: prevState.latestIdeas ?? false,
                updates: prevState.updates ?? false,
                [key]: !prevState[key], // Toggle only the selected key
            };

            onChange("notifications", newState);

            return newState;
        });
    };

    // Handle next button click
    const handleNextClick = () => {
        // console.log(phoneNumber, notifications, countryCode);
        trackEvent(MIXPANEL_EVENTS.NUMBER_SUMBMITTED, {
            phoneNumber, notifications, countryCode
        })
        setUserProperties({
            "Phone Number": phoneNumber,
            "Country Code": countryCode
        })
        handleNext();
    };

    if (isAuthenticated && isLinkedinVerified) {
        return <Navigate to='/' />
    }
    return (
        <div>
            {/* Background Image */}
            {/* <div className="absolute [zIndex:-1] h-full w-[33%] left-0 top-10">
                <img className="h-full w-full object-contain" src={OnBoardingStep4} alt="onboarding-step-4" />
            </div> */}
            <div className="absolute [zIndex:-1] h-full left-0 top-0 mt-20">
                <img className="h-full w-full object-contain" src={OnBoardingStep4} alt="onboarding-step-1" />
            </div>

            {/* Progress Indicator */}
            <div className="flex items-center justify-center gap-2">
                <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                {/* <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" /> */}
            </div>

            {/* Form Section */}
            <div className="flex mt-16 [min-height:337px]">
                <div className="flex-1"></div>
                <div className="flex-1 flex flex-col">
                    <p className="text-headingTwo bg-hover-gradient-1 bg-clip-text text-transparent">
                        Get notifications for viral content
                    </p>

                    <div className="flex flex-wrap items-center justify-between mt-8">
                        {/* Phone Number Input */}
                        <div className="flex w-full items-center gap-3 mb-5">
                            <p className="text-bodyTwoBold text-star-default min-w-40">Your WhatsApp number</p>
                            <PhoneNumberInput
                                name="phoneNumber"
                                maxLength={maxLength}
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                errorMessage={errorMessage}
                            />
                        </div>

                        {/* Divider */}
                        <div className="rounded-2xl bg-gray-3 mt-5 mb-5 w-full h-[1px]" />

                        {/* Notification Preferences */}
                        <div className="w-full">
                            <p className="mb-4 text-bodyTwoBold text-star-default">Notify me on</p>
                            <div className="flex flex-col gap-y-4">
                                <div className="flex justify-between w-full">
                                    <p className={`text-bodyTwo ${toggleStates.trendingTopics ? "text-bodyThreeBold" : "text-gray-6"}`}>
                                        Trending topics/ideas
                                    </p>
                                    <ToggleBtn isOn={toggleStates.trendingTopics} onChange={() => handleToggle("trendingTopics")} />
                                </div>
                                <div className="flex justify-between w-full">
                                    <p className={`text-bodyTwo ${toggleStates.latestIdeas ? "text-bodyThreeBold" : "text-gray-6"}`}>
                                        Latest ideas
                                    </p>
                                    <ToggleBtn isOn={toggleStates.latestIdeas} onChange={() => handleToggle("latestIdeas")} />
                                </div>
                                <div className="flex justify-between w-full">
                                    <p className={`text-bodyTwo ${toggleStates.updates ? "text-bodyThreeBold" : "text-gray-6"}`}>
                                        Updates
                                    </p>
                                    <ToggleBtn isOn={toggleStates.updates} onChange={() => handleToggle("updates")} />
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* Navigation Buttons */}
                    <div className="flex justify-between items-center mt-20">
                        {/* <button className="text-btn z-0 flex items-center gap-2" onClick={handleNextClick}>
                            Skip for now
                        </button> */}
                        <button onClick={() => {
                            trackEvent(MIXPANEL_EVENTS.NUMBER_SKIPPED);
                            navigate('/')
                        }} className="text-btn">Skip for now</button>
                        <button className="icon-btn" disabled={!phoneNumber || errorMessage} onClick={handleNextClick}>
                            <ArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationForm;
