import { createSlice } from '@reduxjs/toolkit';
import { setUserProperties } from '../../utils/mixpanel';
import { trackEvent } from '@intercom/messenger-js-sdk';
import { MIXPANEL_EVENTS } from '../../utils/mixpanel/eventType';

const initialState = {
  userId: '',
  name: '',
  email: '',
  onboardingStep: 1,
  isEmailVerified: false,
  isLinkedinVerified: false,
  profilePicture: '',
  role: '',
  isLoggedIn: false,
  accessToken: '',
  refreshToken: '',
  occupation: '',
  interest: [],
  interestedPlatform: [],
  createdAt: '',
  subscription: null,
  subscriptionLimit: null,
  locationDetail: null,
  requireCaptcha: false,
  phoneNumber: null,
  countryCode: null,
  notifications: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      const { user, tokens, subscription, subscriptionLimit } = action.payload;
      state.userId = user._id;
      state.createdAt = user.createdAt;
      state.name = user.name;
      state.email = user.email;
      state.isEmailVerified = user.isEmailVerified;
      state.isLinkedinVerified = user.isLinkedinVerified;
      state.profilePicture = user.profilePicture;
      state.role = user.role;
      state.isLoggedIn = true;
      state.accessToken = tokens.access.token;
      state.refreshToken = tokens.refresh.token;
      state.occupation = user.occupation;
      state.interest = user.interest;
      state.locationDetail = user.locationDetail;
      state.phoneNumber = user.phoneNumber
      state.countryCode = user.countryCode
      state.notifications = user.notifications

      // Calculate onboarding step based on presence of occupation, and interest
      let onboardingStep = 1;
      if (user.occupation) onboardingStep++;
      if (user?.interest?.length > 0) onboardingStep++;

      state.onboardingStep = onboardingStep;
      if (subscription) {
        state.subscription = subscription;
      }
      if (subscriptionLimit) {
        state.subscriptionLimit = subscriptionLimit
      }
    },
    logout(state) {
      state.name = '';
      state.email = '';
      state.onboardingStep = 1;
      state.isEmailVerified = false;
      state.isLinkedinVerified = false;
      state.profilePicture = '';
      state.role = '';
      state.isLoggedIn = false;
      state.accessToken = '';
      state.refreshToken = '';
      state.occupation = '';
      state.interest = '';
      state.interestedPlatform = [];
      state.subscription = null;
      state.subscriptionLimit = null;
      state.requireCaptcha=false;
    },
    updateOnboarding(state, action) {
      if (action.payload.occupation !== undefined) {
        state.occupation = action.payload.occupation;
        // Tracking
        setUserProperties({
          persona: action.payload.occupation
        })
      }
      if (action.payload.interest !== undefined) {
        state.interest = action.payload.interest;

        // Tracking
        setUserProperties({
          interests: action.payload.interest
        })
      }
      if (action.payload.interestedPlatform !== undefined) {
        state.interestedPlatform = action.payload.interestedPlatform;
        // Tracking
        trackEvent(MIXPANEL_EVENTS.VIRAL_PLATFORM, {
          'Platform(s) chosen': action.payload.interestedPlatform
        })
        setUserProperties({
          platfromInterest: action.payload.interestedPlatform
        })
      }
      if (action.payload.notifications !== undefined) {
        state.notifications = action.payload.notifications;
      }
      if (action.payload.phoneNumber !== undefined) {
        state.phoneNumber = action.payload.phoneNumber;
      }
      if (action.payload.countryCode !== undefined) {
        state.countryCode = action.payload.countryCode;
      }
      if (action.payload.email !== undefined) {
        state.email = action.payload.email;
      }
    
      // Update onboarding step based on new values
      let onboardingStep = 1;
      if (state.occupation) onboardingStep++;
      if (state?.interest?.length > 0) onboardingStep++;

      state.onboardingStep = onboardingStep;
    },
    updateOnLinkedinConnect(state, action) {
      const { user } = action.payload;
      state.name = user.name;
      state.email = user.email;
      state.isLinkedinVerified = user.isLinkedinVerified;
      state.profilePicture = user.profilePicture;

      // Tracking
      setUserProperties({
        linkedinConnected: true
      })
    },
    updateSubscription(state, action) {
      const { subscription, subscriptionLimit } = action.payload;
      if (subscription) {
        state.subscription = subscription;
      }
      if (subscriptionLimit) {
        state.subscriptionLimit = subscriptionLimit;
      }
    },
    updateCancelSubscription(state) {
      state.subscription.status = 'cancelled'
    },
    updateLinkedinConnect(state) {
      state.isLinkedinVerified = false
    },
    updateSubscriptionStatus(state, action) {
      state.subscription.status = action.payload;
    },
    updateAiCreditUsage(state) {
      state.subscriptionLimit.aiCreditUsage = state.subscriptionLimit.aiCreditUsage + 1;
    },
    updateSearchUsage(state) {
      state.subscriptionLimit.searchUsage = state.subscriptionLimit.searchUsage + 1;
    },
    updateFavouriteCreatorUsage(state, action) {
      if (action.payload.type === 'increment') {
        state.subscriptionLimit.favouriteCreatorUsage = state.subscriptionLimit.favouriteCreatorUsage + 1;
      } else {
        state.subscriptionLimit.favouriteCreatorUsage = state.subscriptionLimit.favouriteCreatorUsage - 1;
      }
    },
    updateCaptchaStatus(state, action) {
      state.requireCaptcha = action.payload
    },
  },
});

export const {
  login,
  logout,
  updateOnboarding,
  updateOnLinkedinConnect,
  updateSubscription,
  updateCancelSubscription,
  updateAiCreditUsage,
  updateSearchUsage,
  updateFavouriteCreatorUsage,
  updateSubscriptionStatus,
  updateCaptchaStatus,
  updateLinkedinConnect
} = authSlice.actions;

export default authSlice.reducer;
