import { NavArrowUp } from "iconoir-react";
import { useEffect, useRef, useState } from "react";
import CheckBox from "./CheckBox";

const MultiSelectBox = ({ options, onChange, theme, placeholder }) => {
    const [selectedOption, setSelectedOption] = useState([]);
    const [openModel, setOpenModel] = useState(false);
    const dropdownRef = useRef(null);
    const handleClick = (text) => {
        if (selectedOption.includes(text)) {
            let temp = [...selectedOption];
            temp = temp.filter((item) => item !== text);
            setSelectedOption(temp);
            onChange(temp);
        } else {
            let temp = [...selectedOption, text];
            setSelectedOption(temp);
            onChange(temp)
        }
    }

    let selectedOptionStyle = '';
    let optionStyle = '';
    if (theme === 'red') {
        selectedOptionStyle = 'bg-venus-light2 text-venus-default'
        optionStyle = 'hover:bg-[#FFF6FA] hover:text-venus-default'
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenModel(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div className="w-full relative" ref={dropdownRef}>
            <div className={`${openModel ? 'rounded-t-lg border-b-0' : 'rounded-lg'} bg-gray-moon border border-gray-3 relative`}>
                <div
                    className={`cursor-pointer inline-flex gap-2 items-center flex-wrap  w-full  py-2.5 px-3.5 `}
                    onClick={() => {
                        setOpenModel((prev) => !prev);
                    }}>
                    {
                        selectedOption.length === 0 && (
                            <p className="text-bodyTwo text-gray-400">{placeholder}</p>
                        )
                    }
                    {
                        selectedOption.map((option) => (
                            <div className={`rounded-[4px] text-bodyTwo  px-[6px] ${selectedOptionStyle}`}>
                                {option}
                            </div>
                        ))
                    }
                    <div className="ml-auto text-gray-4 flex gap-2">
                        <div className="bg-gray-4 h-[20px] w-[1px]" />
                        <NavArrowUp width={20} height={20} />
                    </div>
                </div>
            </div>
            {
                openModel && (
                    <div className="flex flex-col gap-2.5 p-3 border rounded-b-lg border-gray-3 absolute w-full bg-gray-moon z-10 ">
                        {
                            options.map((option) => {
                                return (
                                    <div className={`rounded-lg text-gray-6 group flex gap-3 px-2 py-[6px] cursor-pointer ${optionStyle}`} onClick={() => {
                                        handleClick(option)
                                    }}>
                                        <div>
                                            <CheckBox checked={selectedOption.includes(option)} theme={theme} />
                                        </div>
                                        <p className="text-bodyTwo">{option}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}
export default MultiSelectBox;