import React, { useState, useRef, useEffect } from 'react';
import { incrementUserProperty, setUserProperties, trackEvent } from '../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../utils/mixpanel/eventType';

const parseContentWithHashtags = (text, isToggled, highlightColor, templateFormat) => {

    if (templateFormat) {
        const customSymbolRegex = /\^([^\^]+)\^/g;  // No need to escape ^

        const parts = text?.split(customSymbolRegex);

        return parts?.map((part, index) => {
            // Odd indices will contain content between `^`
            if (index % 2 !== 0) {
                return (
                    <span key={index} className={`${highlightColor ? 'text-bodyThreeBold text-star-light2' : 'text-bodyThree text-star-default'}`}>{part}</span>
                );
            }
            return part; // Return non-highlighted parts as they are
        });

    } else {
        const hashtagRegex = /(#\w+)/g; // Regex to match hashtags
        const parts = text?.split(hashtagRegex);

        return parts?.map((part, index) => {
            if (part.startsWith('#')) {
                return (
                    <span key={index} className="text-star-default">
                        {part}
                    </span>
                );
            }
            return part;
        });



    }

};
const ContentExpand = ({ content, maxLines = 7, defaultValue = false, tracker = false, trackingData = {}, isToggled = false, onClick = () => { }, highlightColor, templateFormat = false, trackPostInteraction=()=>{} }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [overFlow, setOverFlow] = useState(false);
    const [needOverFlow, setNeedOverFlow] = useState(false);
    const contentRef = useRef(null);


    useEffect(() => {
        const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight, 10);
        const maxHeight = lineHeight * maxLines;
        // console.log(maxHeight, contentRef.current.scrollHeight)
        if (contentRef.current.scrollHeight > maxHeight) {
            setNeedOverFlow(true);
            setOverFlow(true);
            setIsExpanded(false);
        }
        if (defaultValue) {
            setIsExpanded(true);
            setOverFlow(false);
        }
    }, [content, maxLines, defaultValue]);

    const handleClickSeeMore = () => {
        onClick(true);
        setIsExpanded(true);
        setOverFlow(false);
        if (tracker) {
            trackPostInteraction("See more");
            incrementUserProperty('Number of times see more clicked')
        }
    };
    const handleClickSeeLess = () => {
        onClick(false);
        setIsExpanded(false);
        setOverFlow(true);
    };

    return (
        <p ref={contentRef} className={`text-bodyThree whitespace-pre-wrap ${isToggled ? "text-star-light2 " : "text-space-dark"
            }`}>
            <span
                style={{
                    wordBreak: 'break-word',
                    ...(!isExpanded && {
                        display: '-webkit-box',
                        WebkitLineClamp: maxLines,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }),
                }}
            >
                {parseContentWithHashtags(content, isToggled, highlightColor, templateFormat)}

            </span>
            {
                needOverFlow && (
                    <>
                        {overFlow ? (
                            <span
                                onClick={handleClickSeeMore}
                                className={` ${isToggled ? 'text-nebula-default cursor-pointer' : 'text-star-light cursor-pointer hover:text-star-default'} `}

                            >
                                See more
                            </span>
                        ) : (
                            <span
                                onClick={handleClickSeeLess}
                                className={` ${isToggled ? 'text-nebula-default cursor-pointer' : 'text-star-light cursor-pointer hover:text-star-default'} `}
                            >
                                See less
                            </span>
                        )}
                    </>
                )
            }
        </p>
    );
};

export default ContentExpand;
