const SegmentedBtn = ({ list, activeIndex, onChange, tabStyle='w-[177px]' }) => {

    return (
        <div className="inline-flex overflow-hidden rounded-xl border border-star-light w-full">
            {
                list.map((item, index) => {
                    return (
                        <div key={index} className={`px-4 py-2 text-center text-bodyThreeBold cursor-pointer  ${activeIndex === index ? 'bg-space-default text-white' : 'bg-gray-moon text-star-default '} ${tabStyle}`} onClick={() => onChange(index)}>
                            {item}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SegmentedBtn;