import { useQuery } from '@tanstack/react-query';
import { DiceThree, FireFlame } from "iconoir-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoaderGif from "../../asset/gif/loader.gif";
import FreeTrialBg from '../../asset/image/freeTrialBg.png';
import Crown from '../../asset/svg/Crown';
import NotFoundImage from "../../asset/svg/notFound.svg";
import FreePlanLimitDialog from '../../component/FreePlanLimitDialog';
import MasonaryLayout from "../../component/MasonaryLayout";
import PostCard from "../../component/PostCard";
import useScrolledDown from "../../hooks/useScrolledDown";
import MainLayout from "../../layout/main";
import { isObjectEmpty, nearestRoundOff } from '../../utils/helper';
import { trackEvent } from '../../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../../utils/mixpanel/eventType';

import LinkedinIcon from '../../asset/svg/LinkedinIcon.js';
import TrendingMountainSVG from '../../asset/svg/trending-mountain.svg';
import TrendUp from '../../asset/svg/Trendup.js';
import BlurCard from "../../component/BlurCard.js";
import { updateSubscription } from '../../redux/slice/auth.slice.js';
import { fetchTrendingGroup, fetchTrendingMedia, fetchTrendingPost } from '../../services/trending.service.js';
import { userObject } from './helper.js';
import MediaCard from '../../component/MediaCard.js';

const ArrowUpBold = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="30" viewBox="0 0 21 30" fill="none">
            <path d="M10.25 23.8542V8.75M10.25 8.75L17.5 16M10.25 8.75L3 16" stroke="#7145FD" strokeWidth="3.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


const trackFilterEvent = (eventName, name, value) => {
    trackEvent(MIXPANEL_EVENTS.FILTER_CLICKED, {
        "Filter type": name,
        "Filter Source": 'Recommendation'
    })
    trackEvent(eventName, {
        [`${name} Filter`]: value,
        "Filter Source": 'Recommendation'
    });
}

function getIndexFromSearchUsage(searchUsage, totalObjects) {
    return searchUsage % totalObjects;
}

const TrendingMedia = () => {
    const authState = useSelector((state) => state.auth);
    const isFreeTrial = authState.subscription.isFreeTrial;

    const dispatch = useDispatch();

    let { scrolledDown: sticky, isScrollingDown } = useScrolledDown(40);
    const [likeFilter, setLikeFilter] = useState({});
    const [contentToneFilter, setContentToneFilter] = useState({});
    const [contentLengthFilter, setContentLengthFilter] = useState({});
    const [sortBy, setSortBy] = useState({
        label: 'Latest first',
        value: 'publishedDate:desc',
    },);
    const [isUnstuck, setIsUnstuck] = useState(false);
    const [page, setPage] = useState(1);

    const [openFilterLimitDialog, setOpenFilterLimitDialog] = useState(false);
    const [openLimitDialog, setOpenLimitDialog] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);


    const query = {
        minContentLength: contentLengthFilter?.value?.min,
        maxContentLength: contentLengthFilter?.value?.max,
        minLikes: likeFilter?.value?.min,
        maxLikes: likeFilter?.value?.max,
        reactionFunny: contentToneFilter?.value === 'funny' ? true : undefined,
        reactionInsightful: contentToneFilter?.value === 'insightful' ? true : undefined,
        reactionShareworthy: contentToneFilter?.value === 'shareworthy' ? true : undefined,
        page,
        sortBy: sortBy.value,
        topicGroupId: selectedGroupId,
    };
    const { data, isFetching: isLoading } = useQuery({
        queryKey: ['trending-media', query],
        queryFn: async ({ queryKey }) => {
            const response = await fetchTrendingMedia(queryKey[1]);
            dispatch(updateSubscription({ subscriptionLimit: response.subscriptionLimit, subscription: response.subscription }));
            
            return response;
        },
        staleTime: Infinity,
        retry: false
    });

    const trendingGroup = useQuery({
        queryKey: ['trending-group', { limit: 10 }],
        queryFn: async ({ queryKey }) => {
            const response = await fetchTrendingGroup(queryKey[1]);
            if(response.data){
                response.data.forEach(element => {
                    if(element.hotTopic){
                        setSelectedGroupId(element._id)
                    }
                });
            }
            return response;
        },
        staleTime: Infinity,
        retry: false
    });


    const resetAllFilter = () => {
        trackEvent(MIXPANEL_EVENTS.FILTERS_REMOVED, {
            'Filter source': 'Trending'
        });
        setLikeFilter({});
        setContentToneFilter({});
        setContentLengthFilter({});
    }

    const activeFilter = Object.keys(likeFilter).length || Object.keys(contentLengthFilter).length || Object.keys(contentToneFilter).length

    const filterCount = !isObjectEmpty(likeFilter) + !isObjectEmpty(contentToneFilter) + !isObjectEmpty(contentLengthFilter);
    sticky = isUnstuck ? false : sticky;

    useEffect(() => {
        setIsUnstuck(false);
    }, [isScrollingDown])
    const handleUnStick = () => {
        setIsUnstuck(true);
    }

    const metaData = {
        postTone: contentToneFilter?.value
    }

    const { subscription } = authState;
    // const totalPost = (data?.totalPost - 8);
    const trackTrendingTopic = (topicName) => {
        trackEvent(MIXPANEL_EVENTS.TRENDING_TOPIC, {
            "Topic Name": topicName
        })
    }
    return (
        <>
            <MainLayout>
                {
                    openFilterLimitDialog && (
                        <FreePlanLimitDialog title={'This premium feature is available in a paid plan'} subTitle={'80% of SuperPen’s paid users claim that this filter is the most useful.'} onClose={() => { setOpenFilterLimitDialog(false) }} />
                    )
                }

                {
                    openLimitDialog && (
                        <FreePlanLimitDialog title={'To update your interests, please upgrade to a paid plan'} onClose={() => { setOpenLimitDialog(false) }} />
                    )
                }

                <div className="rounded-2xl  relative bg-star-light3 " style={{
                    marginTop: subscription.isFreeTrial ? 13 : undefined,
                }}>
                    <div className='rounded-2xl fixed z-20  w-full right-[9px] bg-gray-moon' style={{
                        width: 'calc(100% - 228px)',
                        top: subscription.isFreeTrial ? !sticky ? 65 : 48 : sticky ? 0 : 8
                    }}>
                        {
                            !sticky && <div>

                                <div className='w-full -z-10 absolute top-[0] h-full overflow-hidden right-0'>
                                    <img className='w-full' src={TrendingMountainSVG} alt='trend-up' />
                                </div>
                                {/* <div className='flex justify-end  pt-4 pr-4'>
                                    <button className='flex items-center px-2 gap-2 py-1 rounded-lg bg-star-light3'>
                                        <Bell color='#7145FD' width={15} />
                                        <p className='text-bodyThree text-gray-5'>Notify me</p>
                                    </button>
                                </div> */}
                                <div className='ml-[55px] mt-[79px]'>
                                    
                                    {/* <p className='text-subHeadingTwoBold text-space-default'>Explore what is </p> */}
                                    <div className='flex gap-0.5'>
                                        <ArrowUpBold />
                                        <p className='text-subHeadingTwoBold bg-clip-text bg-base-gradient text-transparent'>Trending Images</p>
                                    </div>
                                    <div className='flex gap-0.5 items-center'>
                                        <p className='text-subHeadingTwoBold text-space-default'>right now on Linked
                                        </p>
                                        <LinkedinIcon />
                                    </div>
                                </div>

                            </div>
                        }
                        <div className='flex  items-center justify-center bg-star-light3 gap-2' style={{
                            padding: sticky ? "16px 20px" : undefined,
                            marginTop: sticky ? 0 : 70
                        }}>
                            <button className={`${selectedGroupId === null ? 'active' : ''} trending-btn text-button`} onClick={() => { setSelectedGroupId(null);trackTrendingTopic("All"); }}>
                                <TrendUp />
                                All
                            </button>
                            {
                                trendingGroup?.data?.data.map((item, index) => (
                                    <button className={`${item._id === selectedGroupId ? 'active' : ''} trending-btn`} key={index} onClick={() => { setSelectedGroupId(item._id);trackTrendingTopic(item.groupName); }}>
                                        {item.hotTopic ? <FireFlame /> : <TrendUp />}
                                        <p className='text-button'> {item.groupName}</p>
                                    </button>
                                ))
                            }
                        </div>


                    </div>

                    <div className={`p-4 flex flex-col ${!sticky ? 'pt-[280px]' : 'pt-[200px]'} min-h-[110vh]`}>
                        {
                            isLoading && (
                                <div className="flex flex-col h-[70vh] justify-center items-center">
                                    <img src={LoaderGif} width={200} alt="loading" />
                                    <p className="text-bodyThreeBold text-star-light">Hang tight, Trending is on the way!</p>
                                </div>
                            )
                        }
                        {
                            data?.data?.length === 0 && (
                                <div className="flex flex-col h-[52vh] justify-center items-center">
                                    <img src={NotFoundImage} alt="notFound" />
                                    <p className="text-bodyThreeBold text-star-light text-center"> Posts not found for your query! <br />
                                        Try using some alternate keywords</p>
                                </div>
                            )
                        }
                        {
                            !isLoading && (
                                <MasonaryLayout columnCount={4}>
                                    {
                                        data?.data?.map((postItem) => (
                                            postItem?.isFreeTrial ?
                                                <div key={postItem.postId} className="flex-1">
                                                    <BlurCard {...postItem} postType={contentToneFilter?.value} metaData={metaData} />
                                                </div>
                                                :
                                                <div key={postItem.postId} className="flex-1">
                                                    <MediaCard hideBookMark={true} hideFavCreator={true} {...postItem} postType={contentToneFilter?.value} metaData={metaData} />
                                                </div>
                                        ))
                                    }
                                </MasonaryLayout>
                            )
                        }
                        {
                             !isLoading && data?.pagination?.remainingPage > 0 && (
                                <div className='flex items-center justify-center py-4 mt-auto '>
                                    <button className='outlined-btn small border-none' onClick={() => setPage((prev) => prev + 1)}>
                                        <DiceThree />
                                        Renew Trending feed
                                    </button>
                                </div>
                            )
                        }

                    </div>
                    {/* {
                        isFreeTrial && nearestRoundOff(totalPost) > 0 && !isLoading && (
                            <div className='p-4'>
                                <div className='rounded-xl relative w-full pt-[47px] pb-[89px] '>
                                    <img src={FreeTrialBg} alt='background-img' className='w-full top-0 h-full absolute z-0' />
                                    <div className='flex flex-col items-center justify-center relative z-10'>
                                        <p className='text-headingOne text-white text-center'>{nearestRoundOff(totalPost)}+ posts found</p>
                                        <p className='text-subHeadingThree mt-3 mb-5 text-white text-center'>Need more Trending?
                                        </p>
                                        <Link to={'/plan'} className='filled-btn white small'><Crown /> <p >Upgrade Plan Now</p> </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    } */}
                </div>
            </MainLayout>

        </>
    )
}

export default TrendingMedia;