import { ArrowLeft, Sparks, Xmark } from "iconoir-react";
import DialogBox from "../DialogBox";
import { useState } from "react";

const PromptDialog = ({ onClose, handleGenerateNewPost, content }) => {
    const [rawThought, setRawThought] = useState('');
    return (
        <DialogBox>
            <div className="px-8 py-9 bg-white rounded-3xl w-[529px]">
                <div className="flex items-center justify-between">
                    <p className="text-subHeadingTwoBold text-transparent bg-clip-text bg-hover-gradient-1">Write a prompt</p>
                    <button className="icon-outline-btn small border-none" onClick={onClose}>
                        <Xmark />
                    </button>
                </div>
                <div className="mt-3">
                    <div className="relative">
                        <textarea
                            value={rawThought}
                            onChange={(event) => { setRawThought(event.target.value) }}
                            rows={4}
                            maxLength={100}
                            className="rounded-lg outline-none resize-none w-full px-2 py-3 text-bodyTwo border border-gray-3 bg-white placeholder:text-gray-4"
                            placeholder="Make this post..."
                        />
                        <div className="absolute bottom-2 right-3">
                            <p className="text-bodyThree text-gray-3">{rawThought.length}/100</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between mt-[60px]">
                    <button className="text-btn" onClick={() => {
                        onClose();
                    }}><ArrowLeft />Back</button>
                    <button disabled={!rawThought} className="filled-btn v2 small w-[260px]" onClick={() => {
                        handleGenerateNewPost({ content, tone: rawThought });
                        onClose();
                    }}>
                        <Sparks />
                        Generate with AI
                    </button>
                </div>

            </div>
        </DialogBox>
    )
}
export default PromptDialog;