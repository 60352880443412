import { Check, Percentage } from "iconoir-react";


const CouponSection = ({ isFreeTrial, validCoupon, offerDetail, setOpenCouponDialog, handleRemoveCoupon,countryCode }) => {
    if (!isFreeTrial || countryCode) return null;

    return validCoupon ? (
        <div className="flex flex-col justify-end items-end">
            <div className="flex justify-center cursor-pointer items-center text-bodyOne text-star-light group hover:text-star-default transition-all">
                <div className="flex items-center justify-center text-white bg-star-light group-hover:bg-star-default w-[22px] h-[22px] rounded-full p-0.5 mr-1">
                    <Check />
                </div>
                <div className="text-bodyOne">
                    <b>{offerDetail.name}</b> Code applied successfully
                </div>
            </div>
            <button className="text-btn small" onClick={handleRemoveCoupon}>Remove coupon code</button>
        </div>
    ) : (
        <div className="flex justify-center cursor-pointer items-center text-bodyOne text-star-light group hover:text-star-default transition-all" onClick={() => setOpenCouponDialog(true)}>
            <div className="flex items-center justify-center text-white bg-star-light group-hover:bg-star-default w-[22px] h-[22px] rounded-full p-0.5 mr-1">
                <Percentage />
            </div>
            Do you have a
            <div className="text-bodyOneBold ml-1 underline">Coupon Code?</div>
        </div>
    );
};
export default CouponSection;