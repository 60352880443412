import { Xmark } from "iconoir-react";
import DialogBox from "../../../component/DialogBox";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { isPending } from "@reduxjs/toolkit";

const HEROSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="142" height="79" viewBox="0 0 142 79" fill="none">
            <path d="M16.7615 13.1737C17.4533 12.3947 18.7416 12.8483 18.7927 13.8889L19.0542 19.214C19.0696 19.5262 19.2099 19.8191 19.4436 20.0266L23.4301 23.5668C24.2091 24.2586 23.7554 25.5469 22.7149 25.598L17.3898 25.8595C17.0776 25.8749 16.7847 26.0152 16.5772 26.2489L13.037 30.2354C12.3452 31.0144 11.0569 30.5608 11.0058 29.5202L10.7442 24.1951C10.7289 23.8829 10.5886 23.59 10.3549 23.3825L6.3684 19.8423C5.58939 19.1505 6.04302 17.8622 7.08361 17.8111L12.4087 17.5496C12.7209 17.5342 13.0137 17.3939 13.2213 17.1602L16.7615 13.1737Z" fill="url(#paint0_linear_1230_2528)" />
            <path d="M119.158 52.416C120.227 51.3439 122.042 52.2829 121.784 53.775L120.686 60.1287C120.6 60.6261 120.762 61.1342 121.119 61.4906L125.684 66.044C126.757 67.1134 125.818 68.9278 124.325 68.67L117.972 67.5725C117.474 67.4866 116.966 67.6481 116.61 68.0054L112.056 72.5706C110.987 73.6427 109.173 72.7037 109.43 71.2115L110.528 64.8578C110.614 64.3604 110.452 63.8524 110.095 63.4959L105.53 58.9425C104.458 57.8731 105.397 56.0588 106.889 56.3165L113.243 57.4141C113.74 57.5 114.248 57.3385 114.604 56.9811L119.158 52.416Z" fill="#7145FD" />
            <path d="M93.5894 29.9735C92.987 26.9002 88.682 23.0569 84.4461 23.3149L71.476 25.8573C71.8508 18.6324 69.8434 11.3541 68.3274 7.85515C65.7743 2.81205 59.8544 6.3996 59.4912 9.15506C58.1568 15.4269 60.4798 25.115 53.6586 30.9552L44.7921 32.6932C44.3719 32.7756 44.098 33.183 44.1804 33.6032L50.1149 63.8781C50.1972 64.2983 50.6054 64.5715 51.0267 64.4945C63.3628 62.2398 75.1545 63.9587 87.4799 59.6448C100.335 55.1453 96.2075 39.5091 93.5894 29.9735Z" stroke="url(#paint1_linear_1230_2528)" strokeWidth="3.87673" strokeMiterlimit="10" />
            <path d="M24.7845 71.5838C25.1372 73.3831 26.7449 74.5827 28.3753 74.2631L40.3742 71.9111C42.0046 71.5915 43.0404 69.8737 42.6877 68.0744L35.7835 32.8521C35.4307 31.0528 33.8231 29.8531 32.1927 30.1727L20.1933 32.5248C18.5629 32.8444 17.5271 34.5622 17.8799 36.3616L24.7841 71.5838L24.7845 71.5838Z" fill="#5F38DB" />
            <path d="M37.6623 65.4248C37.9299 66.7901 37.0399 68.1135 35.6746 68.3812C34.3093 68.6488 32.9856 67.7593 32.718 66.394C32.4503 65.0283 33.3402 63.7049 34.7055 63.4373C36.0708 63.1697 37.3946 64.0595 37.6623 65.4248Z" fill="#ECEFF0" />
            <defs>
                <linearGradient id="paint0_linear_1230_2528" x1="4.38802" y1="32.0763" x2="22.8978" y2="34.9727" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.709584" stopColor="#FDA2F5" />
                </linearGradient>
                <linearGradient id="paint1_linear_1230_2528" x1="62.1695" y1="70.2939" x2="100.937" y2="56.1456" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.709584" stopColor="#FDA2F5" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const LINESVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="680" height="179" viewBox="0 0 680 179" fill="none">
            <path d="M335.825 -455.781L676.272 -12.573C693.022 9.2333 721.42 18.5539 747.834 10.9153V10.9153C796.127 -3.04985 802.25 -68.9878 757.355 -91.6065L491.73 -225.43C490.021 -226.291 488.186 -226.878 486.294 -227.168L40.4242 -295.486C25.7958 -297.727 15.081 -282.037 22.4921 -269.228L50.4204 -220.955C127.165 -88.305 90.5419 80.9441 -34.1763 170V170" stroke="url(#paint0_linear_1219_6879)" stroke-width="19.7993" />
            <defs>
                <linearGradient id="paint0_linear_1219_6879" x1="387.113" y1="-337.748" x2="-338.846" y2="475.475" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.33" stopColor="#FDA1F5" />
                    <stop offset="0.635" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const UpgradeConfirmDialog = ({ planDetail, onSuccess, onClose, isPending }) => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;

    const planText = `${planDetail?.name} ${planDetail?.billingPeriod === 'yearly' ? 'yearly plan' : 'monthly plan'}`;
    const amountText = `${planDetail.currency === 'INR' ? '₹' : '$'}${planDetail.amount}`;

    // Calculate the amount to refund
    const moment = require("moment");

    const today = moment();
    let refundAmount = 0;

    let daysUsed = '';
    if (subscription) {

        let totalDays = moment(subscription.endDate).diff(moment(subscription.startDate), 'days');
        let daysUntilNextRenewal = moment(subscription.endDate).diff(today, 'days');

        console.log({ daysUntilNextRenewal, totalDays, amount: subscription.amountAfterDiscount })
        if (totalDays > 0 && daysUntilNextRenewal > 0) {
            refundAmount = (daysUntilNextRenewal / totalDays) * subscription.amountAfterDiscount;
            daysUsed = totalDays - daysUntilNextRenewal;
        }

        refundAmount = `${subscription.currency === 'INR' ? '₹' : '$'} ${Math.floor(refundAmount).toLocaleString()}`;
    }

    return (
        <DialogBox>
            <div className="bg-white rounded-3xl shadow-drop-shadow w-[680px] p-8 relative">
                <div className="absolute top-0 left-0 ">
                    <LINESVG />
                </div>
                <div className="flex flex-col items-center justify-center relative z-10">
                    <div className="flex w-full justify-end">
                        <button onClick={onClose} className="icon-outline-btn small border-none">
                            <Xmark />
                        </button>
                    </div>
                    <HEROSVG />
                    <p className="text-transparent bg-clip-text text-subHeadingTwoBold text-center bg-heading-gradient-3 mt-3">
                        Smart choice!
                    </p>
                    <p className="text-bodyOne text-center text-gray-5 mt-7">
                        Upgrading to the <span className="text-subHeadingThreeBold text-star-default">{planText} </span>
                        has helped users be more consistent and find inspiration faster on SuperPen.
                    </p>
                    <p className="text-bodyOne mt-5 text-gray-5 text-center">
                        On clicking upgrade, you will be asked to create a new {planDetail?.billingPeriod} subscription of the amount
                        <span className="text-subHeadingThreeBold text-star-default"> {amountText}</span>.
                    </p>
                    {subscription && (
                        <div className="rounded-lg bg-star-light4 p-2 mt-5 w-full text-center">
                            <p className="text-bodyOne text-star-light">
                                Once done, we will immediately cancel your current plan and
                                <br />
                                <b>refund {refundAmount} to you (after deducting {daysUsed} days of usage)</b>
                            </p>
                        </div>
                    )}

                    <button
                        className="filled-btn mt-8 w-[238px] loader"
                        onClick={() => onSuccess(planDetail._id)}
                        disabled={isPending}
                    >
                        Upgrade Plan
                    </button>
                </div>
            </div>
        </DialogBox>
    );
};
export default UpgradeConfirmDialog;