import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { fetchViralPostCount } from "../../services/post.service";

export default function AnimatedTicker({
    label = "viral posts added | last 24 hrs",
    updateInterval = 120000, // 2 minutes
}) {
    const [count, setCount] = useState(9745);

    const { data, refetch } = useQuery({
        queryKey: ["viral-post-count"],
        queryFn: async () => {
            const response = await fetchViralPostCount();
            setCount(response?.value)
            return response?.value;
        },
        refetchInterval: updateInterval, // Automatically refetch every 2 minutes
        staleTime: Infinity,
        retry: false,
        initialData: undefined
    });

    useEffect(() => {
        refetch()
    }, [])

    const digits = count.toLocaleString().split("");
    const direction = data > count ? "up" : "down";

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                {digits.map((digit, index) => (
                    <div
                        key={index}
                        style={{
                            position: "relative",
                            height: "38px",
                            width: digit === "," ? "5px" : "25px",
                            overflow: "hidden",
                            borderRadius: "4px",
                            background: digit === "," ? "transparent" : "#F5F3FF",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "700",
                        }}
                    >
                        {digit === "," ? (
                            <span
                                style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#6C5CE7",
                                }}
                            >
                                {digit}
                            </span>
                        ) : (
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key={`${index}-${digit}`}
                                    initial={{ y: direction === "up" ? 48 : -48 }}
                                    animate={{ y: 0 }}
                                    exit={{ y: direction === "up" ? -48 : 48 }}
                                    transition={{ duration: 0.3 }}
                                    style={{
                                        position: "absolute",
                                        inset: 0,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#6C5CE7",
                                    }}
                                >
                                    {digit}
                                </motion.div>
                            </AnimatePresence>
                        )}
                    </div>
                ))}
            </div>
            {label && <p className="text-gray-5 text-bodyThree">{label}</p>}
        </div>
    );
}
