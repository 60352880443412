import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateOnLinkedinConnect } from "../../redux/slice/auth.slice";
import { verifyLinkedin } from "../../services/onboardin.service";
import LoaderGif from "../../asset/gif/loader.gif";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const VerifyLinkedin = () => {
    const authState = useSelector((state) => state.auth);
    const isAuthenticated = authState.isLoggedIn;
    const isLinkedinVerified = authState.isLinkedinVerified;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMounted = useRef(false);

    useEffect(() => {
        const handleLinkedInCallback = async () => {
            const windowUrl = window.location.href;

            if (windowUrl.includes('error=')) {
                const codeMatch = windowUrl.match(/error=([^&]+)/);
                if (codeMatch && codeMatch[1]) {
                    toast.error(codeMatch[1] || 'Something went wrong');
                }
                trackEvent(MIXPANEL_EVENTS.LINKEDIN_CONNECT_FAILURE, {
                    "Reason (LinkedIn sync failed)": codeMatch[1] || 'Server error'
                });
                // navigate('/onboarding');
                navigate('/accounts');
            } else if (windowUrl.includes('code=')) {
                const codeMatch = windowUrl.match(/code=([a-zA-Z0-9_\-]+)/);
                if (codeMatch && codeMatch[1] && isAuthenticated) {
                    try {
                        const user = await verifyLinkedin(codeMatch[1]);
                        trackEvent(MIXPANEL_EVENTS.LINKEDIN_CONNECT_SUCCESS);
                        dispatch(updateOnLinkedinConnect(user));
                        // navigate('/');
                        navigate('/accounts');
                    } catch (error) {
                        toast.error(error?.message || 'Something went wrong');
                        trackEvent(MIXPANEL_EVENTS.LINKEDIN_CONNECT_FAILURE, {
                            "Reason (LinkedIn sync failed)": error?.message || 'Server error'
                        });

                        // navigate('/onboarding');
                        navigate('/accounts');

                    } finally {
                        isMounted.current = true; // To prevent Re-render
                    }
                }
            }
        };

        if (!isLinkedinVerified && !isMounted.current) {
            handleLinkedInCallback();
        }
    }, [dispatch, navigate, isAuthenticated, isLinkedinVerified,]);

    if (isAuthenticated && isLinkedinVerified) {
        return <Navigate to='/accounts' />
    }

    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <img src={LoaderGif}  width={200} alt='loading' />
            <p className='text-gray-5'>Connecting to LinkedIn</p>
        </div>
    )

}

export default VerifyLinkedin;