import { useGSAP } from '@gsap/react';
import { useQueryClient } from '@tanstack/react-query';
import gsap from 'gsap';
import { BookmarkBook, CreditCard, FireFlame, LogOut, MediaImageList, Puzzle, ReportColumns, Settings, Star, Xmark } from "iconoir-react";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import AvatarIcon from "../../asset/svg/AvatarIcon";
import LogoIcon from "../../asset/svg/LogoIcon";
import ViralTemplateSVG from '../../asset/svg/ViralTemplateSVG';
import { logout } from "../../redux/slice/auth.slice";
import { resetMixpanel, trackEvent, trackPageView } from '../../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../../utils/mixpanel/eventType';
import TrendUp from '../../asset/svg/Trendup';
import PostGroupIcon from '../../asset/svg/PostGroupIcon';
import CreditWidget from '../../component/CreditWidget';
import PostGeneratorIcon from '../../asset/svg/PostGeneratorIcon';

// gsap.registerPlugin(useGSAP);


// const BackgroundSVG = () => {
//     return (
//         <svg className='tab-btn' width="99" height="129" viewBox="0 0 99 129" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M-212 128.291C-214.922 129.12 -217.963 127.424 -218.792 124.502C-219.622 121.579 -217.925 118.538 -215.003 117.709L-212 128.291ZM-110.594 -64.2487L38.8237 -64.228L38.8222 -53.228L-110.595 -53.2487L-110.594 -64.2487ZM55.0578 52.5122L-212 128.291L-215.003 117.709L52.055 41.9299L55.0578 52.5122ZM38.8237 -64.228C107.879 -64.2184 121.49 33.6616 55.0578 52.5122L52.055 41.9299C106.206 26.5644 95.1106 -53.2202 38.8222 -53.228L38.8237 -64.228Z" fill="url(#paint0_linear_1_10901)" />
//             <defs>
//                 <linearGradient id="paint0_linear_1_10901" x1="-488.079" y1="262.98" x2="264.379" y2="-209.693" gradientUnits="userSpaceOnUse">
//                     <stop offset="0.288815" stopColor="#FBDEBC" />
//                     <stop offset="0.427773" stopColor="#FFC0FA" />
//                     <stop offset="0.567652" stopColor="#C5BBFF" />
//                     <stop offset="0.75671" stopColor="#FD6483" />
//                 </linearGradient>
//             </defs>
//         </svg>
//     )

// }



// const LogoutDialog = ({ onClose }) => {
//     const dispatch = useDispatch();
//     const queryClient = useQueryClient();
//     const container = useRef();
//     const tl = useRef();

//     const handleLogout = () => {
//         trackEvent(MIXPANEL_EVENTS.LOGOUT_CLICKED);
//         resetMixpanel();
//         dispatch(logout());
//         queryClient.removeQueries({});
//     }

//     useGSAP(() => {
//         tl.current = gsap
//             .timeline()
//             .to('#animation1', {
//                 backdropFilter: 'blur(20px)',
//                 duration: 0.25,
//                 ease: 'power2.inOut',
//             })
//             .to("#animation2", {
//                 opacity: 1,
//                 duration: 0.25,
//             })
//             .to("#animation3", {
//                 opacity: 1,
//                 top: 31,
//                 duration: 0.25,
//             });
//     }, { scope: container })
//     return (
//         <div ref={container} className="w-full h-full flex flex-col items-center justify-center fixed top-0 left-0 z-[99]">

//             <div id="animation1" className="absolute  top-0 left-0 w-full h-full" style={{
//                 background: "rgba(106, 101, 167, 0.52)"
//             }} />

//             <div id="animation2" className="opacity-0 relative z-0 rounded-[25px] flex flex-col items-center justify-center bg-gray-moon p-8 px-10" style={{
//                 boxShadow: "0px 14px 32px 0px rgba(39, 39, 47, 0.06)"
//             }}>

//                 <div className="flex flex-col items-center justify-center">
//                     <div className="absolute -z-[1] top-0 left-0 h-full w-full">
//                         <BackgroundSVG />
//                     </div>
//                     <p className="mt-8 text-headingThree text-transparent bg-clip-text bg-heading-gradient-3">Are you sure you want to log out?</p>
//                     <p className="text-gray-6 text-subHeadingThree">Don’t forget—your next great post is just a login away!</p>
//                     <button className="filled-btn red mt-[68px]" onClick={handleLogout}>
//                         <LogOut />
//                         Log out

//                     </button>
//                 </div>

//             </div>
//             <div className="relative flex items-center justify-center">
//                 <div id="animation3" onClick={onClose} className="absolute opacity-0 -top-1 z-0 cursor-pointer  bg-star-light3 rounded-full w-[48px] h-[48px] flex items-center justify-center ">
//                     <Xmark width={24} height={24} color="#7145FD" />
//                 </div>
//             </div>
//         </div>
//     )
// }

const SubTabComponent = ({ tab, handleTrackVisit, pathname }) => {
    const isActiveTab = pathname.startsWith(tab.path);

    const [openTab, setOpenTab] = useState(true);

    return (
        <div className={`rounded-lg  ${openTab || isActiveTab ? 'bg-star-light3' : ''}`}>
            <div className='flex items-center px-3 py-2 gap-2.5 cursor-pointer' onClick={() => { setOpenTab(!openTab) }}>
                <div className='text-star-light'>
                    {tab.icon}
                </div>
                <span className={`text-bodyTwo text-star-light ${openTab || isActiveTab ? 'font-bold' : ''} `}>
                    {tab.name}
                </span>
            </div>
            {
                openTab && (
                    <div className='p-2 flex flex-col gap-2'>
                        {
                            tab.subTabs.map((subTab) => {
                                const subTabActive = pathname === subTab.path;
                                return (
                                    <Link
                                        to={subTab.path}
                                        onClick={() => { handleTrackVisit(subTab?.eventName) }}
                                        key={subTab.name}
                                        className={`text-nowrap bg-gray-moon tab-btn svg ${subTabActive ? 'active' : ''}`}
                                    >
                                        <div>
                                            {subTab.icon}
                                        </div>
                                        <span>
                                            {subTab.name}
                                        </span>
                                    </Link>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}
const Sidebar = () => {
    const authState = useSelector((state) => state.auth);
    const { subscription } = authState;

    const isIndia = authState?.locationDetail?.countryCode === 'IN';
    const [openDialog, setOpenDialog] = useState(false);

    let { pathname } = useLocation();

    const tabs = [
        
        {
            name: 'Viral Content ',
            path: '/',
            icon: <ReportColumns />,
            eventName: MIXPANEL_EVENTS.VIRAL_CONTENT_TAB
        },
        {
            name: 'Post Generator',
            path: '/post-generator',
            icon: <PostGeneratorIcon />,
            eventName: MIXPANEL_EVENTS.POST_GENERATOR_TAB
        },
        {
            name: 'Viral Templates',
            path: '/templates',
            icon: <ViralTemplateSVG />,
            eventName: MIXPANEL_EVENTS.TEMPLATE_TAB
        },
        {
            name: 'Top Keywords',
            path: '/top-keywords',
            icon: <FireFlame />,
            eventName: MIXPANEL_EVENTS.TOP_KEYWORD_TAB
        },
        {
            name: 'Trending Now',
            path: '/trending',
            icon: <TrendUp />,
            eventName: MIXPANEL_EVENTS.TRENDING_TAB,
            hide: !isIndia
        },
        // {
        //     name: 'Trending Now',
        //     icon: <TrendUp />,
        //     eventName: MIXPANEL_EVENTS.TRENDING_TAB,
        //     subTabsExists: true,
        //     path: '/trending',
        //     subTabs: [
        //         {
        //             name: 'Posts',
        //             path: '/trending',
        //             icon: <PostGroupIcon />,
        //             eventName: MIXPANEL_EVENTS.TRENDING_POST_TAB,
        //         },
        //         {
        //             name: 'Images',
        //             path: '/trending/media',
        //             icon: <MediaImageList />,
        //             eventName: MIXPANEL_EVENTS.TRENDING_IMAGES_TAB,
        //         },
        //     ]
        // },
        
        
        {
            name: 'Favourite Creators ',
            path: '/fav-creator',
            icon: <Star />,
            eventName: MIXPANEL_EVENTS.CREATOR_TAB
        },

        {
            name: 'Bookmarks ',
            path: '/bookmarks',
            icon: <BookmarkBook />,
            eventName: MIXPANEL_EVENTS.BOOKMARKS_VISIT
        },
        {
            name: 'Plan & Billing',
            path: '/plan',
            icon: <CreditCard />,
        },

    ]

    const handleTrackVisit = (eventName) => {
        if (eventName) {
            trackPageView(eventName)
        }
    }

    return (
        <>
            {/* {
                openDialog && (
                    <LogoutDialog onClose={() => { setOpenDialog(false); }} />
                )
            } */}
            <div className="w-[195px] " >
                <div className="fixed  z-[9] top-[8px] w-[195px]  flex flex-col px-2 py-4 h-full  bg-gray-moon rounded-2xl" style={{
                    top: subscription.isFreeTrial ? 65 : undefined,
                    height: subscription.isFreeTrial ? '92%' : undefined
                }}>
                    <div >
                        <LogoIcon />
                    </div>
                    <div className="flex flex-col mt-11 gap-2">
                        {
                            tabs.map((tab) => {
                                if(tab.hide){
                                    return (<></>)
                                }
                                return (
                                    tab.subTabsExists ?
                                        <>
                                            <SubTabComponent key={tab.name} tab={tab} handleTrackVisit={handleTrackVisit} pathname={pathname} />
                                        </> : <>
                                            <Link to={tab.path} onClick={() => { handleTrackVisit(tab?.eventName) }} key={tab.name} className={`text-nowrap tab-btn svg ${(tab.path === '/' ? pathname === '/' : pathname.startsWith(tab.path)) ? 'active' : ''
                                                }`}>
                                                <div>
                                                    {tab.icon}
                                                </div>
                                                <span>
                                                    {tab.name}
                                                </span>
                                            </Link>
                                        </>
                                )
                            })
                        }

                        {/* <a href="https://superpen.canny.io/feature-requests" className="text-nowrap tab-btn" rel="noreferrer" target="_blank"> <Puzzle /> Feature Request
                        </a> */}

                    </div>
                    <div className="mt-auto flex flex-col gap-2  ">
                        {/* <Link to={'/plan'} className={`tab-btn ${pathname === '/plan' ? 'active' : ''}`}>
                            <CreditCard />
                            <span>Plan & Billing</span>
                        </Link> */}
                        <CreditWidget />
                        {/* <div className="flex cursor-pointer items-center gap-2.5 rounded-xl bg-star-light3 p-2 text-star-default hover:text-venus-default" onClick={() => { setOpenDialog(true); }}>
                            <AvatarIcon src={authState.profilePicture} />
                            <span className="text-bodyThree text-star-default ">{authState.name?.split(' ')[0]}</span>
                            <LogOut width={20} height={20} className=' ml-auto' />
                        </div>  */}

                        <Link to={'/accounts'} className={`tab-btn ${pathname === '/accounts' ? 'active text-star-light4' : 'text-star-default'} bg-star-light3`}>
                            <AvatarIcon src={authState.profilePicture} width='25' />
                            <span className={`text-bodyThree w-full hover:text-star-light4`}>{authState.name?.split(' ')[0]}</span>
                            <Settings/>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar;