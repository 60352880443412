import { Check, InfoCircle } from "iconoir-react";

const planFeatureList = [
    {
        title: "Viral content library",
        titleInfo: "Library of over 8 million viral LinkedIn/X posts",
        firstValue: "Full access",
        firstInfo: "Access to all posts",
        secondValue: "Full access",
        secondInfo: "Access to all posts",
        thirdValue: "Full access",
        thirdInfo: "Access to all posts",
    },
    {
        title: <>Search viral posts by keyword</>,
        titleInfo: "Want to make a post about a particular topic? Easily search and filter for relevant posts in our library. Search and filters are both counted towards search credits",
        firstValue: "100",
        secondValue: "250",
        thirdValue: "Unlimited"
    },
    {
        title: <span className="flex flex-col items-start">
            <span>Filters</span>
            <span className="text-gray-5 text-subText">(tone, length, engagement)</span>
        </span>,
        titleInfo: "Filter out funny/insightful posts or get the short/long posts as per your preference",
        firstValue: "Full access",
        firstInfo: "Access to all filters",
        secondValue: "Full access",
        secondInfo: "Access to all filters",
        thirdValue: "Full access",
        thirdInfo: "Access to all filters",
    },
    {
        title: "AI credits (GPT 4o)",
        titleInfo: "Let our trained AI model do the writing for you once you've selected a post inspiration",
        firstValue: "50",
        secondValue: "200",
        thirdValue: "500"
    },
    {
        title: "Bookmarks",
        titleInfo: "Easily save posts that you can visit later",
        firstValue: <Check color="#7145FD" />,
        secondValue: <Check color="#7145FD" />,
        thirdValue: <Check color="#7145FD" />
    },
    {
        title: "Trending posts",
        titleInfo: "Get viral posts on trending topics in real time and ride the hype wave",
        firstValue: "Full access",
        firstInfo: "Full access to all posts across all trending topics with filters",
        secondValue: "Full access ",
        secondInfo: "Full access to all posts across all trending topics with filters",
        thirdValue: "Full access",
        thirdInfo: "Full access to all posts across all trending topics with filters",
    },
    {
        title: "Viral Templates",
        titleInfo: "Tried and tested versatile templates to go viral",
        firstValue: "50",
        secondValue: "All",
        thirdValue: "All",
    },
    {
        title: <>Add profiles of creators you love </>,
        titleInfo: "Add LinkedIn/X links of creators you follow and get all their viral posts from the last 5 years in seconds",
        firstValue: 2,
        secondValue: 10,
        thirdValue: 30
    },
];

const Tooltip = ({ info, position }) => {
    let style = 'bottom-5 left-2 rounded-bl-none';
    // let style = '';
    if (position === 'bottom') {
        style = 'top-5 rounded-tl-none'
    } else if (position === 'left') {
        style = 'right-0 bottom-5 rounded-br-none'
    }
    return (
        <div className={`tooltip-wrapper -translate-y-1/2 -right-5 top-1/2 `} style={{ position: 'absolute' }}>
            <div className="relative ">
                <InfoCircle width={18} height={18} color='#D6D0FF' />
            </div>
            <div className={`tooltip absolute hidden rounded-lg md:p-[15px] p-3 bg-white text-bodyThree text-gray-5 z-10 max-w-[380px] w-max ${style}`} style={{
                // borderRadius: "8px 8px 8px 0",
                boxShadow: "0px 14px 32px 0px rgba(113, 69, 253, 0.10)"
            }}>
                {info}
            </div>
        </div>
    )
};

const FeatureCell = ({ value, info, position, size }) => (
    <div className="relative ">
        <p className="text-bodyOne flex items-center justify-center text-center text-space-default px-2 relative" style={{
            fontSize: size
        }}>{value}</p>
        {info && <Tooltip info={info} position={position} />}
    </div>
);

const FeatureList = () => {

    return (
        <>
            <div className="" >
                {planFeatureList.map((planDetail, index) => {
                    const isFirstItem = index === 0;
                    const isLastItem = (index + 1) === planFeatureList.length;

                    return (
                        <div className="grid grid-cols-4 w-full bg-white" key={index} style={{
                            borderTopLeftRadius: isFirstItem ? "16px" : undefined,
                        }}>
                            <div
                                className="h-[60px] flex items-center ml-3 text-left"
                                style={{
                                    borderBottom: isLastItem ? undefined : "1px solid #F5F2FF",
                                }}
                            >
                                <FeatureCell value={planDetail.title} info={planDetail.titleInfo} position={isFirstItem ? 'bottom' : ''} size="15px" />
                            </div>

                            <div className="h-[60px] flex items-center justify-center text-center border-l border-yellow-dark"
                                style={{
                                    borderBottom: isLastItem ? undefined : "1px solid #F5F2FF",
                                }}
                            >
                                <FeatureCell value={planDetail.firstValue} info={planDetail.firstInfo} position={isFirstItem ? 'bottom' : ''} />
                            </div>
                            <div className="h-[60px] flex items-center justify-center text-center border-l border-star-default bg-star-light3"
                                style={{
                                    borderBottom: isLastItem ? undefined : "1px solid #F5F2FF",
                                }}
                            >
                                <FeatureCell value={planDetail.secondValue} info={planDetail.secondInfo} position={isFirstItem ? 'bottom' : ''} />
                            </div>
                            <div className="h-[60px] flex items-center justify-center text-center border-l border-r border-sky-dark"
                                style={{
                                    borderBottom: isLastItem ? undefined : "1px solid #F5F2FF"
                                }}
                            >
                                <FeatureCell value={planDetail.thirdValue} info={planDetail.thirdInfo} position={isFirstItem ? 'bottom' : 'left'} />
                            </div>
                        </div>
                    );
                })}
            </div>

        </>
    );
};


export default FeatureList;