import {
    Copy,
    Plus,
    Post
} from "iconoir-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RecreateTemplate from "../pages/RecreateTemplate";
import ContentExpand from "./ContentExpand";
import { unsecuredCopyToClipboard } from "./text-editor/copyClipboard";
import { trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";


const TemplateCard = (props) => {
    const [open, setOpen] = useState(false);
    const [bookmarkModal, setbookmarkModal] = useState(false)
    const [bookmarkStatus, setBookmarkStatus] = useState(false);
    const [firstOpen, setFirstOpen] = useState(false);
    const postIds = useSelector((state) => state.post.postIds);
    const trackInteractionEvent = (actionTaken) => {
        trackEvent(MIXPANEL_EVENTS.TEMPLATE_INTERACTION, {
            "Template category": props.type,
            "Template name": props.name,
            "Action Taken": actionTaken
        })
    }
    const handleBookmark = () => {
        setbookmarkModal(bookmarkModal ? false : true)
    }

    const bookmarkStatusHandle = (status) => {
        setBookmarkStatus(status);
    }

    const {
        _id,
        name,
        content,
        contentExample,
        type,
    } = props;

    const highlightText = (text, highlightColor) => {
        const customSymbolRegex = /\^([^\^]+)\^/g;  // No need to escape ^

        const parts = text?.split(customSymbolRegex);

        return parts?.map((part, index) => {
            // Odd indices will contain content between `^`, indicating the highlighted text
            if (index % 2 !== 0) {
                return part;  // Simply return the part without the ^ symbols
            }
            return part; // Return non-highlighted parts as they are
        }).join('');
    };

    const handleCopyClipBoard = () => {
        trackInteractionEvent("Copy")
        const extractText = highlightText(content)
        unsecuredCopyToClipboard(extractText)
    }

    const handleRecreate = () => {
        setOpen(true);
        trackInteractionEvent("Recreate");
        // trackEvent(MIXPANEL_EVENTS.TEMPLATE_RAW)
    }

   
    return (
        <>
            {
                open && <RecreateTemplate {...props} postType={type} startTime={new Date()} onClose={() => { setOpen(false) }} />
            }

            <div className="min-w-[350px]">

                <div className="w-full h-full relative " style={{
                    transition: 'transform 0.6s',
                    transformStyle: 'preserve-3d',
                    transform: props?.isToggled ? 'rotateY(180deg)' : 'rotateY(0deg)',
                }} >


                    {/* {frontside} */}

                    <div className=" w-full absolute h-full"
                        style={{
                            WebkitBackfaceVisibility: 'hidden',
                            backfaceVisibility: 'hidden',

                        }}>
                        <div className="px-4 py-2 flex justify-between items-center bg-white rounded-t-xl">
                            <div className="flex gap-2 items-center justify-around">
                                <div>
                                    <Post color="#8B8699" />
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex items-center text-gray-6">
                                        <p className="text-bodyTwo "> {name}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="cursor-pointer  border-none  " onClick={
                                props?.toggleChild
                            } >
                                <div>
                                    <button className="text-btn small text-bodyTwo"
                                    >
                                        View Example
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-b-xl bg-gray-moon hover:bg-white">
                            <ContentExpand content={content} maxLines={7} isToggled={props?.isToggled} onClick={(val) => { setFirstOpen(val) }} highlightColor={false} templateFormat={true} trackPostInteraction={trackInteractionEvent} />
                            <div className="flex gap-2 mt-2">
                                <button className="filled-btn small w-full text-base" onClick={handleRecreate}>
                                    <Plus />
                                    Create with AI
                                </button>
                                <button className="text-btn small" onClick={handleCopyClipBoard}>
                                    <Copy />
                                </button>
                            </div>
                        </div>
                    </div>


                    {/* backside */}
                    <div className="w-full absolute h-full"
                        style={{
                            WebkitBackfaceVisibility: 'hidden',
                            backfaceVisibility: 'hidden',
                            transform: 'rotateY(180deg)',
                        }}>
                        <div className="px-4 py-2 flex justify-between items-center  bg-star-default rounded-t-xl ">
                            <div className="flex gap-2 items-center justify-around">
                                <div>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" stroke="#A489F3" viewBox="0 0 37 36" fill="none">
                        <path d="M14.564 22.0272L16.3907 18.0956M22.0088 22.0124L20.4452 18.0645M20.4452 18.0645L18.5913 13.177L16.3907 18.0956M20.4452 18.0645L16.3907 18.0956" stroke="#7145FD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.541 9.62695C11.8507 9.62695 11.291 10.1866 11.291 10.877V24.877C11.291 25.5673 11.8507 26.127 12.541 26.127H24.541C25.2314 26.127 25.791 25.5673 25.791 24.877V10.877C25.791 10.1866 25.2314 9.62695 24.541 9.62695H12.541ZM9.79102 10.877C9.79102 9.35817 11.0222 8.12695 12.541 8.12695H24.541C26.0598 8.12695 27.291 9.35817 27.291 10.877V24.877C27.291 26.3957 26.0598 27.627 24.541 27.627H12.541C11.0222 27.627 9.79102 26.3957 9.79102 24.877V10.877Z" fill="#7145FD" />
                    </svg> */}

                                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
                                        <path d="M14.564 22.0272L16.3907 18.0956M22.0088 22.0124L20.4452 18.0645M20.4452 18.0645L18.5913 13.177L16.3907 18.0956M20.4452 18.0645L16.3907 18.0956" stroke="#A489F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.541 9.62695C11.8507 9.62695 11.291 10.1866 11.291 10.877V24.877C11.291 25.5673 11.8507 26.127 12.541 26.127H24.541C25.2314 26.127 25.791 25.5673 25.791 24.877V10.877C25.791 10.1866 25.2314 9.62695 24.541 9.62695H12.541ZM9.79102 10.877C9.79102 9.35817 11.0222 8.12695 12.541 8.12695H24.541C26.0598 8.12695 27.291 9.35817 27.291 10.877V24.877C27.291 26.3957 26.0598 27.627 24.541 27.627H12.541C11.0222 27.627 9.79102 26.3957 9.79102 24.877V10.877Z" fill="#A489F3" />
                                    </svg>

                                </div>
                                <div className="flex flex-col">
                                    <div className="flex items-center text-star-light">
                                        <p className="text-bodyTwo "> {`${name} Example`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="cursor-pointer " onClick={
                                props?.toggleChild
                            } >
                                <button className="filled-btn text-bodyTwo small hover-white w-full text-base hover:bg-star-light4"
                                >

                                    View Templates
                                </button>
                            </div>
                        </div>
                        <div className="p-3 rounded-b-xl bg-star-dark ">
                            <ContentExpand content={contentExample} maxLines={7} tracker={true} trackingData="" isToggled={props?.isToggled} highlightColor={true} templateFormat={true} trackPostInteraction={trackInteractionEvent}  />
                            <div className="flex gap-2 mt-2 text-gray-moon">
                                <button className="filled-btn small hover-white w-full text-base hover:bg-star-light4" onClick={handleRecreate}>
                                    <Plus />
                                    Create with AI
                                </button>
                                <button className="text-btn dark small hover:bg-star-light4" onClick={handleCopyClipBoard}>
                                    <Copy />
                                </button>
                            </div>
                        </div>
                    </div>


                    {
                        !props?.isToggled ? (
                            <div className=" w-full h-full"
                                style={{
                                    WebkitBackfaceVisibility: 'hidden',
                                    backfaceVisibility: 'hidden',

                                }}>
                                <div className="px-4 py-2 flex justify-between items-center bg-white rounded-t-xl">
                                    <div className="flex gap-2 items-center justify-around">
                                        <div>
                                            <Post color="#8B8699" />
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex items-center text-gray-6">
                                                <p className="text-bodyTwo ">  {`${name} Example`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cursor-pointer" onClick={
                                        props?.toggleChild
                                    } >
                                        <button className="filled-btn text-bodyTwo small hover-white w-full text-base hover:bg-star-light4"
                                        >

                                            View Templates
                                        </button>
                                    </div>
                                </div>
                                <div className="p-4 rounded-b-xl bg-gray-moon hover:bg-white">
                                    <ContentExpand content={content} maxLines={7} defaultValue={firstOpen} tracker={true} trackingData="" isToggled={props?.isToggled} highlightColor={false} templateFormat={true} trackPostInteraction={trackInteractionEvent} />
                                    <div className="flex mt-2 gap-2 ">
                                        <button className="filled-btn small w-full text-base" onClick={handleRecreate}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0699 7.45379C18.9649 6.55884 18.9649 5.10783 18.0699 4.21288L16.8914 3.03437C15.9965 2.13942 14.5455 2.13942 13.6505 3.03437L4.40412 12.2807C4.02362 12.6613 3.78915 13.1635 3.7418 13.6995L3.54006 15.9832C3.46019 16.8874 4.21688 17.6441 5.12107 17.5642L7.40475 17.3625C7.94077 17.3151 8.44304 17.0807 8.82354 16.7002L18.0699 7.45379ZM17.186 5.09677C17.5928 5.50356 17.5928 6.16311 17.186 6.56991L16.3946 7.36129L13.743 4.70964L14.5344 3.91825C14.9412 3.51146 15.6007 3.51146 16.0075 3.91826L17.186 5.09677ZM12.8591 5.59352L15.5108 8.24517L7.93966 15.8163C7.7667 15.9892 7.5384 16.0958 7.29475 16.1173L5.01107 16.3191C4.8819 16.3305 4.7738 16.2224 4.78521 16.0932L4.98695 13.8095C5.00847 13.5659 5.11505 13.3376 5.28801 13.1646L12.8591 5.59352Z" fill="#F7F6FF" />
                                            </svg>
                                            Create with AI
                                        </button>
                                        <button className="text-btn small text-gray-moon" onClick={handleCopyClipBoard}>
                                            <Copy className="text-star-light4" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full h-full"
                                style={{
                                    WebkitBackfaceVisibility: 'hidden',
                                    backfaceVisibility: 'hidden',
                                    transform: 'rotateY(180deg)',
                                }}>
                                <div className="px-4 py-2 flex justify-between items-center  bg-star-default rounded-t-xl ">
                                    <div className="flex gap-2 items-center justify-around">
                                        <div>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
                                                <path d="M14.564 22.0272L16.3907 18.0956M22.0088 22.0124L20.4452 18.0645M20.4452 18.0645L18.5913 13.177L16.3907 18.0956M20.4452 18.0645L16.3907 18.0956" stroke="#A489F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.541 9.62695C11.8507 9.62695 11.291 10.1866 11.291 10.877V24.877C11.291 25.5673 11.8507 26.127 12.541 26.127H24.541C25.2314 26.127 25.791 25.5673 25.791 24.877V10.877C25.791 10.1866 25.2314 9.62695 24.541 9.62695H12.541ZM9.79102 10.877C9.79102 9.35817 11.0222 8.12695 12.541 8.12695H24.541C26.0598 8.12695 27.291 9.35817 27.291 10.877V24.877C27.291 26.3957 26.0598 27.627 24.541 27.627H12.541C11.0222 27.627 9.79102 26.3957 9.79102 24.877V10.877Z" fill="#A489F3" />
                                            </svg>

                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex items-center text-star-light">
                                                <p className="text-bodyTwo ">{`${name} (Example)`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cursor-pointer" onClick={
                                        props?.toggleChild
                                    } >
                                        <button className="filled-btn text-bodyTwo small hover-white w-full text-base hover:bg-star-light4"
                                        >

                                            View Templates
                                        </button>
                                    </div>
                                </div>
                                <div className="p-3 rounded-b-xl bg-star-dark ">
                                    <ContentExpand content={contentExample} maxLines={7} tracker={true} trackingData="" isToggled={props?.isToggled} highlightColor={true} templateFormat={true} trackPostInteraction={trackInteractionEvent} />
                                    <div className="flex gap-2 mt-2 text-gray-moon">
                                        <button className="filled-btn small hover-white w-full text-base hover:bg-star-light4" onClick={handleRecreate}>
                                            <Plus />
                                            Create with AI
                                        </button>
                                        <button className="text-btn dark small hover:bg-star-light4" onClick={handleCopyClipBoard}>
                                            <Copy />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }


                </div>


            </div>


        </>
    )
}
export default TemplateCard;

