import { Xmark } from "iconoir-react";
import DialogBox from "../../../component/DialogBox";
import moment from "moment/moment";
import { useSelector } from "react-redux";

const HEROSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="261" height="230" viewBox="0 0 261 230" fill="none">
            <circle cx="143.002" cy="95.5455" r="91.2144" fill="#7145FD" />
            <path d="M103.023 104.836L128.925 123.965C130.701 125.276 133.199 124.928 134.549 123.182L179.369 65.2062" stroke="white" strokeWidth="14.8863" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M193.827 164.182C184.167 160.02 173.634 160.73 164.972 165.219C161.518 159.033 156.074 153.895 149.065 150.875C134.691 144.682 118.364 149.391 109.316 161.329C96.9079 158.207 84.6183 161.286 77.758 169.108H30.0869C13.9513 169.108 0.870834 182.556 0.870834 199.144C0.870834 215.733 13.9513 229.181 30.0869 229.181H209.735C225.871 229.181 238.951 215.733 238.951 199.144C238.951 182.556 225.871 169.108 209.735 169.108H201.918C199.524 167.139 196.816 165.47 193.827 164.182Z" fill="white" />
            <path d="M67.3948 31.1455C68.5498 29.8449 70.7008 30.6022 70.7861 32.3396L71.2228 41.2302C71.2484 41.7514 71.4827 42.2403 71.8728 42.5868L78.5285 48.4974C79.8292 49.6525 79.0718 51.8034 77.3345 51.8887L68.4438 52.3254C67.9226 52.351 67.4337 52.5853 67.0872 52.9754L61.1766 59.6312C60.0216 60.9318 57.8706 60.1744 57.7853 58.4371L57.3487 49.5464C57.3231 49.0252 57.0888 48.5363 56.6986 48.1898L50.0429 42.2792C48.7423 41.1242 49.4996 38.9732 51.237 38.8879L60.1276 38.4513C60.6488 38.4257 61.1377 38.1914 61.4842 37.8012L67.3948 31.1455Z" fill="url(#paint0_linear_1224_2351)" />
            <rect x="73.5156" y="174.452" width="138.971" height="45.6729" rx="7" fill="#F5F2FF" />
            <path d="M81.6035 191.21C81.6035 189.076 83.3338 187.346 85.4683 187.346H200.532C202.666 187.346 204.396 189.076 204.396 191.21C204.396 193.345 202.666 195.075 200.532 195.075H85.4683C83.3338 195.075 81.6035 193.345 81.6035 191.21Z" fill="white" />
            <path d="M174.851 110.318L173.368 150.36L160.763 145.229C156.177 143.363 152.379 149.192 155.939 152.633L179.332 175.244C181.447 177.288 184.796 177.307 186.933 175.287L212.22 151.383C215.997 147.812 211.716 141.743 207.085 144.104L194.203 150.669L194.336 110.711C194.354 105.474 190.228 101.159 184.994 100.942C179.6 100.718 175.051 104.923 174.851 110.318Z" fill="#FD6483" stroke="#7145FD" strokeWidth="5" />
            <path d="M206.795 178.673C208.581 176.883 211.61 178.451 211.179 180.942L209.336 191.611C209.193 192.442 209.463 193.29 210.059 193.885L217.725 201.531C219.515 203.316 217.947 206.345 215.456 205.915L204.787 204.072C203.957 203.929 203.108 204.199 202.513 204.795L194.867 212.461C193.082 214.251 190.053 212.683 190.483 210.192L192.326 199.523C192.469 198.692 192.2 197.844 191.603 197.249L183.938 189.603C182.148 187.818 183.715 184.789 186.207 185.219L196.875 187.062C197.706 187.205 198.554 186.936 199.149 186.339L206.795 178.673Z" fill="#7145FD" />
            <defs>
                <linearGradient id="paint0_linear_1224_2351" x1="46.7365" y1="62.7048" x2="77.6399" y2="67.5404" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5F45FD" />
                    <stop offset="0.709584" stop-color="#FDA2F5" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const LINESVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="680" height="222" viewBox="0 0 680 222" fill="none">
            <path d="M830.036 -91.6372L730.475 89.2437C697.557 149.048 747.484 220.645 814.98 210.427V210.427C878.482 200.814 894.685 116.753 839.307 84.2251L622.88 -42.8984C620.579 -44.2495 618.01 -45.0789 615.354 -45.3279L142.317 -89.6719C135.503 -90.3106 130.811 -82.9887 134.238 -77.0649V-77.0649C205.309 45.7794 104.858 196.843 -35.915 178.82L-51.4893 176.826" stroke="url(#paint0_linear_1224_2370)" strokeWidth="19.7993" />
            <defs>
                <linearGradient id="paint0_linear_1224_2370" x1="509.096" y1="-127.889" x2="-216.863" y2="685.334" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5F45FD" />
                    <stop offset="0.33" stop-color="#FDA1F5" />
                    <stop offset="0.635" stop-color="#FBDEBC" />
                    <stop offset="1" stop-color="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}
const DowngradeSuccessDialog = ({ planDetail, onClose }) => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;
    const currentPlanName = subscription?.planName;

    const planName = planDetail?.name;

    const expireDate = moment(subscription?.endDate).format("DD MMM’YY");
    return (
        <DialogBox>
            <div className="bg-white rounded-3xl shadow-drop-shadow w-[680px] p-8 relative">
                <div className="absolute top-0 left-0">
                    <LINESVG />
                </div>
                <div className="flex flex-col items-center justify-center relative z-10">
                    <div className="flex w-full justify-end">
                        <button onClick={onClose} className="icon-outline-btn small border-none">
                            <Xmark />
                        </button>
                    </div>
                    <p className="text-center mt-1 mb-16 text-transparent bg-clip-text text-subHeadingThreeBold bg-heading-gradient-3">Downgrade successful</p>
                    <HEROSVG />
                    <p className="text-bodyOne mt-6 text-center text-gray-5">
                        You will continue on the <span className="font-bold text-gray-6">{currentPlanName}</span> plan till
                        <span className="font-bold text-gray-6"> {expireDate}</span> after which you will be shifted to
                        <span className="font-bold text-gray-6"> {planName}</span>
                    </p>

                </div>
            </div>
        </DialogBox>
    );
};
export default DowngradeSuccessDialog;