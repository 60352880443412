import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyRecaptcha } from "../services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateCaptchaStatus } from "../redux/slice/auth.slice";
import { GOOGLE_CAPTCHA_KEY } from "../utils/config";
import { trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";

const BotDetection = ({ children }) => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const requireCaptcha = authState.requireCaptcha;
  const recaptchaRef = useRef(null); // Ref for reCAPTCHA

  // Your reCAPTCHA site key (client key)
  const clientKey = GOOGLE_CAPTCHA_KEY;

  // Function to handle reCAPTCHA verification
  const handleVerify = async () => {
    const token = await recaptchaRef.current.getValue(); // Get the reCAPTCHA token

    // Send the token to your backend for verification
    const isValid = await verifyRecaptcha(token);
    if (isValid) {
      dispatch(updateCaptchaStatus(false));
      window?.location?.reload();
      trackEvent(MIXPANEL_EVENTS.CAPTCHA_SUCCESS);
    } else {
      trackEvent(MIXPANEL_EVENTS.CAPTCHA_FAILED)
      alert("Invalid reCAPTCHA. Please try again.");
    }
  };
  const handleLogout = () => {
    dispatch(logout());
  }

  // If verified, render the children
  return <>
    {
      requireCaptcha && (
        <div className="fixed w-full z-[999999] h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div>
            <button onClick={handleLogout} className="outlined-btn bg-white absolute top-6 right-6 small">
              Logout
            </button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="mb-4 text-bodyThree text-star-default">Suspicious activity detected. <br /> Please verify you’re a human to continue.</p>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={clientKey}
              onChange={handleVerify} // Automatically verify when the user completes the challenge
            />
          </div>
        </div>
      )
    }
    {
      children
    }
  </>;
};

export default BotDetection;