import { Download, Xmark } from "iconoir-react";
import { useRef, useState } from "react";
import ShareIcon from "../asset/svg/ShareIcon";
import Facebook from "../asset/svg/social-share/Facebook";
import Link from "../asset/svg/social-share/Link";
import Linkedin from "../asset/svg/social-share/Linkedin";
import Whatsapp from "../asset/svg/social-share/Whatsapp";
import XIcon from "../asset/svg/social-share/XIcon";
import TrendUp from "../asset/svg/Trendup";
import { downloadImage } from "../utils/helper";
import DialogBox from "./DialogBox";
import { unsecuredCopyToClipboard } from "./text-editor/copyClipboard";
import VideoPauseIcon from "../asset/svg/VideoPauseIcon";
import VideoPlayIcon from "../asset/svg/VideoPlayIcon";

const VideoPlayer = ({ mediaURL }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true); // Tracks play/pause state
    const [isEnded, setIsEnded] = useState(false);   // Tracks if the video has ended

    const handleToggle = () => {
        if (isPlaying) {
            videoRef.current.pause();
            setIsPlaying(false);
        } else {
            videoRef.current.play();
            setIsPlaying(true);
            setIsEnded(false); // Reset end state if video is restarted
        }
    };

    const handleVideoEnd = () => {
        setIsPlaying(false);
        setIsEnded(true); // Mark the video as ended
    };

    return (
        <div className="relative" onClick={handleToggle}>
            {(!isPlaying || isEnded) && (
                <div
                    className="absolute rounded-lg top-0 h-full w-full flex items-center justify-center"
                >
                    <div
                        className="h-full absolute bottom-0 left-0 w-full"
                        style={{
                            background:
                                "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.05%, rgba(0, 0, 0, 0.45) 99.77%)",
                            opacity: 0.69,
                        }}
                    />
                    <div className="z-10">
                        {isEnded ? <VideoPlayIcon /> : <VideoPauseIcon />}
                    </div>
                </div>
            )}

            <video
                ref={videoRef}
                autoPlay
                src={mediaURL}
                className="rounded-lg object-contain max-h-[56vh] hide-controller"
                alt="media-video"
                onEnded={handleVideoEnd} // Handle end of video
            />
        </div>
    );
};

const MediaShareDialog = (props) => {
    const {
        onClose,
        mediaURL,
        mediaType
    } = props;

    const shareURL = `${window.location.origin}/share?code=${mediaURL}&type=${mediaType}`

    const encodedImageUrl = encodeURIComponent(shareURL);

    const shareList = [
        {
            name: "LinkedIn",
            icon: <Linkedin />,
            href: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedImageUrl}`,
        },
        {
            name: "WhatsApp",
            href: `https://api.whatsapp.com/send?text=${encodedImageUrl}`,
            icon: <Whatsapp />

        },
        {
            name: "Facebook",
            href: `https://www.facebook.com/sharer/sharer.php?u=${encodedImageUrl}`,
            icon: <Facebook />
        },
        {
            name: "X",
            href: `https://twitter.com/intent/tweet?url=${encodedImageUrl}&text=Check out this image!`,
            icon: <XIcon />
        },

    ]


    return (
        <DialogBox>
            <div className="p-8 rounded-3xl min-w-[525px] bg-white max-h-[85vh]">
                <div className="flex items-center justify-center relative">
                    <p className="text-center text-subHeadingTwoBold bg-clip-text text-transparent bg-heading-gradient-3">Share this trending {mediaType}</p>
                    <button onClick={onClose} className="icon-outline-btn border-none small absolute right-0">
                        <Xmark />
                    </button>
                </div>
                <div className="my-6 max-w-[400px] overflow-hidden rounded-lg group flex justify-center w-max mx-auto tooltip-wrapper ">
                    {
                        mediaType === 'video' ? (
                            <VideoPlayer mediaURL={mediaURL} />
                        ) : (
                            <img src={mediaURL} className="rounded-lg object-contain  max-h-[56vh]" alt="media-img" />
                        )
                    }
                    <div className="translate-y-6 opacity-0  transition-all group-hover:translate-y-0 group-hover:opacity-100 h-[30%] flex items-end absolute bottom-0 left-0 w-full" >
                        <div className="h-full absolute bottom-0 left-0 w-full" style={{
                            background: "linear-gradient(180deg, rgba(18, 11, 42, 0.00) 0%, #000 75.53%)",
                            opacity: 0.69
                        }} />

                        <div className="p-4 z-10 w-full">

                            <button
                                className="filled-btn mx-auto max-w-[250px] w-full small"
                                onClick={() => {
                                    downloadImage(mediaURL)
                                }}
                            >
                                <Download />
                                Download
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    {
                        shareList.map((listItem) => (
                            <div className="flex flex-col gap-2 items-center justify-center">
                                <a href={listItem.href} target="_blank">
                                    {listItem.icon}
                                </a>
                                <p className="text-bodyOne text-space-default">{listItem.name}</p>
                            </div>
                        ))
                    }

                    <div className="flex flex-col gap-2 items-center justify-center">
                        <button onClick={() => {
                            unsecuredCopyToClipboard(shareURL)
                        }}>
                            <Link />
                        </button>
                        <p className="text-bodyOne text-space-default">Link</p>
                    </div>
                </div>

            </div>
        </DialogBox>
    )
}
const MediaCard = (props) => {
    const [openShare, setOpenShare] = useState(false);
    const {
        mediaURL,
        mediaType,
        topicGroupId,
        source
    } = props;

    return (
        <>
            {openShare && (
                <MediaShareDialog {...props} onClose={() => { setOpenShare(false); }} />
            )}
            <div className="rounded-lg overflow-hidden relative group cursor-pointer" onClick={(event) => {
                event.stopPropagation();
                setOpenShare(true);
            }}>
                <div className="rounded-xl top-4 right-4 z-[5] group-hover:opacity-0 transition-all text-bodyThree absolute px-3 py-1 gap-1 text-white flex items-center" style={{
                    background: "rgba(18, 11, 42, 0.50)",
                    backdropFilter: "blur(1.5px)",
                }}>
                    {source === 'manual' && <TrendUp />}
                    {/* <TrendUp /> */}
                    {topicGroupId.groupName}
                </div>
                {
                    mediaType === 'video' ? (
                        <div className="relative">
                            <div
                                className="absolute rounded-lg top-0 h-full w-full  flex items-center justify-center"
                            >
                                <div className="h-full absolute bottom-0 left-0 w-full" style={{
                                    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.05%, rgba(0, 0, 0, 0.45) 99.77%)",
                                    opacity: 0.69
                                }} />
                                <div className="z-10">
                                    <VideoPlayIcon />
                                </div>
                            </div>

                            <video src={mediaURL} className="rounded-lg w-full" />
                        </div>
                    ) : (
                        <img src={mediaURL} className="rounded-lg w-full" alt="" />
                    )
                }

                <div className="translate-y-6 opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100 h-[60%] flex items-end absolute bottom-0 left-0 w-full" >
                    <div className="h-full absolute bottom-0 left-0 w-full" style={{
                        background: "linear-gradient(180deg, rgba(18, 11, 42, 0.00) 0%, #000 75.53%)",
                        opacity: 0.69
                    }} />
                    <div className="flex gap-2 z-10 p-3 mt-auto  w-full">
                        <button
                            className="filled-btn w-full small"
                            onClick={(event) => {
                                event.stopPropagation();
                                downloadImage(mediaURL)
                            }}
                        >
                            <Download />
                            Download
                        </button>

                        <button className="filled-btn small white" onClick={() => { setOpenShare(true); }}><ShareIcon /></button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MediaCard;