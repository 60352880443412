const VideoPauseIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.6" cx="27.625" cy="27.9707" r="27.5" fill="#120B2A" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.75 20.4573C19.75 19.5875 20.4552 18.8823 21.325 18.8823H24.5917C25.4615 18.8823 26.1667 19.5875 26.1667 20.4573V35.3907C26.1667 36.2605 25.4615 36.9657 24.5917 36.9657H21.325C20.4552 36.9657 19.75 36.2605 19.75 35.3907V20.4573Z" fill="#EDEAFF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M29.0833 20.4573C29.0833 19.5875 29.7885 18.8823 30.6583 18.8823H33.925C34.7948 18.8823 35.5 19.5875 35.5 20.4573V35.3907C35.5 36.2605 34.7948 36.9657 33.925 36.9657H30.6583C29.7885 36.9657 29.0833 36.2605 29.0833 35.3907V20.4573Z" fill="#EDEAFF" />
        </svg>

    )
}
export default VideoPauseIcon;