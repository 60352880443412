import { Heart } from "iconoir-react";
import { useSelector } from "react-redux";
import ConfettiBg from '../../../asset/image/confettiBg.png'
import SegmentedBtn from "../../../component/SegmentedBtn";
import useScrolledDown from "../../../hooks/useScrolledDown";
import { useLocation } from "react-router-dom";

const PricingHeader = ({ tenure, planList, setActiveTenure }) => {
    let { scrolledDown: sticky } = useScrolledDown(200);
    const { pathname } = useLocation();
    const tabs = [
        'Yearly',
        'Monthly'
    ];
    const authState = useSelector((state) => state.auth);
    const { subscription } = authState;
    const isFreeTrial = subscription?.isFreeTrial;
    const activePlanId = subscription?.planId;
    const cometData = planList.find((planItem) => planItem.name.toLowerCase() === 'super comet');
    const starData = planList.find((planItem) => planItem.name.toLowerCase() === 'super star');
    const novaData = planList.find((planItem) => planItem.name.toLowerCase() === 'super nova');

    const headerList = [
        {
            ...cometData,
            name: 'Comet',
            amount: cometData?.amountText,

            hasOffer: cometData?.hasOffer,
            primaryText: cometData?.primaryText,
            secondaryText: cometData?.secondaryText,
            amountAfterDiscount: cometData?.amountAfterDiscount,
            isCurrentPlan: activePlanId === cometData?._id,
        },
        {
            ...starData,
            name: 'Star',
            amount: starData?.amountText,
            isRecommended: isFreeTrial,

            hasOffer: starData?.hasOffer,
            primaryText: starData?.primaryText,
            secondaryText: starData?.secondaryText,
            amountAfterDiscount: starData?.amountAfterDiscount,
            isCurrentPlan: activePlanId === starData?._id

        },
        {
            ...novaData,
            name: 'Nova',
            amount: novaData?.amountText,

            hasOffer: novaData?.hasOffer,
            primaryText: novaData?.primaryText,
            secondaryText: novaData?.secondaryText,
            amountAfterDiscount: novaData?.amountAfterDiscount,
            isCurrentPlan: activePlanId === novaData?._id
        },
    ]
    const tenureText = tenure === 1 ? '/month' : '/month (billed annually)';
    const isPaymentPage = pathname.includes('/plan');

    return (
        <div className="grid grid-cols-4 pt-5 bg-star-light4 w-full z-10" style={{
            top: isFreeTrial ? '48px' : 0
        }}>
            <div >
                {sticky && (
                    <div className="pr-5">
                        <div className="relative">
                            <SegmentedBtn list={tabs} onChange={setActiveTenure} activeIndex={tenure} tabStyle="flex-1" />
                            <div className="absolute rounded-full border border-star-light4 bg-star-default text-white w-[22px] h-[22px] grid place-items-center -bottom-2 left-1">
                                {starData?.currency === 'INR' ? '₹' : '$'}
                            </div>
                        </div>
                        <div className="text-bodyThree text-star-light pl-6 mt-0.5">(Save 30%)</div>
                    </div>
                )}
            </div>
            {
                headerList.map(((headerItem, index) => {
                    const isFirst = index === 0;
                    const isLast = (index + 1) === headerList.length;
                    const hasOffer = headerItem.hasOffer;

                    let style = '';
                    let textColor = '';
                    let bgColor = '';
                    if (isFirst) {
                        style = 'border-t border-l rounded-tl-2xl  border-yellow-dark';
                        bgColor = 'bg-yellow-dark';
                        if (hasOffer) {
                            style += ' bg-yellow-dark text-white'
                        } else {
                            style += ' bg-yellow-light'
                            textColor = 'text-yellow-dark'
                        }
                    } else if (isLast) {
                        style = 'border-t border-l border-r rounded-tr-2xl  border-sky-dark';
                        bgColor = 'bg-sky-dark';
                        if (hasOffer) {
                            style += ' bg-sky-dark text-white'
                        } else {
                            style += ' bg-sky-light'
                            textColor = 'text-sky-dark'

                        }
                    } else {
                        style = 'border-t border-l border-star-default text-white bg-star-default';
                        bgColor = 'bg-star-default border';
                    }

                    return (
                        <div className={`relative text-center py-5 ${style} relative`}>
                            {
                                headerItem.hasOffer && (
                                    <div className="absolute top-0 left-0 w-full h-full">
                                        <img src={ConfettiBg} alt="confetti-bg" />
                                    </div>
                                )
                            }
                            <p className={`text-subText ${textColor}`} >Super</p>
                            <p className={`text-headingFour ${textColor}`}>{headerItem.name}</p>
                            {
                                headerItem.hasOffer ? (
                                    <div>
                                        <p className="text-headingThree flex items-center justify-center gap-1">{headerItem.amountAfterDiscount}<span className="text-bodyThree">{headerItem.primaryText}</span> </p>
                                        <p className="text-bodyTwo"> {headerItem.secondaryText}  </p>
                                    </div>
                                ) : (
                                    <p className="text-headingThree flex justify-center items-center gap-1 ">{headerItem.amount}  <span className="text-bodyTwo ">{tenureText}</span></p>
                                )
                            }

                            {!headerItem.isCurrentPlan && headerItem.isRecommended && (
                                <div className="absolute border-2 rounded-lg border-star-light3 bg-star-default -bottom-[12px] left-1/2 -translate-x-1/2">
                                    <p className="flex items-center text-bodyThree gap-2 text-nebula-light px-3 py-0.5">
                                        <Heart color="#FD6483" fill="#FD6483" />
                                        Recommended
                                    </p>
                                </div>
                            )}
                            {headerItem.isCurrentPlan && (
                                <div className={`absolute rounded-lg -bottom-[14px] left-1/2 -translate-x-1/2 ${bgColor}`}>
                                    <p className="text-bodyThree text-white text-nowrap px-3 py-0.5">Current Plan</p>
                                </div>
                            )}
                        </div>
                    )
                }))
            }
        </div>
    )
}
export default PricingHeader;