import { WarningCircle } from "iconoir-react";
import { useEffect, useRef, useState } from "react";
import CountryCodeSelector from "./CountrySelect";

const countryCodes = [
    { code: "+1", country: "US" },
    { code: "+91", country: "IN" },
    { code: "+44", country: "UK" },
    { code: "+61", country: "AU" },
];

const PhoneNumberInput = ({ errorMessage, maxLength, value, onChange }) => {
    const ref = useRef();
    const [hasFocus, setFocus] = useState(false);

    
    // Extract countryCode and phoneNumber from value (if provided)
    const initialCode = value?.countryCode || countryCodes[0].code;
    const initialNumber = value?.phoneNumber || "";
    
    const [selectedCode, setSelectedCode] = useState(initialCode);
    const [phoneNumber, setPhoneNumber] = useState(initialNumber);

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);

    const handlePhoneChange = (e) => {
        const newNumber = e.target.value.replace(/\D/g, ''); // Remove non-numeric chars
        setPhoneNumber(newNumber);
        onChange({ countryCode: selectedCode, phoneNumber: newNumber });
    };

    const handleCodeChange = (value) => {
        const newCode = value;
        setSelectedCode(newCode);
        onChange({ countryCode: newCode, phoneNumber });
    };

    return (
        <div className="w-full relative">
            <div className="relative flex items-center gap-2">
                <CountryCodeSelector onSelectCountry={handleCodeChange}/>

                {/* Phone Number Input */}
                <input
                    className="rounded-lg text-bodyTwo text-space-default bg-gray-moon py-2.5 px-3.5 w-full"
                    style={{
                        border: hasFocus ? '1px solid transparent' : '1px solid #E0DDED',
                        backgroundImage: hasFocus
                            ? 'linear-gradient(#F7F6FF, #F7F6FF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)'
                            : undefined,
                        backgroundOrigin: 'border-box',
                        backgroundClip: 'padding-box, border-box',
                        outline: 'none'
                    }}
                    ref={ref}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    maxLength={maxLength}
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                />

                {/* Error Icon */}
                {!!errorMessage && (
                    <div className="absolute right-4 top-2.5">
                        <WarningCircle height={'24px'} width={'24px'} color="#FD6483" />
                    </div>
                )}
            </div>
            {/* Error Message */}
            {!!errorMessage && (
                <p className="text-bodyThree absolute left-16 text-venus-default">{errorMessage}</p>
            )}

        </div>
    );
};

export default PhoneNumberInput;


