import { Crown } from "iconoir-react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import { trackEvent } from "../../utils/mixpanel";

const Banner = () => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;
    const subscriptionLimit = authState.subscriptionLimit;
    const endDate = subscription.endDate;
    const now = moment(); // Current date

    const diffInDays = moment(endDate).diff(now, 'days', true);

    // console.log(diffInDays)
    let text = '';

    if (diffInDays <= 0) {
        text = 'Your FREE Trial plan is expired !!!'
    } else {
        text = `Your FREE Trial plan is expiring in ${Math.floor(diffInDays) + 1} day(s)!`
    }

    if (!subscription.isFreeTrial) {
        return <></>
    }

    const remaining = (subscriptionLimit.searchLimit - subscriptionLimit.searchUsage);
    const remainingText = remaining === subscriptionLimit.searchLimit ? remaining - 1 : remaining;
    return (
        <>
            <div className="h-[48px] " />
            <div className="fixed top-0 left-0 z-[11] w-full bg-star-light4">
                <div className="flex items-center justify-center h-[52px]  py-2 px-2.5 text-center" style={{
                    // borderColor: '#D9974A',
                    background: '#120B2A'
                }}>
                    <p className="text-bodyTwoBold" style={{ color: '#F7F6FF' }}>To get access to all features </p>
                    <Link to={'/plan'} className="ml-2 filled-btn white" style={{ padding: '4px 10px' }} onClick={() => {
                        trackEvent(MIXPANEL_EVENTS.UPGRADE_CLICKED, {
                            'Upgrade clicked from': 'Banner'
                        })
                    }}> <Crown className="text-star-default" /> <p className="text-buttonSmall">Upgrade now</p></Link>
                </div>
            </div>
        </>
    )
}

export default Banner;