const AiIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M11.1901 6.19764V5.84504C11.1901 2.89306 8.79702 0.5 5.84504 0.5V0.5C2.89306 0.5 0.5 2.89306 0.5 5.84504V5.84504C0.5 8.79702 2.89305 11.1901 5.84503 11.1901H6.73588" stroke="#A489F3" stroke-width="0.7" stroke-linecap="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.83814 7.77271C9.99195 7.77271 10.1166 7.89739 10.1166 8.0512C10.1166 8.61016 10.2369 8.93814 10.4322 9.13214C10.6279 9.32651 10.9563 9.44368 11.5091 9.44368C11.6629 9.44368 11.7876 9.56837 11.7876 9.72218C11.7876 9.87599 11.6629 10.0007 11.5091 10.0007C10.9582 10.0007 10.6292 10.1206 10.4329 10.3169C10.2365 10.5132 10.1166 10.8422 10.1166 11.3932C10.1166 11.547 9.99195 11.6717 9.83814 11.6717C9.68434 11.6717 9.55965 11.547 9.55965 11.3932C9.55965 10.8427 9.43909 10.5136 9.24226 10.3171C9.04534 10.1205 8.7161 10.0007 8.16717 10.0007C8.01336 10.0007 7.88867 9.87599 7.88867 9.72218C7.88867 9.56837 8.01336 9.44368 8.16717 9.44368C8.71795 9.44368 9.04668 9.32661 9.24291 9.13198C9.43867 8.93781 9.55965 8.6097 9.55965 8.0512C9.55965 7.89739 9.68434 7.77271 9.83814 7.77271Z" fill="#A489F3" />
            <path d="M3.00342 8.01308L4.69385 3.67676H5.67381L7.34586 8.01308H6.40265L6.13316 7.27198H4.21612L3.94663 8.01308H3.00342ZM4.49786 6.48189H5.85755L5.18383 4.60772L4.49786 6.48189Z" fill="#A489F3" />
            <path d="M7.81096 8.01308V3.67676H8.6868V8.01308H7.81096Z" fill="#A489F3" />
        </svg>
    )
}
export default AiIcon;