import api from "../utils/api";

export const fetchAITemplateCategory = async (prop) => {
    try {
        console.log(prop)
        const response = await api.get('/template/ai-template-category', {
            params: {
                type:prop.type
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};