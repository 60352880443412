import { ArrowUpRight, Plus, Twitter, Xmark } from "iconoir-react";
import DialogBox from "../../component/DialogBox";
import NormalSearch from "../../component/custom-form/NormalSearch";
import { useState } from "react";
import GradinetLink from "../../asset/svg/GradinetLink";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addFavCreatorByURL, fetchFavCreator, fetchSuggestedCreator } from "../../services/favCreator.service";
import AvatarIcon from "../../asset/svg/AvatarIcon";
import LinkedinIcon from "../../asset/svg/LinkedinIcon";
import { convertToK, verifyLinkedinTwitterURL } from "../../utils/helper";
import SuccessAddCreatorPopup from "./successAddCreatorPopup";
import XIcon from "../../asset/svg/XIcon";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import RemoveCreatorDialog from "../../component/dialog/RemoveCreatorDialog";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";


const LinkSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="176" height="119" viewBox="0 0 176 119" fill="none">
            <path d="M169.846 -82.9281L119.316 62.9466C106.462 100.054 67.1601 120.964 29.2039 110.888L-118.158 71.7687" stroke="url(#paint0_linear_153_4214)" strokeWidth="11" />
            <defs>
                <linearGradient id="paint0_linear_153_4214" x1="-69.1177" y1="70.9182" x2="143.509" y2="149.776" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8773FF" />
                    <stop offset="0.505" stopColor="#FFC0FA" />
                    <stop offset="1" stopColor="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>
    )
}


const CreatorCard = (props) => {
    const [removeCreatorModel, setRemoveCreatorModel] = useState(false);

    const dialogProps = {
        ...props,
        onClose: () => { setRemoveCreatorModel(false) },
        userId: props.user,
        source: props.source,
        accountId: props.profileId
    }
    return (
        <>
            {
                removeCreatorModel && <RemoveCreatorDialog {...dialogProps} />
            }
            {/* Add tooltip-wrapper class below to enable removecreator button */}
            <div className="rounded-xl  overflow-hidden w-[240px] relative border border-star-light2 flex items-center  flex-col p-4">
                {/* {
                    !props.default && (
                        <div className="hidden tooltip" onClick={() => {
                            setRemoveCreatorModel(true);
                        }}>
                            <div className="absolute z-10 p-0.5 right-2 top-2 rounded-full bg-venus-light2 flex items-center justify-center cursor-pointer">
                                <Xmark width={24} height={24} color="#BA1E45" />
                            </div>
                        </div>
                    )
                } */}
                <div className="w-full absolute top-0 h-[80px]" style={{ background: '#E2F1FF' }} >
                    {/* {
                        props?.user?.coverPhoto ? <img src={props?.user?.coverPhoto} className="h-full object-cover w-full" alt="cover-photo" /> : <></>
                    } */}
                </div>
                <div className="relative">
                    <AvatarIcon src={props?.user?.profilePhoto} width="108" height="108" />
                    <div className="absolute bottom-0 right-0">
                        {props.source === 'linkedin' ? <LinkedinIcon width={26} height={26} /> : <XIcon width={26} height={26} />}
                    </div>
                </div>
                <a href={props?.user.url} target="_blank" className="flex items-center justify-center mt-3 text-bodyOneBold text-space-default hover:text-star-default">
                    <p className="text-ellipsis overflow-hidden text-nowrap max-w-[120px] mx-2 text-center">
                        {props?.user.fullName || props?.user.username}
                    </p>
                    <ArrowUpRight width={20} />
                </a>
                <p className="text-bodyTwo w-full text-ellipsis overflow-hidden text-nowrap text-gray-6">
                    {props?.user.headLine || props?.user.description}
                </p>
                {
                    props?.user?.followerCount > 0 && <p className="text-bodyOne mt-1 text-star-light">{convertToK(props?.user?.followerCount)} followers </p>
                }
                {
                    props?.user?.followersCount > 0 && <p className="text-bodyOne mt-1 text-star-light">{convertToK(props?.user?.followersCount)} followers </p>
                }
                {
                    !props?.user?.lastScraped && (
                        <p className="text-bodyOne text-center text-gray-5">Creator addition in progress...</p>
                    )
                }

            </div>
        </>
    )
}

const SuggestedCreatorCard = (props) => {

    return (
        <>
            <div className="rounded-xl w-[240px] relative border border-star-light2 flex items-center  flex-col p-4">

                <div className=" tooltip-wrapper z-10 p-0.5 right-2 top-2 rounded-full bg-star-default flex items-center justify-center cursor-pointer" style={{
                    position: 'absolute'
                }} onClick={() => {
                    props.handlePress(props.user.url);
                }}>
                    <Plus width={24} height={24} color="white" />

                    <div className="tooltip hidden absolute -top-10 left-4 bg-white w-max px-4 py-2 " style={{
                        borderRadius: '8px 8px 8px 0px',
                        boxShadow: '0px 2px 12px 0px rgba(113, 69, 253, 0.12)',
                    }}>
                        <p className="text-bodyThree text-gray-5">
                            Add this fav creator instantly
                        </p>
                    </div>
                </div>

                <div className="w-full absolute top-0 h-[80px] rounded-t-xl" style={{ background: '#E2F1FF' }} />

                <div className="relative">
                    <AvatarIcon src={props?.user?.profilePhoto} width="108" height="108" />
                    <div className="absolute bottom-0 right-0">
                        {props.source === 'linkedin' ? <LinkedinIcon width={26} height={26} /> : <XIcon width={26} height={26} />}
                    </div>
                </div>
                <a href={props?.user.url} target="_blank" className="flex items-center justify-center mt-3 text-bodyOneBold text-space-default hover:text-star-default">
                    <p className="text-ellipsis overflow-hidden text-nowrap max-w-[120px] mx-2 text-center">
                        {props?.user.fullName || props?.user.username}
                    </p>
                    <ArrowUpRight width={20} />
                </a>
                <p className="text-bodyTwo w-full text-ellipsis overflow-hidden text-nowrap text-gray-6">
                    {props?.user.headLine || props?.user.description}
                </p>
                {
                    props?.user?.followerCount > 0 && <p className="text-bodyOne mt-1 text-star-light">{convertToK(props?.user?.followerCount)} followers </p>
                }
                {
                    props?.user?.followersCount > 0 && <p className="text-bodyOne mt-1 text-star-light">{convertToK(props?.user?.followersCount)} followers </p>
                }


            </div>
        </>
    )
}

const AddCreatorPopup = ({ onClose }) => {
    const favCreatorState = useSelector((state => state.favCreator));
    const authState = useSelector((state => state.auth));
    const favCreator = favCreatorState.creatorIds;
    const persona = authState.occupation;

    const [text, setText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [openSuccess, setOpenSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [queueSize, setQueueSize] = useState(false);
    const query = {
        limit: 30,
        favCreator,
        persona
    }
    const { data } = useQuery({
        queryKey: ['fav-creator', query],
        queryFn: async ({ queryKey }) => {
            const [favCreators, suggestedCreators] = await Promise.all([
                fetchFavCreator(queryKey[1]),
                fetchSuggestedCreator(queryKey[1]),
            ]);
            return { favCreators, suggestedCreators };
        },
        staleTime: Infinity,
        retry: false,
    });

    const queryClient = useQueryClient();

    const { mutate, isPending } = useMutation({
        mutationFn: addFavCreatorByURL,
        onSuccess: (data) => {
            let qSize = data?.data?.queueSize || 0;
            let needScraping = data?.data?.needScrapping;
            let inProgress = data?.data?.inProgress;
            trackEvent(MIXPANEL_EVENTS.CREATOR_ADDED, {
                'Creator URL': text
            })
            if (needScraping) {
                setOpenSuccess(true);
                setQueueSize(qSize);
            } else {
                if (inProgress) {
                    toast.success('Profile addition already in progress.');
                } else {
                    toast.success('Profile added Successfully!');
                }
                setOpenSuccess(false);
            }
            setText('');
            queryClient.invalidateQueries({ queryKey: ['fav-creator'] });
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong!!!');
            trackEvent(MIXPANEL_EVENTS.CREATOR_FAILED, {
                'Failure reason': error?.message
            })
        },
    });


    const handlePress = (text) => {
        const result = verifyLinkedinTwitterURL(text);
        if (result) {
            setError(false);
            setSearchText(text);
            mutate({
                url: text,
            })
        } else {
            setError(true);
        }
    }

    const closeAll = () => {
        setOpenSuccess(false);
        onClose();
    }

    const favCreatorList = data?.favCreators?.data;
    const suggestedCreatorList = data?.suggestedCreators?.data?.filter((item) => {
        return !favCreatorList?.some(fav => fav.profileId === item.profileId);
    })
    return (
        <DialogBox>
            {
                openSuccess && <SuccessAddCreatorPopup queueSize={queueSize} url={searchText} onClose={() => { setOpenSuccess(false) }} onCloseAll={closeAll} />
            }
            <div className="max-w-[1067px] w-[74%] rounded-[25px] overflow-hidden">
                <div className="rounded-[25px] max-h-[92vh] overflow-auto scrollbar relative bg-gray-moon p-8 ">
                    <div className="absolute top-0 left-0">
                        <LinkSVG />
                    </div>
                    <div className="flex justify-end ">
                        <div className="icon-outline-btn cursor-pointer small border-none" onClick={onClose}>
                            <Xmark />
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-headingThree"><span className="text-star-default">Add creators </span>whose posts you want to follow here</p>
                        <p className="text-bodyOne text-gray-6"> or select from our collection by clicking on a post from the ‘viral posts library’</p>

                        <div className="w-[55%] mt-6">
                            <NormalSearch icon={<GradinetLink />} value={text} onChange={setText} placeholder='Paste LinkedIn/Twitter profile URL of creator' onPressEnter={handlePress} />
                        </div>
                        {
                            error && <p className="text-bodyOne text-center text-venus-default">Invalid URL: Please enter a correct profile link</p>
                        }
                    </div>

                    <div className="mt-[42px] mb-3">
                        <p className="text-subHeadingThreeBold text-gray-6">Added creators | {data?.favCreators?.total || 0}</p>
                    </div>
                    <div className="flex gap-3 flex-wrap">
                        {
                            favCreatorList?.map((item) => (
                                <CreatorCard key={item._id} {...item} />
                            ))
                        }
                    </div>
                    {
                        suggestedCreatorList?.length > 0 && (
                            <>
                                <div className="mt-4 mb-3">
                                    <p className="text-subHeadingThreeBold text-gray-6">Quick Suggestions for you</p>
                                </div>
                                <div className="flex gap-3 flex-wrap">
                                    {
                                        suggestedCreatorList?.map((item) => (
                                            <SuggestedCreatorCard key={item._id} {...item} handlePress={handlePress} />
                                        ))
                                    }
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </DialogBox>
    )
}
export default AddCreatorPopup;