import { useQuery } from "@tanstack/react-query";
import { FastArrowLeft, FastArrowRight, Quote, Sparks, Xmark } from "iconoir-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import FileIcon from "../../asset/svg/FileIcon";
import LightBulbIcon from "../../asset/svg/LightBulbIcon";
import ContentExpand from "../../component/ContentExpand";
import DialogBox from "../../component/DialogBox";
import { fetchAITemplateCategory } from "../../services/template.service";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const LeftSide = ({ content, selectedComment }) => {
    const [show, setShow] = useState(false);
    const handleToggle = () => {
        setShow(prev => !prev);
    }
    return (
        <div className="rounded-[20px] overflow-hidden h-full ">
            <div className="rounded-[20px] p-4 overflow-auto bg-star-light3 h-[50vh]  scrollbar" >
                <div className="flex items-center gap-1">
                    <LightBulbIcon />
                    <p className="text-bodyOneBold text-star-dark">Your Inspiration</p>
                    {
                        show && (
                            <div className="flex gap-2 text-bodyTwo text-star-default items-center cursor-pointer ml-auto" onClick={handleToggle}>
                                <p>Hide</p>
                                <FastArrowLeft />
                            </div>
                        )
                    }
                </div>
                {
                    !show && (
                        <div className="flex gap-2 justify-end text-bodyTwo text-star-default items-center cursor-pointer mt-2" onClick={handleToggle}>
                            <p>Show</p>
                            <FastArrowRight />
                        </div>
                    )
                }
                {
                    show && (
                        <>
                            <p className="text-bodyTwo text-space-default my-4">
                                Reference post
                            </p>
                            <div className="rounded-xl bg-white p-4">
                                <ContentExpand content={content} maxLines={3} defaultValue={!selectedComment?.length} />
                            </div>

                        </>
                    )
                }
            </div>
        </div>
    )
}
const ToneScreen = ({
    _id,
    source,
    handleNext,
    onClose,
    rawThought,
    setRawThought,
    setAITemplateCategory,
    aiTemplateCategory,
    handleGenerateNewPost,
    content,
    pageSource,
}) => {
    const authState = useSelector((state) => state.auth);
    const { subscriptionLimit } = authState;
    const { aiCreditLimit, aiCreditUsage } = subscriptionLimit;

    const query = {
        type: 'RECREATE_POST'
    };
    const { data } = useQuery({
        queryKey: ['ai-template-category', query],
        queryFn: ({ queryKey }) => fetchAITemplateCategory(queryKey[1]),
        // staleTime: Infinity,
        retry: false
    });
    const trackAIClicked = () => {

        trackEvent(MIXPANEL_EVENTS.AI_POST, {
            "Source":pageSource,
            "Raw Thought": rawThought,
            "Tone selected": aiTemplateCategory?.name,
        })
    }

    return (
        <DialogBox>
            <div className="rounded-[20px] relative bg-gray-moon p-8 w-[782px] h-[67vh] ">
                <div className="absolute right-5 top-7">
                    <button className="icon-outline-btn  border-none small" onClick={onClose}>
                        <Xmark />
                    </button>
                </div>
                <p className="text-subHeadingTwoBold text-transparent bg-clip-text bg-hover-gradient-1">Recreate Post with AI</p>
                <p className="text-bodyOne text-gray-6">Select content tone and add your thoughts.</p>
                <div className="flex gap-5 mt-6 ">
                    <div className="max-w-[277px]">
                        <LeftSide content={content} />
                    </div>
                    <div className="flex-1 flex flex-col">
                        <div className="flex items-center gap-2">
                            <FileIcon />
                            <p className="text-bodyOne text-space-default">I want my post to be</p>
                        </div>
                        <div className="flex flex-wrap gap-2 mt-3">
                            {
                                data?.data?.map((item) => (
                                    <button key={item._id} className={`${aiTemplateCategory?._id === item._id ? 'active' : ''} outlined-btn small`} onClick={() => {
                                        setAITemplateCategory(item);
                                    }}>
                                        {item.name}
                                    </button>
                                ))
                            }
                        </div>
                        <div className="flex items-center gap-1 mt-5">
                            <Quote className="w-5 h-5 text-star-light" />
                            <p className="text-bodyTwo text-space-default">Also include</p>
                            <p className="text-bodyTwo text-gray-5">(Optional)</p>
                        </div>

                        <div className="mt-3">
                            <div className="relative">
                                <textarea
                                    value={rawThought}
                                    onChange={(event) => { setRawThought(event.target.value) }}
                                    rows={4}
                                    maxLength={100}
                                    className="rounded-lg outline-none resize-none w-full px-2 py-3 text-bodyTwo border border-gray-3 bg-white placeholder:text-gray-4"
                                    placeholder="I want this post to be about..."
                                />
                                <div className="absolute bottom-3 right-3">
                                    <p className="text-bodyThree text-gray-3">{rawThought.length}/100</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end mt-auto">
                            <p className="text-bodyThree text-gray-6">{aiCreditLimit-aiCreditUsage}/{aiCreditLimit} credits remaining</p>
                        </div>
                        <div className="flex justify-end mt-2">
                            <button
                                className={`filled-btn small w-[260px] v2`}
                                disabled={!aiTemplateCategory}
                                onClick={() => {
                                    handleGenerateNewPost({ content, rawThought, aiTemplateCategoryId: aiTemplateCategory?._id });
                                    handleNext();
                                    trackAIClicked();
                                }}>
                                <Sparks />
                                Generate with AI
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </DialogBox>
    )
}
export default ToneScreen;