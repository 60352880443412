import MainLayout from "../../layout/main";
import { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LogoutDialog from "./logoutDialog";
import DeleteAccount from "./deleteDialog";
import { EditPencil, LogOut, Mail, Xmark } from "iconoir-react";
import { trackEvent } from "@intercom/messenger-js-sdk";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import { initiateLinkedInAuth } from "../../services/onboardin.service";
import ToggleBtn from "../../component/custom-form/ToggleBtn";
import SegmentedBtn from "../../component/SegmentedBtn";
import Whatsapp from "../../asset/svg/whatsapp-light.svg";
import PhoneNumberInput from "../../component/custom-form/PhoneField";
import InputField from "../../component/form/InputField";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import RadioButton from "../../asset/svg/RadioButton";

import { saveOnBoarding } from "../../services/onboardin.service";
import { updateLinkedinConnect, updateOnboarding } from "../../redux/slice/auth.slice";
import { disconnectLinkedin } from "../../services/linkedin.service";
import DialogBox from "../../component/DialogBox";

const emailSchema = z.object({
    email: z.string().email("Invalid email format"),
});

const HEROSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="138" height="115" viewBox="0 0 138 115" fill="none">
            <path d="M56.6141 24.0484C57.9142 20.7495 62.298 20.0951 64.5049 22.8705L99.5116 66.8961C101.718 69.6715 100.093 73.7951 96.5863 74.3186L40.9557 82.6226C37.4487 83.1461 34.6901 79.6768 35.9902 76.3779L56.6141 24.0484Z" stroke="#FF9EB7" strokeWidth="4.18747" />
            <path fillRule="evenodd" clipRule="evenodd" d="M41.01 81.316C38.3415 81.7143 36.2425 79.0745 37.2318 76.5644L57.7115 24.6007C58.7007 22.0906 62.0363 21.5927 63.7155 23.7045L98.4776 67.4222C100.157 69.534 98.9202 72.6717 96.2517 73.07L41.01 81.316ZM61.7286 45.6059C61.4614 44.2165 62.4051 42.8843 63.8045 42.6754C65.2038 42.4665 66.4954 43.4651 66.6457 44.8719L68.1749 59.1923C68.2822 60.1974 67.5779 61.1083 66.5782 61.2576C65.5785 61.4068 64.639 60.7412 64.4481 59.7486L61.7286 45.6059ZM67.5304 67.6544C68.6592 67.4859 69.4377 66.4342 69.2692 65.3054C69.1007 64.1766 68.049 63.3981 66.9202 63.5666C65.7914 63.7351 65.0129 64.7868 65.1814 65.9156C65.3499 67.0444 66.4016 67.8229 67.5304 67.6544Z" fill="#FD6483" />
            <path opacity="0.56" d="M24.6072 26.592C25.7178 26.5968 26.781 27.0426 27.5629 27.8313L31.699 32.0033C32.4809 32.792 32.9175 33.859 32.9127 34.9695L32.8873 40.8443C32.8825 41.9549 32.4367 43.018 31.6481 43.7999L27.476 47.9361C26.6874 48.718 25.6204 49.1546 24.5098 49.1498L18.635 49.1244C17.5245 49.1196 16.4613 48.6738 15.6794 47.8851L11.5432 43.7131C10.7613 42.9244 10.3248 41.8574 10.3296 40.7468L10.3549 34.8721C10.3597 33.7615 10.8055 32.6983 11.5942 31.9164L15.7662 27.7803C16.5549 26.9984 17.6219 26.5618 18.7325 26.5666L24.6072 26.592Z" fill="#FFCEDB" />
            <ellipse opacity="0.2" cx="96.1496" cy="32.253" rx="3.59943" ry="3.66511" transform="rotate(3.34996 96.1496 32.253)" fill="#FD6483" />
            <path opacity="0.5" d="M117.867 68.4029C119.531 67.416 121.639 68.5998 121.662 70.5338L121.819 83.787C121.842 85.721 119.763 86.9546 118.077 86.0075L106.52 79.5173C104.834 78.5702 104.805 76.1527 106.468 75.1658L117.867 68.4029Z" fill="#FF9EB7" />
        </svg>
    )
}
const LINESVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="680" height="138" viewBox="0 0 680 138" fill="none">
            <path d="M256.368 -318.187L535.068 36.7076C569.872 81.0274 630.39 95.9445 681.805 72.8771V72.8771C759.825 37.8731 762.295 -71.9941 685.927 -110.469L482.464 -212.975M17.9576 -12.4564L-79.4323 132.137" stroke="url(#paint0_linear_1219_8007)" strokeWidth="19.7993" />
            <defs>
                <linearGradient id="paint0_linear_1219_8007" x1="406.392" y1="-346.85" x2="794.001" y2="368.127" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.261823" stopColor="#FFDFE6" />
                    <stop offset="0.555197" stopColor="#FDA1BD" />
                    <stop offset="0.635" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const RevokeAccount = ({ onClose, onNextStep }) => {
    const handleClose = () => {
        window?.scrollTo({ top: 0, behavior: "smooth" });
        onClose();
    }
    return (
        <DialogBox>
            <div className="bg-white rounded-3xl overflow-hidden shadow-drop-shadow w-[680px] p-8 relative">
                <div className="absolute top-0 left-0">
                    <LINESVG />
                </div>
                <div className="flex flex-col items-center justify-center relative z-10">
                    <div className="flex w-full justify-end">
                        <button onClick={onClose} className="icon-outline-btn small border-none">
                            <Xmark />
                        </button>
                    </div>
                    <HEROSVG />
                    <p className="text-subHeadingTwoBold text-venus-default text-center">Alert!</p>
                    <p className="text-bodyOne text-center text-gray-5">Are you sure you want to proceed with disconnection? </p>
                    {/* <div className="rounded-lg bg-gray-moon p-2.5 mt-16">
                        <p className="text-subHeadingThree text-gray-5">Would a you like to <b>downgrade</b> instead?</p>
                    </div> */}
                    <div className="mt-[90px] flex w-full justify-between items-center">
                        <button className="filled-btn w-[238px] light-red" onClick={handleClose}>Cancel</button>
                        <button className="filled-btn w-[238px]" onClick={onNextStep} >Disconnect</button>
                    </div>
                </div>
            </div>
        </DialogBox>
    )
}

const Accounts = () => {
    const dispatch = useDispatch();
    const [openRevokeAccount, setRevokeAccount] = useState(false);
    const [openDeleteAccount, setDeleteAccount] = useState(false);
    const { isLinkedinVerified, phoneNumber, email, notifications, countryCode } = useSelector(
        (state) => state.auth
    );
    const [phoneNumberValue, setPhoneNumberValue] = useState(phoneNumber || "");
    const [countryCodeValue, setCountryCodeValue] = useState(countryCode || "");

    // formData holds the values that will be sent when saving changes.
    const [formData, setFormData] = useState({
        phoneNumber: phoneNumber || "",
        countryCode: countryCode || "",
        email: email || "",
        notifications: notifications || {
            trendingTopics: false,
            latestIdeas: false,
            updates: false,
        },
    });

    // Local UI state
    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0); // 0: edit phone, 1: edit email
    const [errorMessage, setErrorMessage] = useState("");

    // A ref to store a timeout id for phone number validation debounce.
    const phoneTimeoutRef = useRef(null);

    // Mutation hook to save data (using your saveOnBoarding function)
    const { mutate } = useMutation({
        mutationFn: saveOnBoarding,
        onSuccess: (data) => {
            dispatch(updateOnboarding(data.data));
            toast.success("Saved successfully!");
        },
        onError: (error) => {
            toast.error(error?.message || "Something went wrong!");
        },
    });

    // react-hook-form for email editing with Zod validation.
    // We set the default value to the current email in formData.
    const { handleSubmit, control } = useForm({
        resolver: zodResolver(emailSchema),
        defaultValues: { email: formData.email },
    });

    const handleToggle = (key) => {
        setFormData((prev) => {
            const updatedFormData = {
                ...prev,
                notifications: {
                    ...prev.notifications,
                    [key]: !prev.notifications?.[key],
                },
            };

            mutate(updatedFormData);
            return updatedFormData;
        });
    };

    // When switching between phone and email editing
    const handleChange = (index) => {
        setActiveIndex(index);
    };

    // Called when saving email edits.
    // The email value (from react-hook-form) is merged into formData.
    const onSaveEmail = (data) => {
        const updatedData = { ...formData, email: data.email };
        setFormData(updatedData);
        mutate(updatedData);
        setOpenEdit(false);
    };

    const handleSavePhone = () => {
        let error = "";

        if (countryCodeValue === "+91" && phoneNumberValue) {
            const indianPhoneRegex = /^[6789]\d{9}$/;
            if (!indianPhoneRegex.test(phoneNumberValue)) {
                error = "Invalid phone number";
            }
        } else if (countryCodeValue !== "+91" && phoneNumberValue && phoneNumberValue.length < 6) {
            error = "Invalid phone number";
        }

        if (error) {
            setErrorMessage(error);
            return; // Prevent further processing if there's an error.
        } else {
            setErrorMessage("");
            const updatedFormData = { ...formData, phoneNumber: phoneNumberValue, countryCode: countryCodeValue };
            setFormData(updatedFormData);
            mutate(updatedFormData);
            setOpenEdit(false);
        }
    };


    // Handles input change for phone number and country code
    const handlePhoneChange = useCallback(({ phoneNumber, countryCode }) => {
        setPhoneNumberValue(phoneNumber);
        setCountryCodeValue(countryCode);
        setErrorMessage("")
    }, []);

    // The segmented button tabs for switching between phone and email editing.
    const tabs = [
        (
            <div key="whatsapp" className="flex items-center text-[#65D072] justify-center">
                <img className="mr-3" src={Whatsapp} alt="Whatsapp" />WhatsApp
            </div>
        ),
        (
            <div key="email" className="flex items-center justify-center">
                <Mail className="mr-3" /> Email
            </div>
        ),
    ];

    const handleDisconnect = async () => {
        try {
            const response = await disconnectLinkedin();
            dispatch(updateLinkedinConnect());
            setRevokeAccount(false);
            toast.success("Successfully disconnected!");
        } catch (error) {
            console.error("Error disconnecting from LinkedIn:", error);
            toast.error("Failed to disconnect from LinkedIn.");
        }
    };

    const handleCancelCTA = async () => {
        setOpenEdit(false);
        setErrorMessage("");
        setPhoneNumberValue(phoneNumber || "");
        setCountryCodeValue(countryCode || "");
    }

    return (
        <MainLayout>
            {
                openRevokeAccount && (
                    <RevokeAccount onClose={() => setRevokeAccount(false)}
                        onNextStep={handleDisconnect}
                    />
                )
            }
            {
                openDeleteAccount && (
                    <DeleteAccount onClose={() => setDeleteAccount(false)} />
                )
            }
            {openDialog && <LogoutDialog onClose={() => setOpenDialog(false)} />}

            <div className="rounded-2xl px-5 py-8 bg-star-light3 min-h-screen">
                {/* Header */}
                <div className="flex justify-between items-center">
                    <div>
                        <p className="text-subHeadingTwoBold text-space-default">Account Settings</p>
                        <p className="text-bodyTwo bg-clip-text text-transparent bg-heading-gradient-3">
                            Manage your account information & settings here
                        </p>
                    </div>
                    <button className="filled-btn light-red" onClick={() => setOpenDialog(true)}>
                        <LogOut />
                        Log out
                    </button>
                </div>
                <div className="p-4 mt-7 flex flex-col gap-y-4">
                    {/* LinkedIn Connection Section */}
                    <div>
                        <p className="text-bodyOneBold text-gray-6">Connect Linkedin with Superpen</p>
                        <div className="p-4 bg-gray-moon rounded-xl mt-4 flex justify-between items-center">
                            <div className="flex">
                                <span className="text-bodyTwoBold text-star-default mr-3">Connection Status |</span>
                                <label className="flex items-center space-x-2">
                                    <RadioButton color={`${isLinkedinVerified ? '#21A700' : '#FD6483'} `} />
                                    {isLinkedinVerified ? (
                                        <span className="text-bodyTwoBold text-[#21A700] ml-2">Connected</span>
                                    ) : (
                                        <span className="text-bodyTwoBold text-venus-default ml-2">Not connected</span>
                                    )}
                                </label>
                            </div>
                            {isLinkedinVerified ? (
                                <button className="outlined-btn" onClick={() => { setRevokeAccount(true) }}>Disconnect</button>
                            ) : (
                                <button
                                    className="filled-btn"
                                    onClick={() => {
                                        trackEvent(MIXPANEL_EVENTS.LINKEDIN_CONNECT_CLICKED);
                                        initiateLinkedInAuth();
                                    }}
                                >
                                    Connect Linkedin
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Contact & Notification Preferences Section */}
                    <div>
                        <p className="text-bodyOneBold text-gray-6">Notification Preferences</p>
                        <div className="p-4 bg-gray-moon rounded-xl mt-4">
                            {/* Top row: Contact info editing */}
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-bodyTwoBold text-star-default">Send notifications via</p>
                                    <p className="text-bodyThree text-gray-5">
                                        Where would you like us to share highly curated content for you?
                                    </p>
                                </div>
                                {openEdit ? (
                                    <div className="w-[320px]">
                                        {/* <SegmentedBtn list={tabs} onChange={handleChange} activeIndex={activeIndex} /> */}

                                        {activeIndex === 0 ? (
                                            <>
                                                <div className="flex w-full items-center gap-3 mt-5">
                                                    <PhoneNumberInput
                                                        name="phoneNumber"
                                                        maxLength={15}
                                                        value={{ phoneNumber: phoneNumberValue }}
                                                        onChange={handlePhoneChange}
                                                        errorMessage={errorMessage}
                                                    />
                                                </div>
                                                <div className="flex mt-3 justify-end">
                                                    <button
                                                        className="text-btn small mr-2"
                                                        onClick={() => {
                                                            handleCancelCTA()
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="filled-btn small"
                                                        disabled={!phoneNumberValue || errorMessage}
                                                        onClick={handleSavePhone}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            // Wrap email editing in a form so that we can use react-hook-form validation
                                            <form onSubmit={handleSubmit(onSaveEmail)}>
                                                <div className="flex w-full items-center gap-3 mt-5">
                                                    <InputField
                                                        name="email"
                                                        type="text"
                                                        control={control}
                                                        placeholder="Email"
                                                        maxLength={25}
                                                    />
                                                </div>
                                                <div className="flex mt-3 justify-end">
                                                    <button
                                                        className="text-btn small mr-2"
                                                        type="button"
                                                        onClick={() => setOpenEdit(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button className="filled-btn small" type="submit">
                                                        Save
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <div className="mr-3 flex">
                                            <span className={`${formData.phoneNumber ? "text-gray-5" : "text-gray-4"} text-bodyOne py-3 px-1 flex gap-2`}>
                                                <img src={Whatsapp} alt="Whatsapp" />
                                                {formData.phoneNumber ? (
                                                    <>
                                                        {formData.countryCode} {String(formData.phoneNumber).slice(0, 5)} {String(formData.phoneNumber).slice(5)}
                                                    </>
                                                ) : (
                                                    "Whatsapp number Missing!"
                                                )}

                                            </span>
                                            <span className="text-gray-5 py-3 px-1">|</span>
                                            <span className="text-gray-5 text-bodyOne py-3 px-1 flex gap-2">
                                                <Mail />
                                                {formData?.email || ""}
                                            </span>
                                        </div>
                                        <button className="text-btn" onClick={() => setOpenEdit(true)}>
                                            <EditPencil />
                                            Edit
                                        </button>
                                    </div>
                                )}
                            </div>

                            {/* Divider */}
                            <div className="rounded-2xl bg-gray-3 mt-5 mb-5 w-full h-[1px]" />

                            {/* Notification toggles */}
                            <div className="flex flex-col gap-y-4">
                                <p className="mb-4 text-bodyTwoBold text-star-default">Notify me on</p>
                                {["trendingTopics", "latestIdeas", "updates"].map((key) => (
                                    <div key={key} className="flex justify-between w-full">
                                        <p
                                            className={`text-bodyTwo ${formData.notifications[key] ? "text-bodyThreeBold" : "text-gray-6"
                                                }`}
                                        >
                                            {key === "trendingTopics"
                                                ? "Trending topics/ideas"
                                                : key === "latestIdeas"
                                                    ? "Latest ideas"
                                                    : "Updates"}
                                        </p>
                                        <ToggleBtn isOn={formData.notifications[key]} onChange={() => handleToggle(key)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-bodyOneBold text-gray-5">Delete my Account</p>
                            <p className="text-bodyTwo text-gray-5 mt-2">Your account will be permanently deleted and can’t be restored later.</p>
                        </div>
                        <div className="my-10">
                            <button className="text-btn small white" onClick={() => setDeleteAccount(true)}>
                                <Xmark />
                                Delete Permanently
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default Accounts;
