import { useMutation, useQuery } from '@tanstack/react-query';
import { DiceThree, EditPencil, Filter } from "iconoir-react";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import LoaderGif from "../../asset/gif/loader.gif";
import FreeTrialBg from '../../asset/image/freeTrialBg.png';
import Crown from '../../asset/svg/Crown';
import FlameIcon from '../../asset/svg/FlameIcon';
import GradientLock from '../../asset/svg/GradientLock';
import MicstageIcon from "../../asset/svg/MicStageIcon";
import NotFoundImage from "../../asset/svg/notFound.svg";
import PermissionLockSVG from "../../asset/svg/PermissionLock.svg";
import PenNibIcon from "../../asset/svg/PenNibIcon";
import ThumbsUpIcon from "../../asset/svg/ThumbupIcon";
import ConsultationDialog from '../../component/ConsultationDialog';
import SearchField from "../../component/custom-form/SearchField";
import SelectBox from "../../component/custom-form/SelectBox";
import ToggleBtn from '../../component/custom-form/ToggleBtn';
import FreePlanLimitDialog from '../../component/FreePlanLimitDialog';
import MasonaryLayout from "../../component/MasonaryLayout";
import PostCard from "../../component/PostCard";
import useScrolledDown from "../../hooks/useScrolledDown";
import MainLayout from "../../layout/main";
import { updateOnboarding, updateSubscription } from '../../redux/slice/auth.slice';
import { setPostIds } from '../../redux/slice/post.slice';
import { fetchBookmarkIds, fetchLinkedInPosts } from "../../services/post.service";
import { isObjectEmpty, nearestRoundOff } from '../../utils/helper';
import { trackEvent } from '../../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../../utils/mixpanel/eventType';

import { toast } from 'react-toastify';
import BlurCard from "../../component/BlurCard.js";
import { saveOnBoarding } from '../../services/onboardin.service.js';
import EditInterest from './editInterests.js';
import AnimatedCounter from "./counter.js";
import { getRecommendation } from './helper.js';
import ClockCountDown from '../../asset/svg/ClockCountDown.js';
const likesOption = [
    {
        label: '100 - 500',
        value: {
            min: 100,
            max: 500
        },
        lock: true
    },
    {
        label: '500 - 1K',
        value: {
            min: 500,
            max: 1000
        },
        lock: true
    },
    {
        label: '1k+',
        value: {
            min: 1000,
            max: undefined
        },
        lock: true
    },
]

const contentToneOption = [
    {
        label: 'Funny',
        value: 'funny',
        lock: true
    },
    {
        label: 'Insightful',
        value: 'insightful',
        lock: true

    },
    {
        label: 'Relatable',
        value: 'shareworthy',
        lock: true
    },
]

const contentLengthOption = [
    {
        label: 'Short',
        value: {
            min: 0,
            max: 75 * 5
        },
        // lock: true
    },
    {
        label: 'Medium',
        value: {
            min: 75 * 5,
            max: 200 * 5
        },
        // lock: true
    },
    {
        label: 'Long',
        value: {
            min: 200 * 5,
        },
        // lock: true
    },
]

const contentTimeOption = [
    {
        label: 'Last 3 Months',
        value: {
            startDate: moment().subtract(3, 'months').toDate(),
            endDate: null,
        },
        // lock: true
    },
    {
        label: '3 Months to 1 Year',
        value: {
            startDate: moment().subtract(12, 'months').toDate(),
            endDate: moment().subtract(3, 'months').toDate()
        },
        // lock: true
    },
    {
        label: 'Over 1 Year Ago',
        value: {
            startDate: null,
            endDate: moment().subtract(1, 'year').toDate() // No end date, assuming open-ended
        },
        // lock: true
    },
];

const userObject = [
    {
        index: 0,
        sharesCount: 132,
        commentsCount: 685,
        totalLikeCount: 5200,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdcs",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg1.png"],
        publishedDate: "2023-11-18T10:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile1.png",
            fullName: "David Jones",
            isActive: true,
            followerCount: 1000,
            url: "https://example.com/user1",
        },
        url: "https://example.com/post1",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 1,
        sharesCount: 56,
        commentsCount: 602,
        totalLikeCount: 5100,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccsd",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg2.png"],
        publishedDate: "2023-11-19T12:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile2.png",
            fullName: "James Johnson",
            isActive: true,
            followerCount: 2500,
            url: "https://example.com/user2",
        },
        url: "https://example.com/post2",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 2,
        sharesCount: 32,
        commentsCount: 85,
        totalLikeCount: 2400,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscsdcd",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg3.png"],
        publishedDate: "2023-11-20T15:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile3.png",
            fullName: "David Jones",
            isActive: false,
            followerCount: 500,
            url: "https://example.com/user3",
        },
        url: "https://example.com/post3",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 3,
        sharesCount: 56,
        commentsCount: 273,
        totalLikeCount: 4100,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs56",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg4.png"],
        publishedDate: "2023-11-21T09:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile4.png",
            fullName: "Natalie Walker",
            isActive: true,
            followerCount: 8000,
            url: "https://example.com/user4",
        },
        url: "https://example.com/post4",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 4,
        sharesCount: 653,
        commentsCount: 181,
        totalLikeCount: 13600,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs567",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg5.png"],
        publishedDate: "2023-11-22T08:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile5.png",
            fullName: "Daniel Clark",
            isActive: true,
            followerCount: 1500,
            url: "https://example.com/user5",
        },
        url: "https://example.com/post5",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 5,
        sharesCount: 104,
        commentsCount: 762,
        totalLikeCount: 9100,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs568",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg6.png"],
        publishedDate: "2023-11-23T14:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile6.png",
            fullName: "Robert Brown",
            isActive: true,
            followerCount: 2000,
            url: "https://example.com/user6",
        },
        url: "https://example.com/post6",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 6,
        sharesCount: 194,
        commentsCount: 1300,
        totalLikeCount: 11200,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs510",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg7.png"],
        publishedDate: "2023-11-24T16:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile7.png",
            fullName: "Christoper Lee",
            isActive: true,
            followerCount: 700,
            url: "https://example.com/user7",
        },
        url: "https://example.com/post7",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 7,
        sharesCount: 125,
        commentsCount: 1100,
        totalLikeCount: 12800,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs511",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg8.png"],
        publishedDate: "2023-11-25T11:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile8.png",
            fullName: "William taylor",
            isActive: false,
            followerCount: 50000,
            url: "https://example.com/user8",
        },
        url: "https://example.com/post8",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 8,
        sharesCount: 83,
        commentsCount: 782,
        totalLikeCount: 8200,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs512",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg9.png"],
        publishedDate: "2023-11-26T18:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile9.png",
            fullName: "Rachel Robinson",
            isActive: true,
            followerCount: 4200,
            url: "https://example.com/user9",
        },
        url: "https://example.com/post9",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 9,
        sharesCount: 58,
        commentsCount: 429,
        totalLikeCount: 5100,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs514",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg10.png"],
        publishedDate: "2023-11-27T20:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile10.png",
            fullName: "Amanda Moore",
            isActive: false,
            followerCount: 1000,
            url: "https://example.com/user10",
        },
        url: "https://example.com/post10",
        postType: "photo",
        isFreeTrial: true,
    },
];


const trackFilterEvent = (eventName, name, value) => {
    trackEvent(MIXPANEL_EVENTS.FILTER_CLICKED, {
        "Filter type": name,
        "Page": 'Viral Content'
    })
    trackEvent(MIXPANEL_EVENTS.FILTER_APPLIED, {
        "Filter type": name,
        "Filter": value
    });
}

const trackFilterRemoved = (name) => {
    trackEvent(MIXPANEL_EVENTS.FILTERS_REMOVED, {
        "Filter type": name,
        "Page": "Viral Content"
    });
}

function getIndexFromSearchUsage(searchUsage, totalObjects) {
    return searchUsage % totalObjects;
}

const Inspiration = () => {
    const authState = useSelector((state) => state.auth);
    const isFreeTrial = authState.subscription.isFreeTrial;
    const occupation = authState.occupation;
    const interests = authState.interest

    const dispatch = useDispatch();

    const location = useLocation().state;

    useEffect(() => {
        const keyword = new URLSearchParams(location).get("keyword");
        if (keyword) {
            handlePressEnter(keyword, "Top keyword")
        }
    }, []);


    let { scrolledDown: sticky, isScrollingDown } = useScrolledDown(40);
    const [text, setText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [editInterest, setEditInterest] = useState(false)
    const [page, setPage] = useState(1)
    const [likeFilter, setLikeFilter] = useState({});
    const [contentToneFilter, setContentToneFilter] = useState({});
    const [contentTimeFilter, setContentTimeFilter] = useState({});
    const [contentLengthFilter, setContentLengthFilter] = useState(isFreeTrial ? {
        label: 'Short',
        value: {
            min: 0,
            max: 75 * 5
        }
    } : {});

    const [otherText, setOtherText] = useState('');
    const [isUnstuck, setIsUnstuck] = useState(false);
    const [toggleIsOn, setToggleIsOn] = useState(false);
    const [enableOther, setEnableOther] = useState(false);
    const [openFilterLimitDialog, setOpenFilterLimitDialog] = useState(false);
    const [openLimitDialog, setOpenLimitDialog] = useState(false);
    const [openConsultationDialog, setOpenConsultationDialog] = useState(false);

    const [formData, setFormData] = useState({
        occupation: authState?.occupation || '',
        interest: interests || [],
    });

    const query = {
        search: searchText,
        userGoals: searchText ? undefined : authState?.interest ? authState.interest.join(',') : undefined,
        minContentLength: contentLengthFilter?.value?.min,
        maxContentLength: contentLengthFilter?.value?.max,
        minLikes: likeFilter?.value?.min,
        maxLikes: likeFilter?.value?.max,
        reactionFunny: contentToneFilter?.value === 'funny' ? true : undefined,
        reactionInsightful: contentToneFilter?.value === 'insightful' ? true : undefined,
        reactionShareworthy: contentToneFilter?.value === 'shareworthy' ? true : undefined,
        startDate: contentTimeFilter?.value?.startDate,
        endDate: contentTimeFilter?.value?.endDate,
        page
    };
    const { data, isFetching: isLoading, refetch, } = useQuery({
        queryKey: ['linkedin-posts', query],
        queryFn: async ({ queryKey }) => {
            const response = await fetchLinkedInPosts(queryKey[1]);
            dispatch(updateSubscription({ subscriptionLimit: response.subscriptionLimit, subscription: response.subscription }));
            setOpenConsultationDialog(response.showPopup)
            //for free trial account
            if (response.subscription?.isFreeTrial) {
                // Object to insert
                const index = getIndexFromSearchUsage(response?.subscriptionLimit?.searchUsage, userObject?.length);
                const newObject = userObject[index]
                // Ensure response.data exists
                if (response.data) {
                    // Insert the object at a random index
                    const randomIndex = Math.floor(Math.random() * (response.data.length + 1));
                    response.data.splice(randomIndex, 0, newObject);
                }
            }
            return response;
        },
        staleTime: Infinity,
        retry: false
    });

    const { bookmarkIds } = useQuery({
        queryKey: ['bookmark-ids'],
        queryFn: async () => {
            const response = await fetchBookmarkIds();
            dispatch(setPostIds(response.data));
        },
        staleTime: Infinity,
        retry: false
    });

    // console.log(isFetching)
    const handlePressEnter = (value, source) => {
        trackEvent(MIXPANEL_EVENTS.SEARCH_KEYWORD, {
            "Keyword searched": value,
            "Search source": source
        })
        setPage(1);
        setText(value);
        setSearchText(value);
    }
    const handleClearSearch = () => {
        setPage(1);
        setText('');
        setSearchText('');
    }

    const resetAllFilter = () => {
        trackFilterRemoved("All");
        setLikeFilter({});
        setContentToneFilter({});
        setContentLengthFilter({});
        setContentTimeFilter({});
        setToggleIsOn(false);
        setPage(1);
    }

    const activeFilter = Object.keys(likeFilter).length || Object.keys(contentLengthFilter).length || Object.keys(contentToneFilter).length || Object.keys(contentTimeFilter).length
    let suggestions = query.search ? data?.relatedTags || [] : getRecommendation(occupation);
    suggestions = suggestions.filter((item) => item !== query.search);
    const filterCount = !isObjectEmpty(likeFilter) + !isObjectEmpty(contentToneFilter) + !isObjectEmpty(contentLengthFilter);
    sticky = isUnstuck ? false : sticky;

    useEffect(() => {
        setIsUnstuck(false);
    }, [isScrollingDown])
    const handleUnStick = () => {
        setIsUnstuck(true);
    }

    const handleClickSuggested = (suggest) => {
        trackEvent(MIXPANEL_EVENTS.SUGGESTION_CLICKED, {
            "Original search": searchText,
            "Suggested word": suggest
        })
        setPage(1);
        handlePressEnter(suggest, "Suggest");
    }

    const handleToggle = () => {
        trackEvent(MIXPANEL_EVENTS.TRENDING_CLICKED);
        if (isFreeTrial) {
            setOpenFilterLimitDialog(true);
            return;
        }
        if (toggleIsOn) {
            setToggleIsOn(false);
            setContentTimeFilter({})
        } else {
            setContentTimeFilter({
                label: 'Last 29 days',
                value: {
                    startDate: moment().subtract(30, 'days').startOf().toDate(),
                    endDate: null,
                }
            },)
            setToggleIsOn(true);
        }

    }

    const handleChange = (name, value) => {
        setFormData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    };

    const { mutate } = useMutation({
        mutationFn: saveOnBoarding,
        onSuccess: async (data) => {
            trackEvent(MIXPANEL_EVENTS.PREFERENCES_EDITED, {
                "Original Preferences": interests,
                "New Preferences": formData.interest
            })
            dispatch(updateOnboarding(data.data));
            handleInterestModal();
            toast.success("Interests updated successfully!")
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong!')
        }
    });

    const handleNext = (defaultValue) => {
        mutate(defaultValue || formData)
    };

    const handleInterestModal = () => {
        if (isFreeTrial) {
            setOpenLimitDialog(true);
            return;
        }

        setEditInterest(!editInterest);
        setOtherText('');
        setEnableOther(false);
        setFormData((prev) => {
            return {
                ...prev,
                ['interest']: interests
            }
        })
    }

    const metaData = {
        search: searchText,
        postFrom: searchText ? 'Search' : 'Recommendation',
        postTone: contentToneFilter?.value
    }

    const { subscription } = authState;
    const totalPost = (data?.totalPost - 8);
    const searchLimitExceed = authState.subscriptionLimit.searchLimit !== null && authState.subscriptionLimit.searchLimit <= authState.subscriptionLimit.searchUsage;
    return (
        <>
            <MainLayout>
                {
                    openFilterLimitDialog && (
                        <FreePlanLimitDialog title={'This premium feature is available in a paid plan'} subTitle={'80% of SuperPen’s paid users claim that this filter is the most useful.'} onClose={() => { setOpenFilterLimitDialog(false) }} />
                    )
                }
                {
                    editInterest ?
                        <EditInterest
                            type={occupation}
                            value={formData.interest}
                            onChange={handleChange}
                            handleClose={handleInterestModal}
                            handleNext={handleNext}
                            enableOther={enableOther}
                            setEnableOther={setEnableOther}
                            otherText={otherText}
                            setOtherText={setOtherText}
                        />
                        : <></>
                }
                {
                    openLimitDialog && (
                        <FreePlanLimitDialog title={'To update your interests, please upgrade to a paid plan'} onClose={() => { setOpenLimitDialog(false) }} />
                    )
                }
                {/* {
                    openConsultationDialog && (
                        <ConsultationDialog onClose={() => { setOpenConsultationDialog(false) }} />
                    )
                } */}
                <div className="rounded-2xl  relative bg-star-light3 " style={{
                    marginTop: subscription.isFreeTrial ? 8 : undefined,
                }}>
                    <div className='rounded-2xl  fixed z-20 top-0 w-full right-2' style={{
                        width: 'calc(100% - 230px)',
                        top: subscription.isFreeTrial ? !sticky ? 65 : 52 : sticky ? 0 : 10
                    }}>
                        <div className={`flex relative mr-2 rounded-tr-2xl rounded-tl-2xl bg-star-light3 p-4 items-center ${sticky ? 'flex-row gap-4' : 'flex-col'}`}>
                            {
                                !sticky && (
                                    <>

                                        <div className=' top-4 tooltip-wrapper right-6 flex px-3 bg-gray-moon py-2 rounded-lg cursor-pointer hover:bg-star-light4 transition-colors duration-300' onClick={handleInterestModal} style={{ position: 'absolute' }}>
                                            <EditPencil color='#A489F3' width={20} height={20} />
                                            <p className='ml-2 text-bodyThree text-gray-5'>Interests</p>
                                            {
                                                isFreeTrial && (

                                                    <div className="tooltip absolute right-2 top-11 hidden min-w-max bg-white px-4 py-2" style={{
                                                        boxShadow: "0px 14px 32px 0px rgba(113, 69, 253, 0.11)",
                                                        borderRadius: '8px 0px 8px 8px'
                                                    }}>
                                                        <div className="flex items-center gap-1">
                                                            <Crown />
                                                            <p className="text-bodyThreeBold  text-transparent bg-clip-text bg-heading-gradient-3 ">Upgrade to unlock
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className=' top-4 tooltip-wrapper left-6 flex  py-2 rounded-lg cursor-pointer transition-colors duration-300' onClick={handleInterestModal} style={{ position: 'absolute' }}>
                                            <AnimatedCounter />
                                        </div>
                                    </>

                                )
                            }


                            <div className="w-[504px]">
                                <SearchField value={text} onChange={setText} onPressEnter={(val) => handlePressEnter(val, "Searched")} onClear={handleClearSearch} placeholder='Search any topic and discover viral posts' />
                            </div>

                            <div className={`${sticky ? '' : 'mt-8'}`}>
                                {
                                    suggestions ? (
                                        <div className="flex items-center gap-3">
                                            <p className="text-bodyThree text-gray-5">Suggestions</p>
                                            {
                                                suggestions.slice(0, 3).map((suggest, index) => (
                                                    <p onClick={() => { handleClickSuggested(suggest); }} key={index} className="flex flex-col text-bodyThree cursor-pointer bg-clip-text text-transparent bg-base-gradient">
                                                        {suggest}
                                                        <span className="border-b -mt-1 w-full border-star-light"></span>
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    ) : (
                                        <p className="bg-clip-text text-transparent bg-base-gradient text-bodyThree">Recommended for you</p>
                                    )
                                }
                            </div>
                            {
                                !sticky && (
                                    <div className="flex mt-4 items-center gap-2">
                                        <SelectBox
                                            value={likeFilter}
                                            icon={<ThumbsUpIcon />}
                                            label="Likes"
                                            options={likesOption}
                                            onRemoveFilter={() => { setLikeFilter({}); trackFilterRemoved("Likes"); }}
                                            onChange={(val) => {
                                                trackFilterEvent(MIXPANEL_EVENTS.FILTER_LIKES, 'Likes', val.label);
                                                setLikeFilter(val);
                                                setPage(1);
                                            }}
                                        />
                                        <SelectBox
                                            value={contentToneFilter}
                                            icon={<MicstageIcon />}
                                            label="Content Tone"
                                            options={contentToneOption}
                                            onRemoveFilter={() => { setContentToneFilter({}); trackFilterRemoved("Tone"); }}
                                            onChange={(val) => {
                                                trackFilterEvent(MIXPANEL_EVENTS.FILTER_TONE, 'Tone', val.label);
                                                setContentToneFilter(val);
                                                setPage(1);
                                            }}
                                        />
                                        <SelectBox
                                            value={contentLengthFilter}
                                            icon={<PenNibIcon />}
                                            label="Length"
                                            options={contentLengthOption}
                                            onRemoveFilter={() => { setContentLengthFilter({}); trackFilterRemoved("Length"); }}
                                            onChange={(val) => {
                                                trackFilterEvent(MIXPANEL_EVENTS.FILTER_LENGTH, 'Length', val.label);
                                                setContentLengthFilter(val);
                                                setPage(1);
                                            }}
                                        />
                                        <SelectBox
                                            value={contentTimeFilter}
                                            icon={<ClockCountDown />}
                                            label="Time Period"
                                            options={contentTimeOption}
                                            onRemoveFilter={() => { setContentTimeFilter({}); trackFilterRemoved("Time Period"); }}
                                            onChange={(val) => {
                                                trackFilterEvent(MIXPANEL_EVENTS.FILTER_TIME, 'Time Period', val.label);
                                                setContentTimeFilter(val);
                                                setPage(1);
                                            }}
                                        />

                                        {/* <div className="tooltip-wrapper">
                                            <div className='px-2 py-1 rounded-lg bg-gray-moon flex items-center gap-2 hover:bg-star-light4 transition-colors duration-300' onClick={handleToggle}>
                                                <FlameIcon />
                                                <p className='text-bodyThree text-gray-5'>Recent</p>
                                                {
                                                    isFreeTrial ? <GradientLock /> : <ToggleBtn isOn={toggleIsOn} onChange={() => { }} />
                                                }
                                            </div>
                                            {
                                                isFreeTrial && (
                                                    <div className="tooltip absolute -right-6 top-10 hidden min-w-max bg-white px-4 py-2" style={{
                                                        boxShadow: "0px 14px 32px 0px rgba(113, 69, 253, 0.11)",
                                                        borderRadius: '0px 8px 8px 8px'
                                                    }}>
                                                        <div className="flex items-center gap-1">
                                                            <Crown />
                                                            <p className="text-bodyThreeBold  text-transparent bg-clip-text bg-heading-gradient-3 ">Upgrade to unlock
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div> */}

                                        {
                                            activeFilter > 0 && (
                                                <button onClick={resetAllFilter} className="text-btn small text-gray-6">Remove all filters</button>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                sticky && (
                                    <div className="ml-auto">
                                        <button className="icon-outline-btn small relative" onClick={handleUnStick}>
                                            <Filter />
                                            {
                                                filterCount > 0 && (
                                                    <div className='flex items-center justify-center absolute -top-1 -right-1 rounded-full w-[13px] h-[13px] bg-mars-default text-white text-subTextBold'>
                                                        {filterCount}
                                                    </div>
                                                )
                                            }
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={"p-4 pt-[200px]"}>
                        {
                            searchLimitExceed && (
                                <div className="flex flex-col h-[70vh] justify-center items-center">
                                    <img src={PermissionLockSVG} alt="notFound" />
                                    <p className="text-bodyThreeBold text-star-light text-center">Search Limit Exceeded<br />
                                    </p>
                                </div>
                            )
                        }
                        {
                            isLoading && (
                                <div className="flex flex-col h-[70vh] justify-center items-center">
                                    <img src={LoaderGif} width={200} alt="loading" />
                                    <p className="text-bodyThreeBold text-star-light">Hang tight, inspiration is on the way!</p>
                                </div>
                            )
                        }
                        {/* {
                            data?.data?.length === 0 && (
                                <div className="flex flex-col h-[70vh] justify-center items-center">
                                    <img src={NotFoundImage} alt="notFound" />
                                    <p className="text-bodyThreeBold text-star-light text-center"> Posts not found for your query! <br />
                                        Try using some alternate keywords</p>
                                </div>
                            )
                        } */}
                        {
                            data?.data?.length === 0 && (
                                <div className="flex flex-col h-[70vh] justify-center items-center">
                                    <p className='text-bodyThreeBold text-gray-5'>No more relevant results for your search?</p>
                                    <p className='text-bodyThree text-gray-5'>Try these keywords instead</p>
                                    <div className='flex items-center  gap-2 mt-4'>
                                        {
                                            suggestions?.slice(0, 3).map((suggest, index) => (
                                                <button
                                                    onClick={() => { handleClickSuggested(suggest); }}
                                                    key={index}
                                                    className="filled-btn white small font-normal">
                                                    {suggest}
                                                </button>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            !searchLimitExceed && !isLoading && (
                                <MasonaryLayout>
                                    {
                                        data?.data?.map((postItem) => (
                                            postItem?.isFreeTrial ?
                                                <div key={postItem.postId} className="flex-1">
                                                    <BlurCard {...postItem} postType={contentToneFilter?.value} metaData={metaData} />
                                                </div>
                                                :
                                                <div key={postItem.postId} className="flex-1">
                                                    <PostCard {...postItem} postType={contentToneFilter?.value} metaData={metaData} />
                                                </div>
                                        ))
                                    }
                                </MasonaryLayout>
                            )
                        }
                        {
                            !searchLimitExceed && !isFreeTrial && !isLoading && data?.data?.length > 0 && (
                                <div className='flex items-center justify-center my-4'>
                                    <button className='outlined-btn small border-none' onClick={() => setPage((page) => page + 1)}>
                                        <DiceThree />
                                        Renew inspiration feed
                                    </button>
                                </div>
                            )
                        }

                    </div>
                    {
                        isFreeTrial && nearestRoundOff(totalPost) > 0 && !isLoading && (
                            <div className='p-4'>
                                <div className='rounded-xl relative w-full pt-[47px] pb-[89px] '>
                                    <img src={FreeTrialBg} alt='background-img' className='w-full top-0 h-full absolute z-0' />
                                    <div className='flex flex-col items-center justify-center relative z-10'>
                                        <p className='text-headingOne text-white text-center'>{nearestRoundOff(totalPost)}+ posts found</p>
                                        <p className='text-subHeadingThree mt-3 mb-5 text-white text-center'>Need more inspiration?
                                        </p>
                                        <Link to={'/plan'} className='filled-btn white small' onClick={() => {
                                            trackEvent(MIXPANEL_EVENTS.UPGRADE_CLICKED, {
                                                'Upgrade clicked from': 'Viral Content - Need more inspiration'
                                            })
                                        }}><Crown /> <p >Upgrade Plan Now</p> </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </MainLayout>

        </>
    )
}

export default Inspiration;