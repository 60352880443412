import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import EmojiPicker from 'emoji-picker-react';
import { InfoCircleSolid, Sparks, Attachment, Trash, Expand, Plus, BrightStar } from 'iconoir-react';
import React, { useEffect, useRef, useState } from 'react';
import DecoratorValidation from './DecoratorValidation';
import EditorToolbar from './EditorToolbar';
import LoaderGif from "../../asset/gif/loader.gif";
import { useSelector } from 'react-redux';
import PERMISSIONS, { hasPermission } from '../../utils/permissions';
import { trackEvent } from '../../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../../utils/mixpanel/eventType';
import moment from 'moment';
import MediaDialog from '../MedialDailog'
import PdfViewer from '../PdfViewerDialog';
import PromptDialog from './PromptDialog';
import { fetchAITemplateCategory } from "../../services/template.service";
import { useQuery } from "@tanstack/react-query";


const TextEditor = ({ isAiGenerated, editorState, setEditorState, handleGenerateNewPost, postGenerating, template = false, setMedia, uploadedFiles, setUploadedFiles, image, setIsImage, content, aiTemplateCategory, rawThought, metaData,setAITemplateCategory }) => {
    const editor = useRef(null);
    const { subscription, subscriptionLimit } = useSelector(state => state.auth);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [mediaDialog, setMediaDialog] = useState(false)
    // const [uploadedFiles, setUploadedFiles] = useState([]);
    const [openPdf, setOpenPdf] = useState(false)
    const [openRawThought, setOpenRawThought] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null)

    const isStyleActive = (style) => editorState.getCurrentInlineStyle().has(style);
    // const characterCount = editorState.getCurrentContent().getPlainText('').length;
    const words = editorState.getCurrentContent().getPlainText('').split(/\s+/).filter(word => word.trim() !== '');

    const applyStyle = (style) => {
        const newState = RichUtils.toggleInlineStyle(editorState, style);
        setEditorState(newState);
    };

    const insertEmoji = (emoji) => {
        const text = emoji.emoji;
        const currentContent = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const newSelectionState = selectionState.merge({
            anchorOffset: selectionState.getAnchorOffset(),
            focusOffset: selectionState.getAnchorOffset(),
        });
        const contentWithEmoji = Modifier.insertText(currentContent, newSelectionState, text);
        const newEditorState = EditorState.push(editorState, contentWithEmoji, 'insert-characters');
        setEditorState(newEditorState);
    };


    const applyDecorator = (decorator) => {
        let newEditorState = EditorState.set(editorState, { decorator });
        setEditorState(newEditorState);
    }

    const removeDecorator = () => {
        const content = editorState.getCurrentContent()
        let newState = EditorState.createWithContent(content)
        setEditorState(newState)
    }

    useEffect(() => {
        editor.current.focus();
    }, []);
    const generatePermission = hasPermission(PERMISSIONS.AI_GENERATE);
    const remainingCount = (subscriptionLimit.aiCreditLimit - subscriptionLimit.aiCreditUsage);
    // const remainingText = remainingCount === subscriptionLimit.aiCreditLimit ? remainingCount - 1 : remainingCount;
    const remainingText = remainingCount;
    const trackAIExpired = () => {
        trackEvent(MIXPANEL_EVENTS.AI_EXPIRED, {
            "Current Plan": subscription.planName,
            "Date of next renewal": moment(subscription.startDate).add(1, 'months').toDate(),
            "Date of expiry": subscription.endDate
        })
    }

    const toneType = [
        // {
        //     index: 1,
        //     lable: "Shorter",
        //     value: "Make this shorter"
        // },
        // {
        //     index: 2,
        //     lable: "Longer",
        //     value: "Make this longer"
        // },
        // {
        //     index: 3,
        //     lable: "Funnier",
        //     value: "Make this funnier"
        // },
        // {
        //     index: 4,
        //     lable: "Add facts",
        //     value: "Add facts to this"
        // },
        // {
        //     index: 5,
        //     lable: "Change hook",
        //     value: "Change the hook line in this"
        // },
    ]


    const query = {
        type: 'RECREATE_POST'
    };


    const { data } = useQuery({
        queryKey: ['ai-template-category', query],
        queryFn: ({ queryKey }) => fetchAITemplateCategory(queryKey[1]),
        // staleTime: Infinity,
        retry: false
    });


    const [fileType, setFileType] = useState(null);

    const handleDeleteFile = (index) => {

        setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        if (uploadedFiles.length === 0) {
            setFileType(null)
            setIsImage(false)
        }
    };

    useEffect(() => {
        if (uploadedFiles.length === 0) {
            setFileType(null);
            setIsImage(false);
        }
        setMedia({ uploadedFiles, fileType })
    }, [uploadedFiles]);



    const handlePdf = (file) => {
        setSelectedPdf(file)
        setOpenPdf(true)
    }

    const trackQuickPrompt = (promptType) => {
        trackEvent(MIXPANEL_EVENTS.QUICK_PROMPT, {
            'Post from': metaData.postFrom,
            'Prompt Name': promptType
        })
    }
    return (
        <div className='flex flex-col h-full'>
            {
                openRawThought && (<PromptDialog onClose={() => { setOpenRawThought(false); }} handleGenerateNewPost={handleGenerateNewPost} content={editorState.getCurrentContent().getPlainText()} />)
            }

            {
                mediaDialog && (<MediaDialog uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} fileType={fileType} setFileType={setFileType} onClose={setMediaDialog} setIsImage={setIsImage} metaData={metaData} />)
            }

            {
                openPdf && (<PdfViewer file={selectedPdf} onClose={setOpenPdf} />)
            }

            <div onClick={() => editor.current.focus()}>
                <div className='relative'>
                    <div
                        className={`bg-white z-0 relative overflow-auto h-[29vh] px-[14px] pr-[100px] py-2.5 rounded-lg border border-gray-3 text-bodyThree ${postGenerating ? "text-gray-5" : "text-space-default"} scrollbar`}
                        style={{ wordBreak: 'break-word' }}>
                        <Editor

                            ref={editor}
                            editorState={editorState}
                            onChange={setEditorState}
                            handleKeyCommand={(command) => {
                                const newState = RichUtils.handleKeyCommand(editorState, command);
                                if (newState) {
                                    setEditorState(newState);
                                    return 'handled';
                                }
                                return 'not handled';
                            }}
                        />
                        {
                            postGenerating && (
                                <div className='absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center'>
                                    <img src={LoaderGif} width={200} alt='loading' />
                                    <p className='text-gray-5'>Generating...</p>
                                </div>
                            )
                        }
                        <div className='absolute -z-10 px-3 w-full left-0 top-[50px]'>
                            <div className='w-full h-1' style={{
                                backgroundImage: 'linear-gradient(to right, #A489F3 33%, rgba(255, 255, 255, 0) 0%)',
                                backgroundPosition: 'bottom',
                                backgroundSize: '10px 0.5px',
                                backgroundRepeat: 'repeat-x',
                            }} />
                        </div>
                        <div className='absolute right-0 px-3  top-[28px]'>
                            {/* On hover this makes visible */}
                            <div className='flex gap-1 items-center tooltip-wrapper'>
                                <p className='text-bodyThree text-star-light'>See more</p>
                                <InfoCircleSolid width={14} height={14} color='#C5C2CE' />
                                {/* Tooltip */}
                                <div className='absolute hidden tooltip bg-white p-[15px] w-[230px] rounded-lg top-5 right-0' style={{
                                    boxShadow: '0px 14px 32px 0px rgba(39, 39, 47, 0.06)'
                                }}>
                                    <p className='text-bodyThree text-gray-5'>
                                        Add a captivating <b> hook line </b> followed by a blank line to increase engagement
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={`absolute text-subText text-gray-5 bottom-2 right-4 ${words.length > 400 ? 'text-mars-default' : ''}`}>
                        {words.length} words
                    </div>
                </div>
                {
                    template ?
                        //     <div className=' justify-between items-center mt-2'>
                        //         <div className='flex items-center gap-2'>
                        //             {
                        //                 toneType.map((buttonLabel) => (
                        //                     <button
                        //                         key={buttonLabel.index} // Use the array index as the key if buttonLabel doesn't have a unique property
                        //                         className="ai-btn small"
                        //                         onClick={() => {
                        //                             handleGenerateNewPost(buttonLabel?.value);
                        //                             if (remainingText <= 1) {
                        //                                 trackAIExpired();
                        //                             }
                        //                         }}
                        //                     >
                        //                         {buttonLabel?.lable} {/* Display the button label */}
                        //                     </button>
                        //                 ))
                        //             }

                        //         </div>
                        //         <div className="flex gap-1 items-center mt-2">
                        //                 {
                        //                     generatePermission && (
                        //                         <>
                        //                             <p className="text-bodyThree text-gray-5">
                        //                                 <b>{remainingText}</b> of {subscriptionLimit.aiCreditLimit - 1} credits left
                        //                             </p>
                        //                             <p className="text-bodyThree text-gray-5">•</p>
                        //                         </>
                        //                     )
                        //                 }

                        //                 <p className="text-bodyThree text-gray-4">powered by ChatGPT</p>
                        //             </div>

                        //     </div> 
                        <>
                            <div className='flex justify-end  text-center items-center mt-3'>

                                <button
                                    className={`text-btn  small ${(!image && uploadedFiles.length)
                                        ? "opacity-50 cursor-not-allowed hidden"
                                        : ""
                                        }`}
                                    onClick={() => {
                                        if (uploadedFiles.length > 0 && !image) {
                                            return
                                        }
                                        setMediaDialog(true);
                                    }}

                                >
                                    {image ? (
                                        <>
                                            <Plus />
                                            Add More Images
                                        </>
                                    ) : (
                                        <>
                                            <Attachment />
                                            Add Media
                                        </>
                                    )}
                                </button>
                            </div>
                        </>
                        :

                        <div className='flex justify-between items-start mt-3'>
                            {/* <div className='flex items-center gap-2'>
                                <button className='ai-btn small' onClick={() => {
                                    handleGenerateNewPost()
                                    if (remainingText <= 1) {
                                        trackAIExpired();
                                    }
                                }} disabled={isAiGenerated || postGenerating || remainingText <= 0}>
                                    <Sparks />
                                    Write with AI
                                </button>
                                <div className="flex gap-1 items-center">
                                    {
                                        generatePermission && (
                                            <>
                                                <p className="text-bodyThree text-gray-5">
                                                    <b>{remainingText}</b> of {subscriptionLimit.aiCreditLimit - 1} credits left
                                                    <b>{remainingText}</b> of {subscriptionLimit.aiCreditLimit} credits left
                                                </p>
                                                <p className="text-bodyThree text-gray-5">•</p>
                                            </>
                                        )
                                    }

                                    <p className="text-bodyThree text-gray-4">powered by ChatGPT</p>
                                </div>
                            </div> */}
                            <div className=''>
                                <div className='flex items-center gap-2'>
                                    <BrightStar className='w-5 h-5 text-star-light' />
                                    <p className='text-bodyOne text-space-default'>Quick prompts</p>
                                </div>
                                <div className='flex mt-3 gap-2'>
                                    <button
                                        className='outlined-btn small'
                                        type='button'
                                        disabled={postGenerating}
                                        onClick={() => {
                                            handleGenerateNewPost({ content, rawThought, aiTemplateCategoryId: aiTemplateCategory?._id });
                                            trackQuickPrompt('Try again');
                                        }}
                                    >
                                        Try again
                                    </button>
                                    {toneType.map((tone, index) => (
                                        <button
                                            className='outlined-btn small'
                                            key={index}
                                            disabled={postGenerating}
                                            type='button'
                                            onClick={() => {
                                                handleGenerateNewPost({ content: editorState.getCurrentContent().getPlainText(), tone: tone?.value });
                                                trackQuickPrompt(tone?.lable);
                                            }}>
                                            {tone.lable}
                                        </button>
                                    ))}
                                    {
                                        data?.data?.map((item) => {
                                            // If this item is the currently selected template, don't render it.
                                            if (aiTemplateCategory?._id === item._id) return null;

                                            return (
                                                <button
                                                    key={item._id}
                                                    disabled={postGenerating}
                                                    className="outlined-btn small"
                                                    onClick={() =>{
                                                        handleGenerateNewPost({
                                                            content,
                                                            rawThought,
                                                            aiTemplateCategoryId: item._id,
                                                        });
                                                        setAITemplateCategory(item)
                                                    }
                                                    }
                                                >
                                                    {item.name}
                                                </button>
                                            );
                                        })
                                    }
                                    <button
                                        className='outlined-btn small'
                                        type='button'
                                        disabled={postGenerating}
                                        onClick={() => {
                                            setOpenRawThought(true);
                                            trackQuickPrompt('Write my prompt');
                                        }}
                                    >
                                        Write my prompt
                                    </button>
                                </div>
                            </div>
                            <div className='flex justify-center  text-center items-center '>

                                <button
                                    className={`text-btn small ${(!image && uploadedFiles.length)
                                        ? "opacity-50 cursor-not-allowed hidden"
                                        : ""
                                        }`}
                                    onClick={() => {
                                        if (uploadedFiles.length > 0 && !image) {
                                            return
                                        }
                                        setMediaDialog(true);
                                    }}

                                >
                                    {image ? (
                                        <>
                                            <Plus />
                                            Add More Images
                                        </>
                                    ) : (
                                        <>
                                            <Attachment />
                                            Add Media
                                        </>
                                    )}
                                </button>

                                <EditorToolbar
                                    applyStyle={applyStyle}
                                    showEmojiPicker={showEmojiPicker}
                                    setShowEmojiPicker={setShowEmojiPicker}
                                    isStyleActive={isStyleActive}
                                />
                            </div>
                        </div>
                }
            </div>
            <div >
                <div className='relative'>
                    {showEmojiPicker && (

                        <div className="absolute top-0 right-0 z-10">
                            <div className='w-full h-full top-0 left-0 fixed' onClick={() => { setShowEmojiPicker(false); }} />
                            <EmojiPicker
                                searchDisabled
                                onEmojiClick={insertEmoji}
                                height={300}
                            />
                        </div>
                    )}
                </div>
                <div className="mt-3 flex flex-wrap gap-2 relative overflow-auto h-[130px]">
                    {uploadedFiles?.map(({ file, previewUrl }, index) => (
                        <div key={index} className="rounded-lg w-[148px] h-[123px] relative bg-white overflow-hidden">
                            {/* Display Images */}
                            {file?.type.startsWith("image/") && (

                                <img src={previewUrl} alt={file?.name} className="w-full h-[93px] object-cover" />

                            )}

                            {/* Display Videos */}
                            {file?.type.startsWith("video/") && (
                                <video src={previewUrl} controls className="w-full h-[93px] object-cover" />
                            )}

                            {/* Display PDFs */}
                            {file?.type === "application/pdf" && (
                                <div className='bg-star-light3 h-[93px]'>
                                    <div className='absolute text-venus-light2 bg-venus-light flex justify-center items-center text-subText p-1 w-8   right-4 top-3 rounded'>
                                        PDF
                                    </div>

                                    <button
                                        className="absolute top-7 left-12 bg-white border border-gray-300 text-gray-700 p-2 rounded-full shadow-md hover:bg-gray-100 focus:outline-none"
                                        aria-label="Expand"
                                        onClick={() => { handlePdf(file) }}
                                    >
                                        <Expand />
                                    </button>
                                </div>
                            )}
                            {/* button */}
                            <div className='flex px-2 py-1 justify-between'>
                                <div className='text-bodyThree w-[97px] overflow-hidden text-ellipsis text-nowrap  text-star-light'>
                                    {file?.name}
                                </div>

                                <button
                                    className="text-venus-light"
                                    onClick={() => handleDeleteFile(index)}
                                >

                                    <Trash width={16} />
                                </button>

                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default TextEditor;
